import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import MoreIcon from "@mui/icons-material/MoreVert";
import { IconArrowsSort } from "@tabler/icons-react";
import {
  Grid,
  IconButton,
  Popover,
  Typography,
  Menu,
  MenuItem,
  CircularProgress,
  CardActions,
  Select,
  TextField,
  Box,
  Button,
  Avatar
} from "@mui/material";
import DrawerComponent from "../../../../../../../../ui-component/Drawer";
import { customDataTableStyles } from "../../../../../../../../ccm-constant";
import { PatientApi } from "../../../../../../../../service/ProviderGroup/Patient";
import { useNotification } from "../../../../../../../../hooks/use-notification";
import ConfirmBox from "../../../../../../../../ui-component/ConfirmBox";
import CustomPagination from "../../../../../../../../ui-component/Pagination";
import { CustomScrollbars } from "../../../../../../../../ui-component/ScrollBar";
import AddFamilyMember from "./Features/AddMember/Index";
import avatar from "../../../../../../../../assets/adminAvatar.jpg";
import { useProviderContext } from "../..";
import { Formik, Form, ErrorMessage } from "formik";
import CircularLoader from "../../../../../../../../ui-component/CircularLoader";
import * as Yup from "yup";
import Input from "../../../Input";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";

const field = [
  {
    label: "Full Name",
    fieldName: "name",
    fieldType: TextField,
    placeholder: "Full Name",
  },
  {
    label: "Relationship",
    fieldName: "relation",
    fieldType: Select,
    placeholder: "Select Relationship",
    options: [
      {
        label: "Child",
        name: "Child",
      },
      {
        label: "Employer",
        name: "Employer",
      },
      {
        label: "Father",
        name: "Father",
      },
      {
        label: "Grandparent",
        name: "Grandparent",
      },
      {
        label: "Grandchild",
        name: "Grandchild",
      },
      {
        label: "Guardian",
        name: "Guardian",
      },
      {
        label: "Life Partner",
        name: "Life Partner",
      },
      {
        label: "Mother",
        name: "Mother",
      },
      {
        label: "Other",
        name: "Other",
      },
      {
        label: "Parent",
        name: "Parent",
      },
      {
        label: "Spouse",
        name: "Spouse",
      },
      {
        label: "Unknown",
        name: "Unknown",
      },
      {
        label: "Parent",
        name: "Parent",
      },
    ],
  },
  {
    label: "Email ID",
    fieldName: "email",
    fieldType: TextField,
    placeholder: "Email ID",
  },
  {
    label: "Phone Number",
    fieldName: "phone_number",
    // fieldType: TextField,
    placeholder: "Phone Number",
  },
  {
    label: "Address Line 1",
    fieldName: "address_line_1",
    fieldType: TextField,
    placeholder: "Address Line 1",
  },
  {
    label: "Address Line 2",
    fieldName: "address_line_2",
    fieldType: TextField,
    placeholder: "Address Line 2",
  },
  {
    label: "City",
    fieldName: "city",
    fieldType: TextField,
    placeholder: "City",
  },
  {
    label: "Country",
    fieldName: "country",
    fieldType: TextField,
    placeholder: "Country",
  },
  {
    label: "State",
    fieldName: "state",
    fieldType: TextField,
    placeholder: "State",
  },
  {
    label: "Zip Code",
    fieldName: "zip_code",
    fieldType: TextField,
    placeholder: "Zip Code",
  },
];

const FamilyData = ({ setOpenDrawer, openDrawer }) => {
  const navigate = useNavigate();
  const {
    searchText,
  } = useProviderContext();
  const { id } = useParams();
  const location = useLocation()
  let userType = JSON.parse(localStorage.getItem("user"))?.tokens?.userRole;
  const ProviderRole = JSON.parse(localStorage.getItem("user"))?.user?.data?.role;
  const { notifySuccess, notifyError } = useNotification();
  const [sortedColumn, setSortedColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("-id");
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMemberData, setLoadingMemberData] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [allData, setAllData] = useState();
  const [openCnf, setOpenCnf] = useState(false);
  const [memberId, setMemberId] = useState(null);
  const [editingMember, setEditingMember] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [memberData, setMemberData] = useState(null);
  const [selectedImage, setSelectedImage] = useState();
  const [updateloading, setUpdateLoading] = useState(false);
  const [fields, setFields] = useState(field);
  const [imageLoading, setImageLoading] = useState(true);
  const userRole = JSON.parse(localStorage.getItem("user") || "{}");

  const [refreshList, setRefreshList] = useState(false);

  const cameraIconStyle = {
    position: "absolute",
    bottom: -15,
    left: "7rem",
    transform: "translateX(-50%)",
    backgroundColor: "white",
    borderRadius: "50%",
  };

  const FamilyDataColumns = [
    {
      name: "No",
      selector: (row, id) => id + 1 + (currentPage ? (currentPage - 1) * 10 : ""),
      width: "8%",
    },
    {
      name: "Member Name",
      cell: (row) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "3px",
            cursor: "pointer",
          }}
          onClick={() => handleRowClick(row)}
        >
          <img
            src={row?.profile_pic ? `${row?.profile_pic}?${new Date().getTime()}` : avatar}
            alt="Image"
            width={40}
            height={40}
            style={{
              marginRight: 10,
              borderRadius: "50%",
              padding: "1px",
              border: "1px solid #dfdfdf",
            }}
            onError={(e) => {
              e.target.src = avatar;
              e.target.onerror = null;
            }}
          />
          {`${row.name}`}
        </div>
      ),
      wrap: true,
      sortable: true,
      width: "16%",
    },
    {
      name: "Contact Number",
      selector: (row)=> row.phone_number,
      sortable: true,
      width: "15%",
    },
    {
      name: "Email ID",
      selector: (row)=> row.email,
      cell: (row) => (
        <span
          style={{
            color: "#002c6b",
          }}
          onClick={() => handleRowClick(row)}
        >
          {row?.email}
        </span>
      ),
      sortable: true,
      width: "16%",
    },
    {
      name: "Relationship",
      selector: (row)=> row.relation,
      sortable: true,
      width: "15%",
    },
    {
      name: "Address",
      selector: (row) => (
        <span onClick={() => handleRowClick(row)}
          style={{
            whiteSpace: 'pre-line',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            // overflow: 'hidden',
            // WebkitLineClamp: 3,
          }}
        >
          {`${row?.address?.address_line_1}, ${row?.address?.address_line_2}, ${row?.address?.city}, ${row?.address?.state}, ${row?.address?.country}, ${row?.address?.zip_code}`}
        </span>
      ),
      // sortable: true,
      wrap: true,
      width: "25%",
    },
    {
      selector: "",
      name: "",
      width: "5%",
      cell: (row) => {
        if (userType === "provider" && (ProviderRole === "Care Team" || ProviderRole === "Physician")) {
          return <MoreButton row={row} />;
        } else {
          return null;
        }
      },
    },
  ];

  const getFamilyData = () => {
    setLoading(false);
    console.log("I am calling");
    const query = {
      search: searchText,
      limit: 10,
      offset:
        currentPage === 0
          ? currentPage
          : searchText
            ? 0
            : (currentPage - 1) * 10,
      order_by: sortDirection,
      // sort_by: sortedColumn,
    };
    PatientApi.getAllFamilyMembersData(id, query)
      .then((response) => {
        setUserData(response.data);
        setPageCount(response.count);
        setAllData(response);
        setLoading(true);
        setRefreshList(false);
      })
      .catch((error) => {
        console.error("Error fetching Care Team data:", error);
        setLoading(true);
      });
  };

  const handleSort = (column, sortDirection) => {
    console.log('sortDirection', sortDirection)
    switch (column.name) {
      case "No":
        setSortDirection(sortDirection == "desc" ? '-patient' : "patient");
        // setSortedColumn();
        break;
      case "Member Name":
        setSortDirection(sortDirection == "desc" ? '-name' : "name");
        // setSortedColumn("role");
        break;
      case "Contact Number":
        setSortDirection(sortDirection == "desc" ? '-phone_number' : "phone_number");
        // setSortedColumn("npi");
        break;
      case "Email ID":
        setSortDirection(sortDirection == "desc" ? '-email' : "email");
        // setSortedColumn("email");
        break;
      case "Relationship":
        setSortDirection(sortDirection == "desc" ? '-relation' : "relation");
        // setSortedColumn("phone_number");
        break;
      case "Address":
        setSortDirection(sortDirection == "desc" ? '-address' : "address");
        // setSortedColumn("phone_number");
        break;
      default:
        setSortDirection("-id");
    }
  };

  const handleDeleteSubmit = () => {
    PatientApi.deleteFamilyMember(id, memberId)
      .then((response) => {
        notifySuccess(response?.message);
        setOpenCnf(false);
        getFamilyData();
      })
      .catch((error) => {
        console.error("Error fetching Delete Family Member:", error);
      });
  };

  const handleRowClick = (row) => {
    // navigate(`/patients/profile/familyMember/${id}/${row.uuid}`, {
    //   state: { enrolled_status: location?.state?.enrolled_status, patientId: id, care_team_member: location?.state?.care_team_member },
    // });
    setMemberId(row?.uuid);
    setEditingMember(true);
  };

  const MoreButton = ({ row }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
      <div>
        <IconButton onClick={handleClick}>
          <MoreIcon />
        </IconButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {/* <MenuItem
              onClick={() => {
                setMemberId(row?.uuid);
                setOpenDrawer(true);
                setEditingMember(true);
              }}
            >
              Edit
            </MenuItem> */}
            <MenuItem
              onClick={() => {
                setOpenCnf(true);
                setMemberId(row?.uuid);
              }}
            >
              Delete
            </MenuItem>
          </Menu>
        </Popover>
      </div>
    );
  };

  useEffect(() => {
    if (refreshList) {
      getFamilyData();
    }
  }, [refreshList]);

  useEffect(() => {
    const delay = setTimeout(() => {
      getFamilyData();
    }, 1000);

    return () => {
      clearTimeout(delay);
    };
  }, [searchText, sortDirection, sortedColumn, currentPage]);

  const fetchMemberData = () => {
    setLoadingMemberData(true);
    PatientApi.getFamilyMemberDataById(id, memberId)
      .then((response) => {
        setMemberData(response);
        setSelectedImage(response?.profile_pic || avatar);
        setLoadingMemberData(false);
      })
      .catch((error) => {
        console.error("Error fetching Family Member Data:", error);
        setLoadingMemberData(false);
      });
  };

  useEffect(() => {
    if (memberId) {
      fetchMemberData();
      setImageLoading(true);
    }
  }, [memberId])

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Full Name is required"),
    phone_number: Yup.string()
      .matches(/^[\d\s()+-]+$/, "Invalid Phone number")
      .min(10, "Phone number is too short")
      .max(20, "Phone number is too long")
      .required("Phone number is required"),
    address_line_1: Yup.string().required("Address Line 1 is required"),
    // address_line_2: Yup.string().required("Address Line 2 is required"),
    zip_code: Yup.string()
      .matches(
        /^[0-9]{5,6}$/,
        "Zip code must be 5 to 6 digits and contain only numbers"
      )
      .required("Zip code is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required"),
    relation: Yup.string().required("Relationship is required"),
    email: Yup.string().required("Email ID is required"),
  });

  const isSupportedFileType = (fileType) => {
    const supportedFormats = ["jpeg", "jpg", "png", "gif", "bmp"];
    return supportedFormats.some((format) => fileType.endsWith(format));
  };

  const isURL = (url) => {
    try {
      if (url.includes("https://")) {
        // new URL(url);
        return true;
      } else return false;
    } catch (e) {
      return false;
    }
  };

  const handleCancelClick = () => {
    setEditMode(false);
    setLoading(false);
  };

  const handleEditClick = () => {
    setEditMode(false);
    setEditingMember(false);
    getFamilyData();
    // navigate(`/patients/profile/${id}`, {
    //   state: { tabIndex: "Family", enrolled_status: location?.state?.enrolled_status, patientId: id, care_team_member: location?.state?.care_team_member },
    // });
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  return (
    <Grid item xs={12} sx={{ minHeight: "75vh", position: "relative" }}>
      {/* <Card sx={{ height: "75vh", position: "relative" }}> */}
      {/* <CardContent> */}
      {/* <Grid
        container
        // xs={12}
        padding={1}
      // sx={{ flex: 1, alignItems: "center", marginBottom: "10px" }}
      > */}
      {/* <CustomScrollbars height="58vh"> */}
      {!editingMember ?
        <CustomScrollbars height="62vh">
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <DataTable
                columns={FamilyDataColumns}
                data={userData}
                sortIcon={<IconArrowsSort size={18} marginLeft={1} />}
                onSort={handleSort}
                onRowClicked={handleRowClick}
                highlightOnHover
                pointerOnHover
                customStyles={customDataTableStyles}
              />
            ) : (
              <CircularProgress
                color="primary"
                sx={{ marginTop: "15%" }}
              />
            )}
          </Grid>
        </CustomScrollbars>
        :
        <Formik
          initialValues={{
            name: memberData?.name || "",
            phone_number: memberData?.phone_number || "",
            address_line_1: memberData?.address?.address_line_1,
            address_line_2: memberData?.address?.address_line_2,
            zip_code: memberData?.address?.zip_code || "",
            city: memberData?.address?.city || "",
            state: memberData?.address?.state || "",
            country: memberData?.address?.country || "",
            relation: memberData?.relation || "",
            email: memberData?.email || "",
            profile_pic: "",
          }}
          onSubmit={(data) => {
            setUpdateLoading(true);
            console.log("Family Member Data", data);
            const formData = new FormData();
            let address = {
              address_line_1: data?.address_line_1,
              address_line_2: data?.address_line_2,
              zip_code: data?.zip_code,
              city: data?.city,
              state: data?.state,
              country: data?.country,
            };
            formData.append("address", JSON.stringify(address));
            formData.append("name", data?.name);
            formData.append("phone_number", data?.phone_number);
            formData.append("relation", data?.relation);
            formData.append("email", data?.email);
            formData.append("profile_pic", data?.profile_pic);
            PatientApi.updateFamilyMember(id, memberId, formData)
              .then((response) => {
                notifySuccess(response?.message);
                setUpdateLoading(false);
                setEditMode(false);
                setLoading(false);
                fetchMemberData();
              })
              .catch((error) => {
                console.error("Error fetching Update Family Member Data:", error);
                setLoading(false);
                notifyError(error?.response?.data?.error)
              });
          }}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ values, setFieldValue, handleSubmit, resetForm }) => (
            <Form>
              {loadingMemberData ?
                <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <CircularLoader />
                </Grid>
                :
                <Grid item xs={12}>
                  <Grid
                    item
                    xs={12}
                    padding={1}
                    sx={{
                      top: 0,
                      right: 0,
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <>
                      {editMode ? (
                        <span>
                          <Button
                            variant="outlined"
                            color="primary"
                            style={{
                              textTransform: "none",
                              width: "163px",
                            }}
                            onClick={() => {
                              handleCancelClick();
                              resetForm();
                            }}
                            sx={{ marginRight: "10px" }}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              marginRight: "8px",
                              textTransform: "none",
                              width: "163px",
                            }}
                            type="submit"
                            onClick={handleSubmit}
                          >
                            Save &nbsp;
                            {updateloading && (
                              <CircularProgress
                                size={18}
                                style={{ color: "white" }}
                              />
                            )}
                          </Button>
                        </span>
                      ) : (
                        <span>
                          <Button
                            variant="outlined"
                            color="primary"
                            style={{
                              textTransform: "none",
                              width: "200px",
                            }}
                            sx={{ marginRight: "10px" }}
                            onClick={handleEditClick}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            style={{
                              textTransform: "none",
                              width: "200px",
                            }}
                            onClick={(e) => {
                              handleEdit();
                              e.preventDefault();
                            }}
                          >
                            Edit Family Member
                          </Button>
                        </span>
                      )}
                    </>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container marginBottom={2}>
                      <Box
                        display="flex"
                        marginBottom={3}
                        width="97%"
                        margin={1}
                      >
                        <Box
                          position="relative"
                          marginRight={{ xs: 0, sm: 2 }}
                          marginBottom={{ xs: 2, sm: 0 }}
                        >
                          {(imageLoading) && (
                            <Box
                              position="absolute"
                              top={0}
                              left={0}
                              width="100%"
                              height="100%"
                              backgroundColor="rgba(119, 118, 118, 0.4)"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              opacity={0.2}
                              zIndex={2}
                            >
                              <CircularProgress
                                size={50}
                                sx={{
                                  color: "#ffffff",
                                  position: "absolute",
                                }}
                              />
                            </Box>
                          )}
                          <Avatar
                            alt="User Profile Photo"
                            src={
                              (isURL(selectedImage)
                                ? `${selectedImage}?${new Date().getTime()}`
                                : selectedImage) || avatar
                            }
                            sx={{
                              width: 200,
                              height: 200,
                              borderRadius: "0%",
                              marginBottom: "0px",
                              objectFit: "contain",
                              padding: 1,
                              backgroundColor: "#ffffff",
                              boxShadow: "0px 0px 8px #00000029",
                              visibility: imageLoading
                                ? "hidden"
                                : "visible",
                            }}
                            onError={(e) => {
                              e.target.src = avatar;
                              e.target.onerror = null;
                              setImageLoading(false);
                            }}
                            onLoad={() => setImageLoading(false)}
                          />
                          {editMode && (
                            <label
                              htmlFor="image-upload"
                              style={cameraIconStyle}
                            >
                              <input
                                id="image-upload"
                                type="file"
                                onChange={(e) => {
                                  let file = null;
                                  file = e.target?.files[0];
                                  const maxSize = 5 * 1024 * 1024;
                                  if (file) {
                                    if (
                                      !isSupportedFileType(file.type)
                                    ) {
                                      notifyError(
                                        "Please select a valid image format, such as .jpeg, .jpg, .png, .gif or .bmp."
                                      );
                                    } else if (file.size > maxSize) {
                                      notifyError(
                                        "Image size should not exceed 5 MB."
                                      );
                                    } else {
                                      setFieldValue(
                                        "profile_pic",
                                        file
                                      );
                                      const reader = new FileReader();
                                      reader.onload = (e) => {
                                        setSelectedImage(
                                          e.target.result
                                        );
                                      };
                                      reader.readAsDataURL(file);
                                    }
                                  }
                                }}
                                style={{ display: "none" }}
                              />
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                              >
                                <PhotoCameraIcon />
                              </IconButton>
                            </label>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                    <CustomScrollbars height={"42vh"}>
                      <Grid container marginBottom={2}>
                        <Grid
                          container
                          columnSpacing={5}
                          rowSpacing={2}
                          paddingLeft={1}
                        >
                          {fields?.slice(0, editMode ? 11 : 4)?.map((field, index) => (
                            <Grid item xs={12} md={6}>
                              <React.Fragment key={field.key}>
                                {!editMode ? (
                                  <Grid container spacing={2}>
                                    <Grid item xs={5}>
                                      <Typography variant="subtitle1">
                                        {field.label}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={7}>
                                      {
                                        <>
                                          <Typography
                                            variant="body1"
                                            component="div"
                                            sx={{ marginBottom: "5px" }}
                                          >
                                            <span
                                              style={{
                                                fontWeight: 700,
                                              }}
                                            >
                                              :&nbsp;&nbsp;&nbsp;
                                            </span>
                                            {field?.fieldName ===
                                              "speciality" ||
                                              field?.fieldName === "specialty"
                                              ? values[
                                                field?.fieldName
                                              ]?.join(", ")
                                              : values[field?.fieldName]}
                                          </Typography>
                                        </>
                                      }
                                    </Grid>
                                  </Grid>
                                ) : <Input data={field} />
                                }
                                <ErrorMessage
                                  name={field.fieldName}
                                  component="div"
                                  style={{
                                    color: "red",
                                    fontSize: "15px",
                                    marginBottom: 1,
                                    marginTop: "10px",
                                  }}
                                />
                              </React.Fragment>
                            </Grid>
                          ))}
                          {!editMode && (
                            <Grid item xs={12} md={6}>
                              <React.Fragment key={"address"}>
                                <Grid container spacing={2}>
                                  <Grid item xs={5}>
                                    <Typography variant="subtitle1">
                                      Address
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={7}>
                                    {
                                      <>
                                        <Typography
                                          variant="body1"
                                          component="div"
                                          sx={{ marginBottom: "5px" }}
                                        >
                                          <span style={{ fontWeight: 700 }}>
                                            :&nbsp;&nbsp;&nbsp;
                                          </span>
                                          {`${memberData?.address ? memberData?.address?.address_line_1 : ""}${memberData?.address?.address_line_1 ? "," : ""
                                            } ${memberData?.address?.address_line_2 || ""}${memberData?.address?.address_line_2 ? "," : ""
                                            } ${memberData?.address?.state || ""}${memberData?.address?.state ? "," : ""} ${memberData?.address?.country || ""
                                            }${memberData?.address?.country ? "," : ""} ${memberData?.address?.zip_code || ""
                                            }`}
                                        </Typography>
                                      </>
                                    }
                                  </Grid>
                                </Grid>
                              </React.Fragment>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </CustomScrollbars>
                  </Grid>
                </Grid>
              }
            </Form>
          )}
        </Formik>
      }
      {/* </CustomScrollbars> */}
      <DrawerComponent
        open={openDrawer}
        handleClose={() => { setOpenDrawer(false) }}
        data={<AddFamilyMember setOpenDrawer={setOpenDrawer} setRefreshList={setRefreshList}
        />}
      />
      <ConfirmBox
        handleClose={() => setOpenCnf(false)}
        open={openCnf}
        handleSubmit={() => handleDeleteSubmit()}
      />
      {/* </Grid> */}
      {/* </CardContent> */}
      <CardActions sx={{ position: "absolute", bottom: 0, width: "100%" }}>
        {pageCount !== 0 && loading && !editingMember && (
          <Grid item xs={12} marginTop={2}>
            <CustomPagination
              count={pageCount}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              data={allData}
            />
          </Grid>
        )}
      </CardActions>
      {/* </Card> */}
    </Grid>
  );
};

export default FamilyData;
