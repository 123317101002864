import config from "../../config";
import httpClient from "../../service/base";

const BACKEND_BASE_URL = config.BACKEND_BASE_URL;
export const SettingsApi = {
  addUser: (userType, payload) =>
    httpClient.post(`${BACKEND_BASE_URL}users/api/v1/${userType}`, payload),
  getUser: (userType, pageNumber, sortDirection, sortedColumn, searchText) =>
    httpClient.get(`${BACKEND_BASE_URL}users/api/v1/${userType}?`, {
      offset: pageNumber,
      limit: 10,
      order_by: sortDirection,
      sort_by: sortedColumn,
      search_by: searchText,
      search_on: "first_name,last_name,email,phone_number",
    }),
  auditLog: (currentPage, sortDirection, sortedColumn, onEndDate, onStartDate, type) =>
    httpClient.get(`${BACKEND_BASE_URL}users/api/v1/audit/log`, {
      offset: currentPage,
      limit: 10,
      order_by: sortDirection,
      sort_by: sortedColumn,
      end_date: onEndDate !== "NaN-NaN-NaN" ? onEndDate : null,
      start_date: onStartDate !== "NaN-NaN-NaN" ? onStartDate : null,
      type
    }),
  auditEvent: () =>
    httpClient.get(`${BACKEND_BASE_URL}users/api/v1/audit/event`),
  updateAdminUser: (uuid, payload) =>
    httpClient.put(`${BACKEND_BASE_URL}users/api/v1/admin/${uuid}`, payload),
  AddProfileImage: (uuid, payload) =>
    httpClient.put(
      `${BACKEND_BASE_URL}users/api/v1/admin/pic/${uuid}`,
      payload,
      {},
      (Headers = {
        "Content-Type": "multipart/form-data",
      })
    ),

  AddSuperAdminProfileImage: (payload) =>
    httpClient.put(
      `${BACKEND_BASE_URL}users/api/v1/current_user/pic`,
      payload,
      {},
      (Headers = {
        "Content-Type": "multipart/form-data",
      })
    ),
  deleteUser: (uuid) =>
    httpClient.delete(`${BACKEND_BASE_URL}users/api/v1/admin/${uuid}`),
  getNotification: () => httpClient.get(`${BACKEND_BASE_URL}settings/api/v1/notification`),
  updateNotification: (payload) =>
    httpClient.put(`${BACKEND_BASE_URL}settings/api/v1/notification`, payload),

  fetchProviderData: (provider_group_id, provider_id) =>
    httpClient.get(`${BACKEND_BASE_URL}settings/api/v1/provider_group/${provider_group_id}/provider/${provider_id}`),

  getProviderData: (provider_group_id, query) =>
    httpClient.get(`${BACKEND_BASE_URL}settings/api/v1/ehr_setting/provider_group/${provider_group_id}`, { ...query }),

  getEhrAuditList: (provider_group_id, query) =>
    httpClient.get(`${BACKEND_BASE_URL}settings/api/v1/ehr_audit/provider_group/${provider_group_id}`, { ...query }),

  getEHRList: (provider_group_id) =>
    httpClient.get(`${BACKEND_BASE_URL}settings/api/v1/provider_group/${provider_group_id}`),

};
