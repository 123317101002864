import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import MoreIcon from "@mui/icons-material/MoreVert";
import { IconArrowsSort } from "@tabler/icons-react";
import {
  Grid,
  IconButton,
  Popover,
  Switch,
  Typography,
  Menu,
  MenuItem,
  CircularProgress,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import DrawerComponent from "../../../../../../../../ui-component/Drawer";
import { customDataTableStyles } from "../../../../../../../../ccm-constant";
import { PatientApi } from "../../../../../../../../service/ProviderGroup/Patient";
import { useNotification } from "../../../../../../../../hooks/use-notification";
import ConfirmBox from "../../../../../../../../ui-component/ConfirmBox";
import CustomPagination from "../../../../../../../../ui-component/Pagination";
import { CustomScrollbars } from "../../../../../../../../ui-component/ScrollBar";
import PatientDocumentForm from "./Features/AddDocument/Index";
import ViewDocument from "./Features/ViewDocument/Index";
import TimeLogForm from "../TimeLog/Features/Index";
import { useProviderContext } from "../..";
import { formatDate } from "../../../../../../../../ccm-constant";

const DocumentData = ({ setOpenDrawer, openDrawer }) => {
  const {
    searchText,
  } = useProviderContext();
  const navigate = useNavigate();
  const { id } = useParams();
  let userType = JSON.parse(localStorage.getItem("user"))?.tokens?.userRole;
  let ProviderId = userType === "provider_group" ? JSON.parse(localStorage.getItem("user"))?.user?.data?.uuid :
    JSON.parse(localStorage.getItem("user"))?.user?.data?.provider_group?.uuid;
  const { notifySuccess, notifyError } = useNotification();
  const [sortedColumn, setSortedColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("-id");
  const [userData, setUserData] = useState([]);
  console.log("userDataDoc", userData);
  const [openCnf, setOpenCnf] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [allData, setAllData] = useState();
  const [refreshList, setRefreshList] = useState(false);
  const [deleteDocumentId, setDeleteDocumentId] = useState('');
  const [viewDocumentData, setViewDocumentData] = useState('');
  console.log("DTAJN", viewDocumentData)
  const [viewDocument, setViewDocument] = useState(false);
  const [drawerSize, setDrawerSize] = useState(false);
  console.log("drawerSize", drawerSize)
  const [previewDocumnet, setPreviewDocument] = useState(false);
  const ProviderRole = JSON.parse(localStorage.getItem("user"))?.user?.data?.role;

  const DocumentColumns = [
    {
      name: "No",
      selector: (row, id) => id + 1 + (currentPage ? (currentPage - 1) * 10 : ""),
      width: "8%",
    },
    {
      name: "Name",
      selector: (row)=> row.file_name,
      sortable: true,
      width: "30%",
      cell: (row) => (
        <Typography onClick={() => handleRowClick(row)} style={{
          whiteSpace: 'pre-line',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
        }}>
          {row?.file_name}
        </Typography>
      ),
    },
    {
      name: "Type",
      selector: (row)=> row.document_type,
      sortable: true,
      width: "18%",
    },
    {
      name: "Modified",
      selector:(row)=> row.updated_at,
      cell: (row) => 
          <Typography onClick={() => handleRowClick(row)}>
            {formatDate(row?.updated_at)}
          </Typography>
      ,
      sortable: true,
      width: "18%",
    },
    {
      name: "Modified By",
      selector: (row)=> row.created_by,
      // sortable: true,
      width: "18%",
    },
    {
      selector: "",
      name: "",
      width: "8%",
      // cell: (row) => <MoreButton row={row} />,
      cell: (row) => 
        (userType === "provider" && (ProviderRole === "Care Team" || ProviderRole === "Physician")) ? 
          <MoreButton row={row} /> :
          null
    },
  ];

  const getDocumentData = () => {
    setLoading(true);
    console.log("I am calling");
    const query = {
      search: searchText,
      limit: 10,
      offset:
        currentPage === 0
          ? currentPage
          : searchText
            ? 0
            : (currentPage - 1) * 10,
      order_by: sortDirection,
      // sort_by: sortedColumn,
    };
    PatientApi.getAllPatientDocuments(id, query)
      .then((response) => {
        setUserData(response.data);
        setPageCount(response.count);
        setAllData(response);
        setLoading(false);
        setRefreshList(false);
      })
      .catch((error) => {
        console.error("Error fetching Time Log data:", error);
        setLoading(false);
      });
  };

  const handleSort = (column, sortDirection) => {
    switch (column.name) {
      case "Name":
        setSortDirection(sortDirection == "desc" ? '-file_name' : "file_name");
        break;
      case "Type":
        setSortDirection(sortDirection == "desc" ? '-document_type' : "document_type");
        break;
      case "Modified":
        setSortDirection(sortDirection == "desc" ? '-updated_at' : "updated_at");
        break;
      case "Modified By":
        setSortDirection(sortDirection == "desc" ? '-created_by' : "created_by");
        break;
      default:
        setSortDirection("-id");
    }
  };

  useEffect(() => {
    if (refreshList) {
      getDocumentData();
    }
  }, [refreshList]);

  useEffect(() => {
    setLoading(true);
    const delay = setTimeout(() => {
      getDocumentData();
    }, 1000);

    return () => {
      clearTimeout(delay);
    };
  }, [searchText, sortDirection, sortedColumn, currentPage]);

  const MoreButton = ({ row }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
      <div>
        <IconButton onClick={handleClick}>
          <MoreIcon />
        </IconButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                setViewDocument(true);
                setViewDocumentData(row);
              }}
            >
              {`${row?.note !== "" ? "Edit note" : "Add note"}`}
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpenCnf(true);
                setDeleteDocumentId(row?.uuid);
              }}
            >
              Delete
            </MenuItem>
          </Menu>
        </Popover>
      </div>
    );
  };

  const handleDeleteSubmit = async () => {
    PatientApi.deletePatientDocument(deleteDocumentId)
      .then((response) => {
        console.log("response", response);
        notifySuccess("Document deleted successfully");
        setOpenCnf(false);
        getDocumentData();
      })
      .catch((error) => {
        console.error("Error fetching delete document data:", error.response?.data?.error);
        notifyError(error?.response?.data?.error);
        setOpenCnf(false);
      });
  };

  const handleRowClick = (row) => {
    setViewDocumentData(row);
    setViewDocument(true);
    setPreviewDocument(true);
    if (["doc", "docx", "xlsx", "pdf"].includes(row?.document_type)) {
      setDrawerSize(true);
      console.log("type", row?.document_type)
    }
    else {
      setDrawerSize(false);
    }
  };

  return (
    <Grid>
      <Grid item xs={12} sx={{ height: "75vh", position: "relative" }}>
        {/* <Card sx={{ height: "75vh", position: "relative" }}> */}
        {/* <CardContent> */}
        <Grid
          container
          xs={12}
          padding={1}
          sx={{ flex: 1, alignItems: "center", marginBottom: "10px" }}
        >
          <CustomScrollbars height="58vh">
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {!loading ? (
                <DataTable
                  columns={DocumentColumns}
                  data={userData}
                  sortIcon={<IconArrowsSort size={18} marginLeft={1} />}
                  onSort={handleSort}
                  onRowClicked={handleRowClick}
                  highlightOnHover
                  pointerOnHover
                  customStyles={customDataTableStyles}
                />
              ) : (
                <CircularProgress
                  color="primary"
                  sx={{ marginTop: "15%" }}
                />
              )}
            </Grid>
          </CustomScrollbars>
          {viewDocument ?
            <DrawerComponent
              open={viewDocument}
              handleClose={() => {
                setViewDocument(false);
                setDrawerSize(false);
              }}
              drawerSize={drawerSize}
              data={<ViewDocument setViewDocument={setViewDocument}
                setRefreshList={setRefreshList}
                viewDocumentData={viewDocumentData}
                previewDocumnet={previewDocumnet}
                setPreviewDocument={setPreviewDocument}
                setDrawerSize={setDrawerSize}
              />}
            />
            :
            <DrawerComponent
              open={openDrawer}
              handleClose={() => { setOpenDrawer(false) }}
              data={<PatientDocumentForm setOpenDrawer={setOpenDrawer} setRefreshList={setRefreshList} />}
            />
          }

          <ConfirmBox
            handleClose={() => setOpenCnf(false)}
            open={openCnf}
            handleSubmit={() => handleDeleteSubmit()}
          />
        </Grid>
        {/* </CardContent> */}
        <CardActions sx={{ position: "absolute", bottom: 0, width: "100%" }}>
          {pageCount !== 0 && !loading && (
            <Grid item xs={12} marginTop={2}>
              <CustomPagination
                count={pageCount}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                data={allData}
              />
            </Grid>
          )}
        </CardActions>
        {/* </Card> */}
      </Grid>
    </Grid>
  );
};

export default DocumentData;
