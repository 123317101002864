import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import MoreIcon from "@mui/icons-material/MoreVert";
import { IconArrowsSort } from "@tabler/icons-react";
import {
  Grid,
  IconButton,
  Popover,
  Typography,
  Menu,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { customDataTableStyles } from "../../../../ccm-constant";
import { CustomScrollbars } from "../../../../ui-component/ScrollBar";
import { formatDate } from "../../../../ccm-constant";
import { Billing } from "../../../../service/Billing";
import avatar from "../../../../assets/adminAvatar.jpg";

const CCMBilling = ({
  setConfigure,
  setIsViewReport,
  setSelectedRows,
  setAdherenceCount,
  setPatientData,
  selectedMonth,
  selectedYear,
  refreshed,
  searchText,
  ConfigData,
  setConfigData,
  setRefreshed,
}) => {
  const navigate = useNavigate();
  let userType = JSON.parse(localStorage.getItem("user"))?.tokens?.userRole;
  let ProviderId =
    userType === "provider_group"
      ? JSON.parse(localStorage.getItem("user"))?.user?.data?.uuid
      : JSON.parse(localStorage.getItem("user"))?.user?.data?.provider_group
          ?.uuid;
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const ProviderRole = JSON.parse(localStorage.getItem("user"))?.user?.data
    ?.role;
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSelectedRowsChange = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const [filteredItems, setFilteredItems] = useState();

  useEffect(() => {
    console.log("searchText", searchText);
    setFilteredItems(
      searchText
        ? userData.filter(
            (item) =>
              item.patient.first_name
                .toLowerCase()
                .includes(searchText.toLowerCase()) ||
              item.patient.last_name
                .toLowerCase()
                .includes(searchText.toLowerCase()) ||
              item.patient?.patient_id
                ?.toLowerCase()
                .includes(searchText.toLowerCase()) ||
              item.patient?.provider_details?.first_name
                .toLowerCase()
                .includes(searchText.toLowerCase()) ||
              item.patient?.provider_details?.last_name
                .toLowerCase()
                .includes(searchText.toLowerCase()) ||
              String(item.total_time).includes(searchText.toLowerCase()) ||
              String(item.billing_status).includes(searchText.toLowerCase())
          )
        : userData
    );

    console.log("searchText", filteredItems);
  }, [searchText, userData]);

  const TimeLogColumns = [
    {
      name: "Patient ID",
      selector: (row) => row?.patient?.patient_id,
      width: "180px",
      sortable: true,
    },
    {
      name: "Patient Name",
      // wrap: true,
      selector: (row) => (
        <>
          {row?.patient?.provider_details ? (
            <Grid
              item
              display={"flex"}
              justifyContent={"left"}
              alignItems={"center"}
              onClick={() =>
                navigate(`/billing/patient/profile/${row?.patient?.uuid}`)
              }
            >
              <img
                src={
                  row?.patient?.profile_pic
                    ? `${row?.patient?.profile_pic}?${
                        new Date().getSeconds() + 10
                      }`
                    : avatar
                }
                alt="Image"
                width={40}
                height={40}
                style={{
                  marginRight: 10,
                  borderRadius: "50%",
                  padding: "1px",
                  border: "1px solid #dfdfdf",
                }}
                onError={(e) => {
                  // e.target.src = avatar;
                  e.target.onerror = null; // Prevent an infinite loop in case the error image also fails to load
                }}
              />
              <span style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                {row?.patient?.first_name} {row?.patient?.last_name}
              </span>
            </Grid>
          ) : (
            ""
          )}
        </>
      ),
      width: "220px",
      // sortable: true,
    },
    {
      name: "Provider",
      wrap: true,
      // sortable: true,
      selector: (row) => (
        <span
          style={{ textOverflow: "ellipsis", overflow: "hidden" }}
          onClick={() =>
            navigate(`/billing/patient/profile/${row?.patient?.uuid}`)
          }
        >
          {row?.patient?.provider_details
            ? `${row?.patient?.provider_details?.first_name} ${row?.patient?.provider_details?.last_name}`
            : ""}
        </span>
      ),
      width: "180px",
    },
    {
      name: <div>99490-Initial 20 Mins Non-Face to Face monitoring</div>,
      selector: (row) => {
        const eligibleObject = row?.billable_object?.find(
          (element) => element?.label === "99490"
        );
        return eligibleObject?.value[eligibleObject?.value?.length - 1]
          ?.created_at ? (
          <span
            onClick={() =>
              navigate(`/billing/patient/profile/${row?.patient?.uuid}`)
            }
          >
            <Grid display={"flex"} justifyContent={"flex-start"}>
              <Typography
                variant="body1"
                color={"#0BA800"}
                fontWeight={500}
                marginRight={1}
              >
                Eligible
              </Typography>
              {eligibleObject?.value?.length >= 2 ? (
                <sub style={{ color: "#0BA800", fontWeight: 600 }}>
                  {" "}
                  X {eligibleObject?.value?.length}
                </sub>
              ) : null}
            </Grid>
            <span>
              Initial 20 Min Completed On <br />
              {formatDate(
                eligibleObject?.value[eligibleObject?.value?.length - 1]
                  ?.created_at
              )}
            </span>
          </span>
        ) : (
          <div
            onClick={() =>
              navigate(`/billing/patient/profile/${row?.patient?.uuid}`)
            }
          >
            <Typography variant="body1" fontWeight={500}>
              Not Eligible
            </Typography>
            <span>Insufficient Treatment Time.</span>
          </div>
        );
      },
      wrap: true,
      width: "300px",
      // sortable: true,
    },
    {
      name: <div>99439- Additional 20 Mins Non-face to face monitoring</div>,
      selector: (row) => {
        const eligibleObject = row?.billable_object?.find(
          (element) => element?.label === "99439"
        );
        return eligibleObject?.value[eligibleObject?.value?.length - 1]
          ?.created_at ? (
          <span
            onClick={() =>
              navigate(`/billing/patient/profile/${row?.patient?.uuid}`)
            }
          >
            <Grid display={"flex"} justifyContent={"flex-start"}>
              <Typography
                variant="body1"
                color={"#0BA800"}
                fontWeight={500}
                marginRight={1}
              >
                Eligible
              </Typography>
              {eligibleObject?.value?.length >= 2 ? (
                <sub style={{ color: "#0BA800", fontWeight: 600 }}>
                  {" "}
                  X {eligibleObject?.value?.length}
                </sub>
              ) : null}
            </Grid>
            <span>
              Additional 20 Min Completed On <br />
              {formatDate(
                eligibleObject?.value[eligibleObject?.value?.length - 1]
                  ?.created_at
              )}
            </span>
          </span>
        ) : (
          <div
            onClick={() =>
              navigate(`/billing/patient/profile/${row?.patient?.uuid}`)
            }
          >
            <Typography variant="body1" fontWeight={500}>
              Not Eligible
            </Typography>
            <span>Insufficient Treatment Time.</span>
          </div>
        );
      },
      wrap: true,
      width: "300px",
      // sortable: true,
    },
    {
      name: <div>99491- Initial 30 Mins Personal care monitoring </div>,
      selector: (row) => {
        const eligibleObject = row?.billable_object?.find(
          (element) => element?.label === "99491"
        );
        return eligibleObject?.value[eligibleObject?.value?.length - 1]
          ?.created_at ? (
          <span
            onClick={() =>
              navigate(`/billing/patient/profile/${row?.patient?.uuid}`)
            }
          >
            <Grid display={"flex"} justifyContent={"flex-start"}>
              <Typography
                variant="body1"
                color={"#0BA800"}
                fontWeight={500}
                marginRight={1}
              >
                Eligible
              </Typography>
              {eligibleObject?.value?.length >= 2 ? (
                <sub style={{ color: "#0BA800", fontWeight: 600 }}>
                  {" "}
                  X {eligibleObject?.value?.length}
                </sub>
              ) : null}
            </Grid>
            <span>
              Initial 30 Min Completed On <br />
              {formatDate(
                eligibleObject?.value[eligibleObject?.value?.length - 1]
                  ?.created_at
              )}
            </span>
          </span>
        ) : (
          <div
            onClick={() =>
              navigate(`/billing/patient/profile/${row?.patient?.uuid}`)
            }
          >
            <Typography variant="body1" fontWeight={500}>
              Not Eligible
            </Typography>
            <span>Insufficient Treatment Time.</span>
          </div>
        );
      },
      wrap: true,
      // sortable: true,

      width: "300px",
    },
    {
      name: <div>99437- Additional 30 Mins Personal care monitoring </div>,
      selector: (row) => {
        const eligibleObject = row?.billable_object?.find(
          (element) => element?.label === "99437"
        );
        return eligibleObject?.value[eligibleObject?.value?.length - 1]
          ?.created_at ? (
          <span
            onClick={() =>
              navigate(`/billing/patient/profile/${row?.patient?.uuid}`)
            }
          >
            <Grid display={"flex"} justifyContent={"flex-start"}>
              <Typography
                variant="body1"
                color={"#0BA800"}
                fontWeight={500}
                marginRight={1}
              >
                Eligible
              </Typography>
              {eligibleObject?.value?.length >= 2 ? (
                <sub style={{ color: "#0BA800", fontWeight: 600 }}>
                  {" "}
                  X {eligibleObject?.value?.length}
                </sub>
              ) : null}
            </Grid>

            <span>
              Additional 30 Min Completed On <br />
              {formatDate(
                eligibleObject?.value[eligibleObject?.value?.length - 1]
                  ?.created_at
              )}
            </span>
          </span>
        ) : (
          <div
            onClick={() =>
              navigate(`/billing/patient/profile/${row?.patient?.uuid}`)
            }
          >
            <Typography variant="body1" fontWeight={500}>
              Not Eligible
            </Typography>
            <span>Insufficient Treatment Time.</span>
          </div>
        );
      },
      wrap: true,
      width: "300px",
      // sortable: true,
    },
    {
      name: <div>99487- Initial 60 Mins Non-face to face consultation</div>,
      selector: (row) => {
        const eligibleObject = row?.billable_object?.find(
          (element) => element?.label === "99487"
        );
        return eligibleObject?.value[eligibleObject?.value?.length - 1]
          ?.created_at ? (
          <span
            onClick={() =>
              navigate(`/billing/patient/profile/${row?.patient?.uuid}`)
            }
          >
            <Grid display={"flex"} justifyContent={"flex-start"}>
              <Typography
                variant="body1"
                color={"#0BA800"}
                fontWeight={500}
                marginRight={1}
              >
                Eligible
              </Typography>
              {eligibleObject?.value?.length >= 2 ? (
                <sub style={{ color: "#0BA800", fontWeight: 600 }}>
                  {" "}
                  X {eligibleObject?.value?.length}
                </sub>
              ) : null}
            </Grid>
            <span>
              Initial 60 Min Completed On <br />
              {formatDate(
                eligibleObject?.value[eligibleObject?.value?.length - 1]
                  ?.created_at
              )}
            </span>
          </span>
        ) : (
          <div
            onClick={() =>
              navigate(`/billing/patient/profile/${row?.patient?.uuid}`)
            }
          >
            <Typography variant="body1" fontWeight={500}>
              Not Eligible
            </Typography>
            <span>Insufficient Treatment Time.</span>
          </div>
        );
      },
      wrap: true,
      width: "300px",
      // sortable: true,
    },
    {
      name: <div>99489- Additional 30 Mins Non-face to face consultation </div>,
      selector: (row) => {
        const eligibleObject = row?.billable_object?.find(
          (element) => element?.label === "99489"
        );
        return eligibleObject?.value[eligibleObject?.value?.length - 1]
          ?.created_at ? (
          <span
            onClick={() =>
              navigate(`/billing/patient/profile/${row?.patient?.uuid}`)
            }
          >
            <Grid display={"flex"} justifyContent={"flex-start"}>
              <Typography
                variant="body1"
                color={"#0BA800"}
                fontWeight={500}
                marginRight={1}
              >
                Eligible
              </Typography>
              {eligibleObject?.value?.length >= 2 ? (
                <sub style={{ color: "#0BA800", fontWeight: 600 }}>
                  {" "}
                  X {eligibleObject?.value?.length}
                </sub>
              ) : null}
            </Grid>
            <span>
              Additional 30 Min Completed On <br />
              {formatDate(
                eligibleObject?.value[eligibleObject?.value?.length - 1]
                  ?.created_at
              )}
            </span>
          </span>
        ) : (
          <div
            onClick={() =>
              navigate(`/billing/patient/profile/${row?.patient?.uuid}`)
            }
          >
            <Typography variant="body1" fontWeight={500}>
              Not Eligible
            </Typography>
            <span>Insufficient Treatment Time.</span>
          </div>
        );
      },
      wrap: true,
      // sortable: true,

      width: "300px",
    },

    {
      name: <div>Time Monitoring</div>,
      selector: (row) => `${row?.total_time} mins`,
      width: "200px",
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row?.patient?.billing_status,
      cell: (row) => (
        <div
          style={{
            fontWeight: 500,
            color:
              row?.patient?.billing_status === "Submitted"
                ? "#7EC379"
                : row?.patient?.billing_status === "Error"
                ? "#FFBF26"
                : row?.patient?.billing_status === "Ready for billing"
                ? "#022C6B"
                : "",
          }}
          onClick={() =>
            navigate(`/billing/patient/profile/${row?.patient?.uuid}`)
          }
        >
          {row?.patient?.billing_status ? row?.patient?.billing_status : ""}{" "}
        </div>
      ),
      width: "200px",
      sortable: true,
    },
    {
      selector: "",
      name: "",
      width: "80px",
      cell: (row) => {
        return <MoreButton row={row} />;
      },
    },
  ];

  const getTimeLogData = () => {
    setLoading(false);
    Billing.getBillingData(
      ProviderId,
      new Date(selectedMonth).getMonth() + 1,
      new Date(selectedYear).getFullYear()
    )
      .then((response) => {
        setUserData(response.data);
        setAdherenceCount(response?.count);
        setLoading(true);
        setRefreshed(false);
      })
      .catch((error) => {
        console.error("Error fetching Time Log data:", error);
        setLoading(true);
      });
  };


  useEffect(() => {
    if(refreshed)
    getTimeLogData();
  }, [refreshed]);


  useEffect(() => {
    if(selectedMonth && selectedYear)
    getTimeLogData();
  }, [selectedMonth, selectedYear]);

  const MoreButton = ({ row }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    console.log("row111111", row.patient.uuid);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
      <div>
        <IconButton onClick={handleClick}>
          <MoreIcon />
        </IconButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                setConfigure(true);
                setIsViewReport(true);
                setPatientData(row);
              }}
            >
              View Report
            </MenuItem>
            <MenuItem
              onClick={() => {
                setConfigure(true);
                setIsViewReport(false);
                setPatientData(row);
                setConfigData({
                  date: row?.patient?.last_billing_date
                    ? formatDate(row?.patient?.last_billing_date)
                    : " - ",
                });
              }}
            >
              Configure
            </MenuItem>
          </Menu>
        </Popover>
      </div>
    );
  };

  const handleSort = (column, order) => {
    console.log("column", column);
    setSortField(column.selector);
    setSortOrder(order);
  };

  return (
    <Grid item xs={12} sx={{ position: "relative" }}>
      <Grid
        container
        xs={12}
        padding={1}
        sx={{ flex: 1, alignItems: "center", marginBottom: "20px" }}
      >
        <CustomScrollbars height="50vh">
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            marginBottom={3}
          >
            {loading ? (
              <DataTable
                columns={TimeLogColumns}
                data={filteredItems}
                selectableRows
                selectableRowsVisibleOnly
                onSelectedRowsChange={handleSelectedRowsChange}
                onRowClicked={(e) => {
                  navigate(`/billing/patient/profile/${e?.patient?.uuid}`);
                }}
                sortIcon={<IconArrowsSort size={18} marginLeft={1} />}
                onSort={handleSort}
                sortField={sortField}
                sortDirection={sortOrder}
                highlightOnHover
                pointerOnHover
                customStyles={{
                  ...customDataTableStyles,
                  selectableRowsCheckbox: {
                    style: {
                      height: "54px",
                      width: "54px",
                      "&:hover": {
                        backgroundColor: "unset",
                      },
                    },
                  },
                }}
              />
            ) : (
              <CircularProgress color="primary" sx={{ marginTop: "15%" }} />
            )}
          </Grid>
        </CustomScrollbars>
      </Grid>
    </Grid>
  );
};

export default CCMBilling;
