import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Divider } from "@mui/material";
import {
  Button,
  Checkbox,
  Grid,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Billing } from "../../../../service/Billing";
import PDFService from "../../../../ui-component/HTMLtoPDF/PDFService";
import { useNotification } from "../../../../hooks/use-notification";
import { ProviderGroupApi } from "../../../../service/ProviderGroup";

import config from "../../../../config";
import CircularLoader from "../../../../ui-component/CircularLoader";

const BACKEND_BASE_URL = config.BACKEND_BASE_URL;

const Configure = ({ handleClose, isViewReport, setRefreshed, patientData, ConfigData, patientId }) => {
  console.log("ConfigData", ConfigData);
  const pdfServiceRef = useRef();
  const { id } = useParams();
  console.log("id", id);
  const User = JSON.parse(localStorage.getItem("user") || "{}");
  const token = User.tokens?.accessToken;
  const { notifySuccess, notifyError } = useNotification();
  const [reportData, setReportData] = useState();
  const [configureData, setConfigureData] = useState();
  const [viewReport, setViewReport] = useState(isViewReport || false);
  const [sendReport, setSendReport] = useState(false);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [reportLoading, setReportLoading] = useState(false)

  let userType = JSON.parse(localStorage.getItem("user"))?.tokens?.userRole;
  let ProviderId =
    userType === "provider_group"
      ? JSON.parse(localStorage.getItem("user"))?.user?.data?.uuid
      : JSON.parse(localStorage.getItem("user"))?.user?.data?.provider_group
        ?.uuid;

  let providerGroup = JSON.parse(localStorage.getItem("user"))?.user?.data
    ?.provider_group?.uuid;
    let Provider_Id = JSON.parse(localStorage.getItem("user"))?.user?.data?.uuid;
  const [isTImeLog, setIsTimeLog] = useState(false)
  const [goalProgress, setGoalProgress] = useState(false)
  const [providerData, setProviderData] = useState();
  console.log("providerData", providerData);
  const [phone_number, setPhoneNumber] = useState();


  let userRole = providerData?.user_role

  let contact_no = userRole === "Provider" ? providerData?.provider_group?.phone_number : 
  providerData?.phone_number
  console.log("contact_no", contact_no);
  
  const [conditions, setConditions] = useState([
    {
      label: "Goals important to the patient",
      value: false,
    },
    {
      label: "Potential Barriers",
      value: false,
    },
    {
      label: "Potential Intervention",
      value: false,
    },
    {
      label: "Problems / Symptoms",
      value: false,
    },
    {
      label: "Expected Outcome",
      value: false,
    },
    {
      label: "Resources/Support",
      value: false,
    },
    {
      label: "Number to track",
      value: false,
    },
    {
      label: "Medication",
      value: false,
    },
    {
      label: "Allergies",
      value: false,
    },
    {
      label: "Additional Care Plan Notes for current month",
      value: false,
    },
    {
      label: "Goal with there history of progress",
      value: false,
    },
    {
      label: "Medication",
      value: false,
    },
    {
      label: "Allergies",
      value: false,
    },
    {
      label: "Conditions",
      value: false,
    },
    {label:"timelog", value:false}
  ]);
  console.log("conditions", conditions);

  const handleCheckboxChange = (index) => {
    const updatedConditions = [...conditions];
    updatedConditions[index].value = !updatedConditions[index].value;
    setConditions(updatedConditions);
  };

  const Details = [
    {
      label: "First Name",
      value: "Alexa",
    },
    {
      label: "Middle Name",
      value: "Alexa",
    },
    {
      label: "Last Name",
      value: "Alexa",
    },
    {
      label: "Gender",
      value: "Female",
    },
    {
      label: "Phone Number",
      value: "888898989",
    },
    {
      label: "Email Id",
      value: "alexa@gmail.com",
    },
  ];

  useEffect(() => {
    setReportLoading(true)
    Billing.getReportData(
      ProviderId,
      patientData ? patientData?.patient?.uuid : patientId
    )
      .then((response) => {
        console.log("response2", response);
        setReportData(response);
        setReportLoading(false);
      })
      .catch((error) => {
        setReportLoading(false);
        console.error("Error fetching Time Log data:", error);
      });

    Billing.getCareConditionsConfigure(ProviderId)
      .then((response) => {
        console.log("response3", response.data);
        setConfigureData(response.data.configuration);
        setPhoneNumber(response.data.mobile_number);
      })
      .catch((error) => {
        // setLoading(false);
        console.error("Error fetching Time Log data:", error);
      });

    Billing.getConfig(ProviderId)
      .then((response) => {
        console.log("response4", response.data);
        setConfigureData(response.data.configuration);
      })
      .catch((error) => {
        // setLoading(false);
        console.error("Error fetching Time Log data:", error);
      });
    
      ProviderGroupApi.getProvider(Provider_Id)
      .then((response) => {
        console.log("ProviderGroupApi response", response.data);
        setProviderData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);

  const handleExportPDF = () => {
    var userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timeZoneHeader = { 'X-User-Timezone': userTimezone };

    fetch(`${BACKEND_BASE_URL}settings/api/v1/billing_configure/provider_group/${ProviderId}/patient/${patientData ? patientData?.patient?.uuid : patientId}?download=true`,
    {
      method: 'POST',
      headers: {
        ...timeZoneHeader, Authorization: `Bearer ${token}`
          }})
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Report_${new Date().getFullYear()}_${new Date().getMonth()+1}.pdf`); // Change the file name and extension
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      notifySuccess('Report downloaded successfully')
    })
    .catch(error => {
      notifyError('There was an error to downloading the file');
    });
  };

  const saveHandler = () => {
    const body = {
      configuration: {
        care_plan_sections: {
          goals_important_to_patient: conditions[0].value,
          potential_barriers: conditions[1].value,
          potential_intervention: conditions[2].value,
          symptoms: conditions[3].value,
          expected_outcome: conditions[4].value,
          support: conditions[5].value,
          numbers_to_track: conditions[6].value,
          medications: conditions[7].value,
          allergies: conditions[8].value,
          all_conditions: false,
          additional_care_plan_notes: conditions[9].value,
          // care_plan_progress_sections: {
          //   goals: conditions[10].value,
          //   barriers: conditions[11].value,
          //   interventions: conditions[12].value,
          // },
          care_plan_progress_goals:goalProgress,
        },
        clinical: {
          medications: conditions[10].value,
          allergies: conditions[11].value,
          conditions: conditions[12].value,
        },
        is_time_log:isTImeLog,
      },
    };
    setLoad(true);
    Billing.updateConfig(ProviderId, body)
      .then((response) => {
        console.log("response3", response.data);
        setLoad(false);
        handleClose();
        notifySuccess("Configuration saved successfully")
        // setConfigureData(response.data.configuration);
      })
      .catch((error) => {
        setLoad(false);
        console.error("Error fetching Time Log data:", error);
      });
  };

  useEffect(() => {
    setConditions([
      {
        label: "Goals important to the patient",
        value:
          configureData?.care_plan_sections?.goals_important_to_patient ||
          false,
      },
      {
        label: "Potential Barriers",
        value: configureData?.care_plan_sections?.potential_barriers || false,
      },
      {
        label: "Potential Intervention",
        value:
          configureData?.care_plan_sections?.potential_intervention || false,
      },
      {
        label: "Problems / Symptoms",
        value: configureData?.care_plan_sections?.symptoms || false,
      },
      {
        label: "Expected Outcome",
        value: configureData?.care_plan_sections?.expected_outcome || false,
      },
      {
        label: "Resources/Support",
        value: configureData?.care_plan_sections?.support || false,
      },
      {
        label: "Number to track",
        value: configureData?.care_plan_sections?.numbers_to_track || false,
      },
      {
        label: "Medication",
        value: configureData?.care_plan_sections?.medications || false,
      },
      {
        label: "Allergies",
        value: configureData?.care_plan_sections?.allergies || false,
      },
      {
        label: "Additional Care Plan Notes for current month",
        value:
          configureData?.care_plan_sections?.additional_care_plan_notes ||
          false,
      },
      // {
      //   label: "Goal",
      //   value:
      //     configureData?.care_plan_sections?.care_plan_progress_sections
      //       ?.goals || false,
      // },
      // {
      //   label: "Barrier",
      //   value:
      //     configureData?.care_plan_sections?.care_plan_progress_sections
      //       ?.barriers || false,
      // },
      // {
      //   label: "Interventions",
      //   value:
      //     configureData?.care_plan_sections?.care_plan_progress_sections
      //       ?.interventions || false,
      // },
      {
        label: "Medication",
        value: configureData?.clinical?.medications || false,
      },
      {
        label: "Allergies",
        value: configureData?.clinical?.allergies || false,
      },
      {
        label: "Conditions",
        value: configureData?.clinical?.conditions || false,
      },
    ]);
    setIsTimeLog(configureData?.is_time_log)
    setGoalProgress(configureData?.care_plan_sections?.care_plan_progress_goals || false)
  }, [configureData]);

  const senReportHandler = () => {
    const body = {
      html_report: reportData,
    };
    setLoading(true);
    Billing.getReportData(ProviderId, patientData?.patient?.uuid, body)
      .then((response) => {
        // setUserData(response.data);
        notifySuccess(
          "The report has been successfully sent to the registered email address!"
        );
        setRefreshed(true)
        setLoading(false);
      })
      .catch((error) => {
        notifyError(error?.response?.data?.error);
        console.error("Error fetching Time Log data:", error);
        setLoading(false);
      });
  };

  return (
    <Grid container spacing={3} width={"50vw"}>
      {viewReport ? (
        <>
          <Grid
            item
            xs={12}
            spacing={3}
            sx={{ display: "flex", justifyContent: "flex-end", padding: 0 }}
          >
            {!patientId && (
              <Button
                variant="contained"
                onClick={senReportHandler}
                style={{
                  textTransform: "none",
                  width: "auto",
                  marginRight: "20px",
                }}
              >
                Send Report
                {loading && (
                  <CircularProgress size={18} style={{ color: "white" }} />
                )}
              </Button>
            )}
            <Button
              variant="contained"
              // onClick={() => {
              //   handleClose(false);
              // }}
              onClick={handleExportPDF}
              style={{
                textTransform: "none",
                width: "auto",
                marginRight: "20px",
              }}
            >
              Download
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleClose(false);
              }}
              style={{
                textTransform: "none",
                width: "auto",
                marginRight: "20px",
              }}
            >
              Close
            </Button>
          </Grid>
          {/* <Grid
            item
            xs={12}
            marginX={3}
            sx={{ borderBottom: "1px solid #969191", paddingBottom: 3 }}
          >
            {Details?.map((element, index) => (
              <Grid container xs={12} spacing={1}>
                <Grid item width={"200px"}>
                  <Typography
                    variant="subtitle1"
                    sx={{ wordWrap: "break-word" }}
                  >
                    {element?.label}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" sx={{ wordWrap: "break-word" }}>
                    {element?.value}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid> */}

          {/* <Grid
            item
            xs={12}
            marginX={3}
            sx={{ borderBottom: "1px solid #969191", paddingBottom: 3 }}
          >
            {Details?.map((element, index) => (
              <Grid container xs={12} spacing={1}>
                <Grid item width={"200px"}>
                  <Typography
                    variant="subtitle1"
                    sx={{ wordWrap: "break-word" }}
                  >
                    {element?.label}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" sx={{ wordWrap: "break-word" }}>
                    {element?.value}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid> */}
          <Grid item xs={12}>

          {reportLoading ? (
              <Grid items>
                <CircularLoader />
              </Grid>
            ) : (
            
            <PDFService pdfExportRef={pdfServiceRef}>
              <div
                className="app"
                dangerouslySetInnerHTML={{ __html: reportData }}
              />
            </PDFService>
            )}
          </Grid>
        </>
      ) : (
        <>
          <Grid
            item
            xs={12}
            spacing={3}
            sx={{ display: "flex", justifyContent: "flex-end", padding: 0 }}
          >
            {/* <Typography variant="body1" paddingTop={1} marginRight={3}>
              Date : {ConfigData?.date}
            </Typography> */}

            <Button
              variant="outlined"
              onClick={() => {
                setViewReport(true);
              }}
              style={{
                textTransform: "none",
                width: "auto",
                marginRight: "20px",
              }}
            >
              View Report
            </Button>
            {  userType === "provider" &&<Button
              variant="contained"
              onClick={saveHandler}
              style={{
                textTransform: "none",
                width: "auto",
                marginRight: "20px",
              }}
            >
              Save
              {load && (
                <CircularProgress size={18} style={{ color: "white" }} />
              )}
            </Button>}

            <Button
              variant="contained"
              onClick={() => {
                handleClose(false);
              }}
              style={{
                textTransform: "none",
                width: "auto",
                // marginRight: "20px",
              }}
            >
              Close
            </Button>
          </Grid>

          <Grid item>
            <Typography variant="body1" fontSize={16}>
              Designated Contact Number :
              <span style={{ color: "#424ded" }}>{
                // userType === "provider_group"
                // ? JSON.parse(localStorage.getItem("user"))?.user?.data?.provider_group?.phone_number
                  // : JSON.parse(localStorage.getItem("user"))?.user?.data?.provider_group?.phone_number
                  phone_number
            }</span>
            </Typography>
            <Typography variant="body1" fontSize={16}>
              Last Billing Date : {ConfigData?.date}
            </Typography>
          </Grid>

          {/* <Grid item xs={12} marginY={1}>
            <Typography variant="body1">
              Designated Contact no. 8888076654
            </Typography>
            <Typography variant="body1" marginTop={1} marginBottom={3}>
              Last Billing Date- 6-12-2022
            </Typography>
            <Grid container spacing={3} display={"flex"} alignContent={"start"}>
              {[
                "Patients Details",
                "Conditions",
                "Medication",
                "Time Tracking summary",
              ].map((element, id) => (
                <Grid item key={id}>
                  <Typography variant="subtitle1">{element}</Typography>
                </Grid>
              ))}
            </Grid>
          </Grid> */}

          {/* <Grid item>
            <Typography variant="h6" fontSize={18}>
              RPM Report Check List
            </Typography>
            <Grid container>
              {conditions?.slice(0, 3)?.map((element, index) => (
                <Grid item xs={12} md={12} key={index}>
                  <Grid container xs={12} spacing={2}>
                    <Grid item minWidth={"210px"}>
                      <Typography variant="body1" padding={1} width>
                        {element?.label}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Checkbox
                        checked={element?.value}
                        onChange={() => handleCheckboxChange(index)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid> */}
          <Grid item>
            <Typography variant="h6" fontSize={18}>
              Care Plan Selection
            </Typography>
            <Grid container padding={1}>
              {conditions?.slice(0, 10)?.map((element, index) => (
                <Grid item xs={12} lg={12} key={index}>
                  <Grid container xs={12} spacing={2}>
                    <Grid item>
                      <Checkbox
                      disabled={  userType === "provider" ? false : true}
                        checked={element?.value}
                        onChange={() => handleCheckboxChange(index)}
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        padding={1}
                        sx={{ wordWrap: "break-word" }}
                      >
                        {element?.label}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
          {/* <Grid item>
            <Typography variant="h6" fontSize={18}>
              Care plan progress section
            </Typography> */}
            {/* <Grid container padding={1}>
              {conditions?.slice(10, 13)?.map((element, index) => (
                <Grid item xs={12} lg={12} key={index}>
                  <Grid container xs={12} spacing={2}>
                    <Grid item>
                      <Checkbox
                        checked={element?.value}
                        onChange={() => handleCheckboxChange(index + 10)}
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        padding={1}
                        sx={{ wordWrap: "break-word" }}
                      >
                        {element?.label}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid> */}
          {/* </Grid> */}
      
          <Grid item>
            <Typography variant="h6" fontSize={18}>
              Clinical
            </Typography>
            <Grid container padding={1}>
              {conditions?.slice(10, 13)?.map((element, index) => (
                <Grid item xs={12} lg={12} key={index}>
                  <Grid container xs={12} spacing={2}>
                    <Grid item>
                      <Checkbox
                        disabled={  userType === "provider" ? false : true}
                        checked={element?.value}
                        onChange={() => handleCheckboxChange(index + 10)}
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        padding={1}
                        sx={{ wordWrap: "break-word" }}
                      >
                        {element?.label}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item xs={12} display={"flex"}>
            <Typography variant="h6" fontSize={18} paddingTop={1} paddingRight={2}>
              Care Plan Goal Progress
            </Typography>
            <Checkbox
              checked={goalProgress}
              disabled={  userType === "provider" ? false : true}
              onChange={() => setGoalProgress(!goalProgress)}
            />
    </Grid>

          <Grid item xs={12} display={"flex"}>
            <Typography variant="h6" fontSize={18} paddingTop={1} paddingRight={2}>
              Time Log 
            </Typography>
            <Checkbox
              checked={isTImeLog}
              disabled={  userType === "provider" ? false : true}
              onChange={() => setIsTimeLog(!isTImeLog)}
            />
        </Grid>
        </>
      )}
      </Grid>

  );
};

export default Configure;
