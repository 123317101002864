import config from "../../config";
import { useState, useEffect } from "react";
import httpClient from "../../service/base";
import axios from "axios";

let refreshing = false;
const BACKEND_BASE_URL = config.BACKEND_BASE_URL;
export const AuthApis = {
  signin: (email, password) =>
    axios.post(`${BACKEND_BASE_URL}users/api/v1/login`, { email, password }),
  // signin: (email, password) => httpClient.post(`${BACKEND_BASE_URL}users/api/v1/login`, { email, password }),
  getMe: () => httpClient.get(`${BACKEND_BASE_URL}users/api/v1/current_user`),
  sendOTP: (email) =>
    axios.post(`${BACKEND_BASE_URL}users/api/v1/send_otp`, { email }),
  verifyOTP: (email, otp) =>
    axios.post(`${BACKEND_BASE_URL}users/api/v1/verify_otp`, { email, otp }),
  changePassword: (token, values) =>
    axios.put(
      `${BACKEND_BASE_URL}users/api/v1/change_password`,
      {
        ...values,
      },
      {
        headers: {
          Authorization: `Bearer ${token.access || token.accessToken}`,
        },
      }
    ),
  verifyUserEmail: (email) =>
  httpClient.post(
      `${BACKEND_BASE_URL}users/api/v1/verify_user`,
      { ...email },
    ),
  updateMe: (payload) =>
    httpClient.put(`${BACKEND_BASE_URL}users/api/v1/current_user`, payload),
};
