import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import MoreIcon from "@mui/icons-material/MoreVert";
import { IconArrowsSort } from "@tabler/icons-react";
import {
  Grid,
  IconButton,
  Popover,
  Switch,
  Typography,
  Menu,
  MenuItem,
  CircularProgress,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import DrawerComponent from "../../../../../../../../ui-component/Drawer";
import { customDataTableStyles } from "../../../../../../../../ccm-constant";
import { PatientApi } from "../../../../../../../../service/ProviderGroup/Patient";
import { useNotification } from "../../../../../../../../hooks/use-notification";
import avatar from "../../../../../../../../assets/adminAvatar.jpg";
import CustomPagination from "../../../../../../../../ui-component/Pagination";
import { CustomScrollbars } from "../../../../../../../../ui-component/ScrollBar";
import CareTeamForm from "./Features/Index";
import SetDefaultBox from "../../../../../../../../ui-component/SetDefaultBox";
import { useProviderContext } from "../..";

const CareTeamData = ({ setOpenDrawer, openDrawer }) => {
  const {
    searchText,
  } = useProviderContext();
  const navigate = useNavigate();
  const { id } = useParams();
  let userType = JSON.parse(localStorage.getItem("user"))?.tokens?.userRole;
  let ProviderId = userType === "provider_group" ? JSON.parse(localStorage.getItem("user"))?.user?.data?.uuid :
    JSON.parse(localStorage.getItem("user"))?.user?.data?.provider_group
      ?.uuid;
  const ProviderRole = JSON.parse(localStorage.getItem("user"))?.user?.data?.role;
  const { notifySuccess, notifyError } = useNotification();
  const [sortedColumn, setSortedColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("-id");
  const [userData, setUserData] = useState([]);
  console.log("userDataCare", userData);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [allData, setAllData] = useState();
  const [openCnf, setOpenCnf] = useState(false);
  const [unassignCof, setUnassignCof] = useState(false);
  const [unassignId, setUnassignId] = useState();
  const [refreshList, setRefreshList] = useState(false);

  const CareTeamColumns = [
    {
      name: "No",
      selector: (row, id) => id + 1 + (currentPage ? (currentPage - 1) * 10 : ""),
      width: "8%",
    },
    {
      name: "Provider Name",
      selector: (row) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "3px",
            cursor: "pointer",
          }}
        >
          <img
            src={row?.profile_pic ? `${row?.profile_pic}?${new Date().getTime()}` : avatar}
            alt="Image"
            width={40}
            height={40}
            style={{
              marginRight: 10,
              borderRadius: "50%",
              padding: "1px",
              border: "1px solid #dfdfdf",
            }}
            onError={(e) => {
              e.target.src = avatar;
              e.target.onerror = null;
            }}
          />
          {`${row.first_name} ${row.last_name}`}
        </div>
      ),
      // sortable: true,
      width: "16%",
    },
    {
      name: "Role",
      selector: (row) => (
        <span>
          {row?.role}
        </span>
      ),
      // sortable: true,
      width: "10%",
    },
    {
      name: "NPI Number",
      selector: (row) => (
        <>
          <span>
            {row?.npi}
          </span>
        </>
      ),
      // sortable: true,
      width: "14%",
    },
    {
      name: "Speciality",
      selector: (row) => (
        <span
          style={{
            whiteSpace: 'pre-line',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            // overflow: 'hidden',
            // WebkitLineClamp: 3,
          }}
        >
          {row?.speciality?.join(", ")}
        </span>
      ),
      // sortable: true,
      width: "16%",
    },
    {
      name: "Email ID",
      selector: (row) => (
        <span
          style={{
            color: "#002c6b",
          }}
        >
          {row?.email}
        </span>
      ),
      // sortable: true,
      width: "18%",
    },
    {
      name: "Contact",
      selector: (row) => (
        <span>
          {row?.phone_number}
        </span>
      ),
      // sortable: true,
      width: "13%",
    },
    {
      selector: "",
      name: "",
      width: "5px",
      // cell: (row) => <MoreButton row={row} />,
      cell: (row) => {
        if (userType === "provider_group" || (userType === "provider" && ProviderRole === "Physician")) {
          return <MoreButton row={row} />;
        } else {
          return null;
        }
      },
    },
  ];

  const getCareTeamData = () => {
    setLoading(false);
    console.log("I am calling");
    const query = {
      search: searchText,
      limit: 10,
      offset:
        currentPage === 0
          ? currentPage
          : searchText
            ? 0
            : (currentPage - 1) * 10,
      order_by: sortDirection,
      // sort_by: sortedColumn,
    };
    PatientApi.getAllCareTeams(id, query)
      .then((response) => {
        setUserData(response.data);
        setPageCount(response.count);
        setAllData(response);
        setLoading(true);
        setRefreshList(false);
      })
      .catch((error) => {
        console.error("Error fetching Care Team data:", error);
        setLoading(true);
      });
  };

  const handleSort = (column, sortDirection) => {
    console.log('sortDirection', sortDirection)
    switch (column.name) {
      case "Provider Name":
        setSortDirection(sortDirection == "desc" ? '-first_name' : "first_name");
        // setSortedColumn();
        break;
      case "Role":
        setSortDirection(sortDirection == "desc" ? '-role' : "role");
        // setSortedColumn("role");
        break;
      case "NPI Number":
        setSortDirection(sortDirection == "desc" ? '-npi' : "first_name");
        // setSortedColumn("npi");
        break;
      case "Speciality":
        setSortDirection(sortDirection == "desc" ? '-speciality' : "speciality");
        // setSortedColumn("speciality");
        break;
      case "Email ID":
        setSortDirection(sortDirection == "desc" ? '-email' : "email");
        // setSortedColumn("email");
        break;
      case "Contact":
        setSortDirection(sortDirection == "desc" ? '-phone_number' : "phone_number");
        // setSortedColumn("phone_number");
        break;
      default:
        setSortDirection("-id");
    }
  };

  const handleUnassign = async () => {
    PatientApi.unassignCareTeam(id, unassignId)
      .then((response) => {
        notifySuccess(response?.message);
        setOpenCnf(false);
        getCareTeamData();
      })
      .catch((error) => {
        console.error("Error fetching unassign Care Team:", error);
      });
  };

  const MoreButton = ({ row }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
      <div>
        <IconButton onClick={handleClick}>
          <MoreIcon />
        </IconButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                setOpenCnf(true);
                setUnassignCof(true);
                setUnassignId(row?.uuid);
              }}
            >
              Unassign
            </MenuItem>
          </Menu>
        </Popover>
      </div>
    );
  };

  useEffect(() => {
    if (refreshList) {
      getCareTeamData();
    }
  }, [refreshList]);

  useEffect(() => {
    const delay = setTimeout(() => {
      getCareTeamData();
    }, 1000);

    return () => {
      clearTimeout(delay);
    };
  }, [searchText, sortDirection, sortedColumn, currentPage]);

  return (
    <Grid>
      <Grid item xs={12} sx={{ height: "75vh", position: "relative" }}>
        {/* <Card sx={{ height: "75vh", position: "relative" }}> */}
        {/* <CardContent> */}
        <Grid
          container
          xs={12}
          padding={1}
          sx={{ flex: 1, alignItems: "center", marginBottom: "10px" }}
        >
          <CustomScrollbars height="58vh">
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {loading ? (
                <DataTable
                  columns={CareTeamColumns}
                  data={userData}
                  sortIcon={<IconArrowsSort size={18} marginLeft={1} />}
                  onSort={handleSort}
                  // onRowClicked={handleRowClick}
                  highlightOnHover
                  pointerOnHover
                  customStyles={customDataTableStyles}
                />
              ) : (
                <CircularProgress
                  color="primary"
                  sx={{ marginTop: "15%" }}
                />
              )}
            </Grid>
          </CustomScrollbars>
          <DrawerComponent
            open={openDrawer}
            handleClose={() => { setOpenDrawer(false) }}
            data={<CareTeamForm setOpenDrawer={setOpenDrawer} setRefreshList={setRefreshList} />}
          />
          <SetDefaultBox
            handleClose={() => {
              setOpenCnf(false);
            }}
            open={openCnf}
            unassignCof={unassignCof}
            setUnassignCof={setUnassignCof}
            handleSubmit={() => handleUnassign()}
          />
        </Grid>
        {/* </CardContent> */}
        <CardActions sx={{ position: "absolute", bottom: 0, width: "100%" }}>
          {pageCount !== 0 && loading && (
            <Grid item xs={12} marginTop={2}>
              <CustomPagination
                count={pageCount}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                data={allData}
              />
            </Grid>
          )}
        </CardActions>
        {/* </Card> */}
      </Grid>
    </Grid>
  );
};

export default CareTeamData;
