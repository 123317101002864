import React, { Fragment, useEffect, useState } from "react";
import DrawerComponent from "../../../../../../../../../ui-component/Drawer";
import CustomPagination from "../../../../../../../../../ui-component/Pagination";
import { Button, CardActions, CircularProgress, Grid } from "@mui/material";
import { IconArrowsSort } from "@tabler/icons-react";
import DataTable from "react-data-table-component";
import { CustomScrollbars } from "../../../../../../../../../ui-component/ScrollBar";
import { useParams } from "react-router";
import { PatientApi } from "../../../../../../../../../service/ProviderGroup/Patient";
import { customDataTableStyles } from "../../../../../../../../../ccm-constant";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import UpdateCarePlanProgress from "./UpdateCarePlanProgress";
import { useProviderContext } from "../../../index";
import CircularProgressBarCell from "../../../../../../../../../ui-component/CircularProgressBarCell";
import { formatDate } from "../../../../../../../../../ccm-constant";

// const CircularProgressBarCell = ({ value }) => (
//   <div style={{ width: "55px", margin: "auto", padding: "7px" }}>
//     <CircularProgressbar
//       value={value}
//       text={`${value}%`}
//       styles={{
//         root: {},
//         path: {
//           stroke:
//             value < 33.33
//               ? "#FF0000CC"
//               : value >= 33.34 && value <= 66.66
//                 ? "#FF9925"
//                 : "#5FD557",
//         },
//         text: {
//           fill:
//             value < 33.33
//               ? "#FF0000CC"
//               : value >= 33.34 && value <= 66.66
//                 ? "#FF9925"
//                 : "#5FD557",
//           fontWeight: 500,
//           fontSize: "18px !important",
//         },
//       }}
//     />
//   </div>
// );

const MyDataTable = ({ dataRefresh, setDataRefresh }) => {
  const {
    searchText,
  } = useProviderContext()
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("-id");
  const [loading, setLoading] = useState(true);
  const [allCarePlan, setAllCarePlan] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [openUpdateForm, setOpenUpdateForm] = useState(false);
  // const [searchText, setsearchText] = useState("");
  const [SelectedRow, setSelectedRow] = useState("");
  const { id } = useParams();
  const [expandedRows, setExpandedRows] = useState([]);
  const [refreshList, setRefreshList] = useState(false);
  const ProviderRole = JSON.parse(localStorage.getItem("user"))?.user?.data?.role;
  const userRole = JSON.parse(localStorage.getItem("user") || "{}");
  const userType = userRole.tokens?.userRole;

  const handleExpandToggle = (row) => {
    setExpandedRows((prevExpandedRows) => {
      const isRowExpanded = prevExpandedRows.includes(row?.uuid);

      if (isRowExpanded) {
        // If the row is already expanded, collapse it
        return [];
      } else {
        // If the row is not expanded, expand it
        return [row?.uuid];
      }
    });
  };

  const fetchAllPatients = async () => {
    setLoading(true);
    const query = {
      search: searchText,
      limit: 10,
      offset:
        currentPage === 0
          ? currentPage
          : searchText
            ? 0
            : (currentPage - 1) * 10,
      order_by: sortDirection,
      sort_by: sortedColumn,
      // search_on: "first_name,last_name,name,created_at,status,progress",
    };

    try {
      const response = await PatientApi.getCarePlans(id, query);
      console.log("getCarePlans response", response.data);
      setAllCarePlan(response.data);
      setPageCount(response.count);
      setDataRefresh(false);
      setLoading(false);
      setRefreshList(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching patients data:", error);
    }
  };

  useEffect(() => {
    console.log("first dataRefresh", dataRefresh)
    if (refreshList || dataRefresh) {
      fetchAllPatients();
    }
  }, [refreshList, dataRefresh]);

  useEffect(() => {
    const delay = setTimeout(() => {
      fetchAllPatients();
    }, 1000);

    return () => {
      clearTimeout(delay);
    };
  }, [searchText, sortDirection, sortedColumn, currentPage]);

  const ExpandedComponent = (props) => {
    const expandedData = props?.data?.data?.note; // Assuming additionalData is available in your row object
    console.log("ExpandedComponent data", props?.data?.data?.note);

    return (
      <div>
        {expandedData ? (
          <DataTable
            columns={CarePlanSubColumns}
            data={expandedData}
            customStyles={{
              ...customDataTableStyles,
              headRow: {
                style: {
                  minHeight: 0,
                },
              },
              rows: {
                style: {
                  borderBottom: "1px solid #ddd",
                },
              },
            }}
            highlightOnHover
            pointerOnHover
            noHeader
          />
        ) : (
          <p>No additional data available</p>
        )}
      </div>
    );
  };

  const CarePlanColumns = [
    {
      name: "No",
      selector: (row, id) => id + 1 + (currentPage ? (currentPage - 1) * 10 : ""),
      width: "60px",
    },
    {
      // selector: (row) => row?.name,
      selector: (row) => (
        <span onClick={() => handleExpandToggle(row)}
          style={{
            whiteSpace: 'pre-line',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            // WebkitLineClamp: 3,
          }}
        >
          {`${row?.name}`}
        </span >
      ),
      width: "350px",
      name: "Goal",
      sortable: true,
    },
    {
      selector: (row) =>
        `${row?.provider?.first_name} ${row?.provider?.last_name}`,
      name: "Provider Name",
      sortable: true,
      width: "180px",
      wrap:true,
    },
    {
      selector: (row) =>
        `${row?.careplan_name ? row?.careplan_name : ""}`,
      name: "Care Plan",
      sortable: true,
      width: "230px",
      wrap:true,
    },
    {
      selector: (row) =>
        // if (!expandedRows.includes(row?.created_at)) {
        // return `${new Date(row?.created_at).getDate()} - ${new Date(row?.created_at).getMonth() + 1
        //   } - ${new Date(row?.created_at).getFullYear()}`
        formatDate(row?.created_at)
      // }
      //   else {
      //   return null;
      // }
      ,
      name: "Date",
      width: "150px",
      sortable: true,
    },
    {
      selector: (row) => {
        if (!expandedRows.includes(row?.uuid)) {
          return row?.status === "progress"
            ? "Progress"
            : row?.status === "regress"
              ? "Regress"
              : "Not Progress";
        } else {
          return null;
        }
      },
      name: "Status",
      width: "180px",
      sortable: true,
      wrap:true,
    },
    {
      name: "Goal Progress",
      width: "180px",
      sortable: true,
      cell: (row) => {
        if (!expandedRows.includes(row?.uuid)) {
          return <span > <CircularProgressBarCell value={row.progress || 0} /></ span>;
        } else {
          return null;
        }
      },
    },
  ];
  if (ProviderRole !== "Non Clinical" && userType !== "provider_group")
    CarePlanColumns.push({
      name: "",
      width: "150px",
      sortable: true,
      cell: (row) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            variant="outlined"
            style={{
              textTransform: "none",
              width: "auto",
              margin: expandedRows.includes(row?.uuid) ? "15px 0px" : null,
            }}
            onClick={() => {
              setOpenUpdateForm(true);
              setSelectedRow(row);
            }}
          >
            Update
          </Button>
        </div>
      ),
    },);

  const CarePlanSubColumns = [
    {
      width: "60px",
    },
    {
      // width: "15%",
      selector: (row) => `Note - ${row?.note}`,
      wrap: true,
      width: "350px",
    },
    {
      width: "180px",
      selector: (row) =>
        `${row?.provider?.first_name} ${row?.provider?.last_name}`,
    },
    {
      width: "230px",
      wrap:true,
      selector: (row) =>
        `${row?.careplan_name ? row?.careplan_name : ""}`,
    },
    {
      selector: (row) =>
        formatDate(row?.created_at),
        width: "150px",
        wrap:true,
    },
    {
      selector: (row) => {
        return row?.status === "progress"
          ? "Progress"
          : row?.status === "regress"
            ? "Regress"
            : "Not Progress";
      },
      width: "180px",
    },
    {
      width: "150px",
      cell: (row) => {
        if (!expandedRows.includes(row?.uuid)) {
          return <span > <CircularProgressBarCell value={row.progress || 0} /></ span>;
        } else {
          return null;
        }
      },
    },
    // {
    //   name: "",
    //   width: "13%",
    // },
  ];



  if (ProviderRole !== "Non Clinical" && userType !== "provider_group")
    CarePlanSubColumns.push({
      name: "",
      width: "150px",
    },);

  const handleClose = () => {
    setOpenUpdateForm(false);
  };

  const handleSort = (column, sortDirection) => {
    console.log("column", column);
    setSortDirection(sortDirection);
    switch (column.name) {
      case "Goal":
        setSortDirection(sortDirection === "desc" ? '-name' : "name");
        // setSortedColumn();
        break;
      case "Provider Name":
        setSortDirection(sortDirection === "desc" ? '-first_name' : "first_name");
        break;
      case "Care Plan":
        setSortDirection(sortDirection === "desc" ? '-careplan_name' : "careplan_name");
        break;
      case "Date":
        setSortDirection(sortDirection === "desc" ? '-created_at' : "created_at");
        break;
      case "Status":
        setSortDirection(sortDirection === "desc" ? '-status' : "status");
        break;
      case "Goal Progress":
        setSortDirection(sortDirection === "desc" ? '-progress' : "progress");
        break;
      default:
        return null;
    }
  };

  return (
    <>
      <Grid item xs={12} sx={{ height: "90vh", position: "relative" }}>
        <Grid
          container
          // xs={12}
          padding={1}
          sx={{ flex: 1, alignItems: "center", marginBottom: 2 }}
        >
          <CustomScrollbars height="82vh">
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              marginBottom={2}
            >
              {!loading ? (
                <DataTable
                  columns={CarePlanColumns}
                  data={allCarePlan}
                  onRowClicked={(row) => handleExpandToggle(row)}
                  sortIcon={<IconArrowsSort size={18} marginLeft={1} />}
                  sortServer
                  onSort={handleSort}
                  sortColumn={sortedColumn}
                  sortDirection={sortDirection}
                  customStyles={{
                    ...customDataTableStyles,
                    cells: {
                      style: {
                        textAlign: "left",
                        fontStyle: "normal",
                        fontVariant: "medium",
                        fontSize: "16px",
                        lineHeight: "26px",
                        color: "#4C4C4C",
                        // font: "normal normal medium 18px/21px Gilroy" ,
                        opacity: 1,
                      },
                    },
                  }}
                  highlightOnHover
                  pointerOnHover
                  expandableRows
                  expandableRowsHideExpander
                  expandableRowsComponent={(row) => (
                    <ExpandedComponent data={row} />
                  )}
                  expandableRowExpanded={(row) =>
                    expandedRows.includes(row?.uuid)
                  }
                ></DataTable>
              ) : (
                <CircularProgress color="primary" sx={{ marginTop: "15%" }} />
              )}
            </Grid>
          </CustomScrollbars>
        </Grid>
        <Grid item marginTop={2}>
          <CardActions
            sx={{ position: "absolute", bottom: 0, width: "100%" }}
            borderBottom={0}
          >
            {pageCount !== 0 && !loading && (
              <Grid item xs={12} marginTop={2}>
                <CustomPagination
                  count={pageCount}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  data={allCarePlan}
                />
              </Grid>
            )}
          </CardActions>
        </Grid>
      </Grid>
      <DrawerComponent
        open={openUpdateForm}
        handleClose={handleClose}
        data={
          <UpdateCarePlanProgress
            handleClose={handleClose}
            SelectedRow={SelectedRow}
            setRefreshList={setRefreshList}
          />
        }
      />
    </>
  );
};

function CarePlanProgress({ dataRefresh, setDataRefresh }) {
  return (
    <>
      <MyDataTable dataRefresh={dataRefresh} setDataRefresh={setDataRefresh} />
    </>
  );
}

export default CarePlanProgress;
