import { Field, useFormikContext } from "formik";
import React from "react";
import CustomPhoneInput from "./CustomPhoneInput";
import { TextField } from "@mui/material";
import { Typography } from "@mui/material";

const PhoneNumber = ({ data }) => {
  const { values, setFieldValue } = useFormikContext();
  const {
    heading,
    label,
    fieldName,
    fieldType,
    options,
    placeholder,
    fieldValue,
    disable,
    country,
    phoneValue,
    defaultData,
  } = data;

  return (<>
  <Typography
          variant="subtitle1"
          sx={{
            marginBottom: "10px",
          }}
        >
          {label}
        </Typography>
    <Field
      name={fieldName}
      variant="outlined"
      fullWidth
      // validate={validatePhoneNumber}
    >
      {({ field }) => (
        <CustomPhoneInput
          {...field}
          name={fieldName}
          as={TextField}
          variant="outlined"
          country={"us"} // Use the selectedCountry from your form values
          enableSearch={true}
          value={values[fieldName]}
          color="primary"
          placeholder={placeholder}
          onChange={(phone) => setFieldValue(fieldName, phone)}
        />
      )}
    </Field>
  </>
  );
};

export default PhoneNumber;
