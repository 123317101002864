import React, { createContext, useContext, useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {
  Box,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
  CardContent,
} from "@mui/material";
import NavTabs from "./Components/NavTabs";
import Profile from "./Components/Profile";
import ProviderProfile from "./Components/ProviderUserProfile/Profile";
import SearchIcon from "@mui/icons-material/Search";
import ProviderUser from "./Components/ProviderUser";
import AuditLog from "./Components/AuditLog";
import { EhrSettings } from "./Components/EhrSettings";
import EhrAudit from "./Components/EhrAudit";
import EhrError from "./Components/EhrError";
import DrawerComponent from "../../../ui-component/Drawer";
import ChangeMyPassword from "./Components/ChangePassword";
import AddProvider from "./Components/AdminUser/feature/add-provider-form";
import { useSelector } from "react-redux";
import AuditEvent from "./Components/AuditEvent";
import Breadcrumb from "../../../ui-component/Breadcrumb";
import { AuthApis } from "../../../service/auth";
import { useNotification } from "../../../hooks/use-notification";
import httpClient from "../../../service/base";
import AdminSettings from "./Components/AdminSettings";
import cardStyle from "../../../theme/card-layout";
import { SettingsApi } from "../../../service/Settings/Index";
import DateRangePicker from "../../../ui-component/DatePicker/DatePicker";
import { useFormikContext } from "formik";
import { CustomScrollbars } from "../../../ui-component/ScrollBar";
import ProviderList from "./Components/ProviderUserProfile/ProviderList";
import ProviderProfileDetails from "./Components/ProviderUserProfile/Profile";
import GroupProfile from "./Components/ProviderUserProfile/GroupProfile";
import GroupProfileUpdate from "./Components/ProviderUserProfile/GroupProfileUpdate";
import NotificationSettings from "./Components/NotificationSettings";

const SettingsContext = createContext();

export const useSettings = () => {
  return useContext(SettingsContext);
};

function Settings() {
  const formRef = React.createRef();
  const store = useSelector((state) => state);
  const [value, setValue] = useState(store.navTabIndex?.tabIndex || "View Profile");
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openAddProvider, setAddProvider] = useState(false);
  const { breadcrumb } = useSelector((state) => state);
  const [isUserAdd, setIsUserAdd] = useState(false);
  const [isUserupdate, setIsUserUpdate] = useState(false);
  const { notifySuccess, notifyError } = useNotification();
  const [searchText, setSearchText] = useState("");
  const [onStartDate, setStartDate] = useState(null);
  const [onEndDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saveClick, setSave] = useState(null);
  const [SaveButton, setSaveButton] = useState(false);
  const userRole = JSON.parse(localStorage.getItem("user") || "{}");
  const showProfilePage = userRole.tokens?.userRole;
  const [providerRole, setProviderRole] = useState(userRole?.user?.data?.role)
  const [confirm, setConfirm] = useState(false);
  const [defaultClicked, setDefaultClicked] = useState(false);
  let userType = JSON.parse(localStorage.getItem("user"))?.tokens?.userRole;


  useEffect(() => {
    if (!["View Profile", "Notification Setting", "Provider Users", "Admin Users", "Audit Log", "Audit Event", "EHR Setting", "EHR Sync", "EHR Audit", "EHR Error"].includes(value)) {
      setValue("View Profile")
    }
  }, [])

  useEffect(() => {

    console.log("setProviderRole", value)
    if (providerRole !== JSON.parse(localStorage.getItem("user"))?.user?.data?.role)
      setProviderRole(JSON.parse(localStorage.getItem("user"))?.user?.data?.role)
  }, [JSON.parse(localStorage.getItem("user")), SaveButton, saveClick])


  const handleDateRangeChange = (dateRange) => {
    let start = new Date(dateRange[0]);
    let end = new Date(dateRange[1]);
    console.log("start, end", start, end);
    if (end !== "Invalid Date") {
      setStartDate(
        `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`
      );
      setEndDate(`${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`);
    }
  };

  const handleSaveClick = () => {
    console.log("formRef 6666");
    setSave(true);
    if (!showProfilePage?.includes("provider")) {
      setLoading(true);
      if (formRef.current) {
        let values = formRef.current.values;
        console.log("formRef 6666");
        console.log("values @", values);
        let payload = {
          first_name: values.first_name,
          last_name: values.last_name,
          phone_number: values.phone_number,
          address: {
            address_line_1: values.address_line_1,
            address_line_2: values.address_line_2,
            country: values.country,
            city: values?.city,
            state: values.state,
            zip_code: values.zip_code,
          },
        };
        AuthApis.updateMe(payload)
          .then((response) => {
            if (typeof values?.profile_pic != "string" && values?.profile_pic) {
              SettingsApi.AddSuperAdminProfileImage({
                profile_pic: values?.profile_pic,
              })
                .then((response) => {
                  setTimeout(() => {
                    formRef?.current?.resetForm();
                    setEdit(false);
                    setIsUserUpdate(true);
                    notifySuccess(userType == "admin" ? "Admin Updated Successfully! " :  "Super Admin Updated Successfully! ");
                    // notifySuccess("Super Admin Updated Successfully!");
                    setLoading(false);
                  }, 1000);
                  // notifySuccess(response.message);
                })
                .catch((error) => {
                  if (typeof error?.response?.data?.error === "string")
                    notifyError(error?.response?.data?.error);
                  setIsUserUpdate(true);
                  setLoading(false);
                });
            } else {
              formRef?.current?.resetForm();
              setEdit(false);
              setIsUserUpdate(true);
              notifySuccess(userType == "admin" ? "Admin Updated Successfully! " :  "Super Admin Updated Successfully! ");
              // notifySuccess("Super Admin Updated Successfully!");
              setLoading(false);
            }
          })
          .catch((error) => {
            notifyError(error?.response?.data?.error);
            console.error("Error", error);
            setLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    // handleSaveClick()
    console.log("formRef 6666", saveClick);
  }, [saveClick]);

  console.log("first edit", edit);

  const tabComponents = {
    "View Profile":
      showProfilePage === "provider_group" && edit ? (
        <GroupProfileUpdate
          saveClick={saveClick}
          setSave={setSave}
          setEdit={setEdit}
          setLoading={setLoading}
        />
      ) : showProfilePage === "provider_group" && !edit ? (
        <GroupProfile formRef={formRef} />
      ) : showProfilePage === "provider" ? (
        <ProviderProfileDetails
          saveClick={saveClick}
          edit={edit}
          setEdit={setEdit}
          setSave={setSave}
          setLoading={setLoading}
        />
      ) : (
        <Profile
          isUserupdate={isUserupdate}
          setIsUserUpdate={setIsUserUpdate}
          formRef={formRef}
        />
      ),
    "Notification Setting": showProfilePage?.includes("provider") ? (
      <NotificationSettings
        saveClick={saveClick}
        setSave={setSave}
        setLoading={setLoading}
        SaveButton={SaveButton}
        setSaveButton={setSaveButton}
        setConfirm={setConfirm}
        confirm={confirm}
        defaultClicked={defaultClicked}
        setDefaultClicked={setDefaultClicked}
      />
    ) : (
      <AdminSettings
        saveClick={saveClick}
        setSave={setSave}
        loading={loading}
        SaveButton={SaveButton}
        setLoading={setLoading}
        setSaveButton={setSaveButton}
        setConfirm={setConfirm}
        confirm={confirm}
        defaultClicked={defaultClicked}
        setDefaultClicked={setDefaultClicked}
      />
    ),
    "Provider Users":
      <ProviderList />,

    "Admin Users": <ProviderUser />,
    // 2: showProfilePage?.includes("provider") ? (
    //   <ProviderList />
    // ) : (
    //   <ProviderUser />
    // ),
    "Audit Log": <AuditLog onStartDate={onStartDate} onEndDate={onEndDate} />,
    "Audit Event": <AuditLog onStartDate={onStartDate} onEndDate={onEndDate} auditEvent={true} />,
    "EHR Setting": <EhrSettings />,
    "EHR Sync": <></>,
    "EHR Audit": <EhrAudit />,
    "EHR Error": <EhrError />,
  };

  const a11TabsProps = [
    {
      label: "View Profile",
    },
    providerRole !== "Non Clinical" ? {
      label: "Notification Setting",
    } : null,
    {
      label: showProfilePage?.includes("provider")
        ? "Provider Users"
        : "Admin Users",
    },
    showProfilePage !== "provider"
      ? {
        label: "Audit Log",
      }
      : null,
    showProfilePage !== "provider"
      ? {
        label: "Audit Event",
      }
      : null,
    showProfilePage?.includes("provider")
      ? {
        label: "EHR Setting",
      }
      : null,
    showProfilePage?.includes("provider")
      ? {
        label: "EHR Sync",
      }
      : null,
    showProfilePage?.includes("provider")
      ? {
        label: "EHR Audit",
      }
      : null,
    showProfilePage?.includes("provider")
      ? {
        label: "EHR Error",
      }
      : null,
  ].filter((item) => item !== null);

  console.log("formRef.current", formRef);

  useEffect(() => {
    if (formRef?.current) {
      if (formRef.current?.values === formRef.current?.initialValues)
        console.log("formRef.current 1", formRef.current);
    }
  }, [formRef]);

  const handleSubmit = () => {
    setSave(true);
    // setConfirm(true)
  };

  const headerButtonRender = {
    "View Profile": (
      <>
        {!edit && (
          <>
            <Button
              variant="outlined"
              color="primary"
              style={{
                marginRight: "15px",
                textTransform: "none",
                width: "200px",
              }}
              onClick={() => {
                setOpen(true);
              }}
            >
              Change Password
            </Button>
            <Button
              variant="contained"
              color="primary"
              className=""
              style={{
                marginRight: "8px",
                textTransform: "none",
                width: "200px",
              }}
              onClick={() => setEdit(!edit)}
            >
              {`Edit ${showProfilePage && showProfilePage?.includes("provider")
                ? ""
                : "Admin"
                } Profile`}
            </Button>
          </>
        )}
        {edit && (
          <>
            <Button
              variant="outlined"
              color="primary"
              style={{
                marginRight: "15px",
                textTransform: "none",
                width: "163px",
              }}
              onClick={() => {
                setEdit(!edit);
                setSave(false);
                formRef?.current?.resetForm();
                // alert("Reset data ");
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{
                marginRight: "8px",
                textTransform: "none",
                width: "163px",
              }}
              onClick={handleSaveClick}
            >
              Save
              {loading && (
                <CircularProgress size={18} style={{ color: "white" }} />
              )}
            </Button>
          </>
        )}
      </>
    ),
    "Notification Setting": (
      <>
        {" "}
        {SaveButton ? (
          <>
            <Button
              variant="outlined"
              color="primary"
              style={{
                marginRight: "15px",
                textTransform: "none",
                width: "163px",
              }}
              onClick={() => { setSaveButton(false); setConfirm(false); setSave(false) }}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              color="primary"
              style={{
                marginRight: "8px",
                textTransform: "none",
                width: "163px",
              }}
              onClick={handleSubmit}
            >
              Save
              {loading && (
                <CircularProgress size={18} style={{ color: "white" }} />
              )}
            </Button>
          </>
        ) :
          <Button
            variant="contained"
            color="primary"
            style={{
              marginRight: "8px",
              textTransform: "none",
              width: "163px",
            }}
            onClick={() => {
              setSave(true);
              setDefaultClicked(true)
            }}
          >
            Set Default
            {/* {loading && (
                <CircularProgress size={18} style={{ color: "white" }} />
              )} */}
          </Button>
        }
      </>
    ),
    "Provider Users":
      <>
        <>
          <TextField
            variant="outlined"
            placeholder="Type here to search"
            size="small"
            sx={{ marginRight: 2 }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          {httpClient.userRole === "super_admin" ||
            httpClient.userRole === "provider_group" ? (
            <Button
              variant="contained"
              color="primary"
              style={{
                marginRight: "8px",
                textTransform: "none",
                width: "263px",
              }}
              onClick={() =>
                httpClient.userRole === "super_admin"
                  ? setAddProvider(true)
                  : setOpenDrawer(true)
              }
            >
              {httpClient.userRole === "super_admin"
                ? "Add Admin User"
                : "Add Provider User"}
            </Button>
          ) : null}
        </>
      </>,

    "Admin Users": (
      <>
        <>
          <TextField
            variant="outlined"
            placeholder="Type here to search"
            size="small"
            sx={{ marginRight: 2 }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          {httpClient.userRole === "super_admin" ||
            httpClient.userRole === "provider_group" ? (
            <Button
              variant="contained"
              color="primary"
              style={{
                marginRight: "8px",
                textTransform: "none",
                width: "263px",
              }}
              onClick={() =>
                httpClient.userRole === "super_admin"
                  ? setAddProvider(true)
                  : setOpenDrawer(true)
              }
            >
              {httpClient.userRole === "super_admin"
                ? "Add Admin User"
                : "Add Provider User"}
            </Button>
          ) : null}
        </>
      </>
    ),
    "Audit Log": <DateRangePicker onDateRangeChange={handleDateRangeChange} />,
    "Audit Event": <DateRangePicker onDateRangeChange={handleDateRangeChange} />,
  };

  const handleClose = () => {
    setOpen(false);
    setIsUserAdd(true);
  };

  const handleCloseAddProvider = () => {
    setAddProvider(false);
  };

  useEffect(() => {
    setEdit(false);
  }, [value]);

  return (
    <SettingsContext.Provider
      value={{
        value,
        setValue,
        a11TabsProps,
        edit,
        isUserAdd,
        setIsUserAdd,
        searchText,
        openDrawer,
        setOpenDrawer,
      }}
    >
      <Grid container spacing={3} padding={3}>
        <Grid item xs={12}>
          <Card sx={{ ...cardStyle, height: "88vh" }}>
            <CustomScrollbars height="88vh">
              <CardContent>
                <Box sx={{ width: "100%" }}>
                  <Grid
                    container
                    // xs={12}
                    // paddingBottom={2}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid item xs={6}>
                      <Breadcrumb
                        tabLabels={a11TabsProps}
                        value={value}
                        title="Settings"
                      />
                      {/* <Typography variant="h6">Settings</Typography> */}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {headerButtonRender[value]}
                    </Grid>
                  </Grid>

                  {/* <Box sx={{ marginBottom: 1 }} paddingLeft={1}> */}
                  <NavTabs
                    a11TabsProps={a11TabsProps}
                    value={value}
                    setValue={setValue}
                  />
                  {/* </Box> */}
                </Box>
              </CardContent>
              {tabComponents[value]}

              <DrawerComponent
                open={open}
                handleClose={handleClose}
                data={<ChangeMyPassword handleClose={handleClose} />}
              />
              <DrawerComponent
                open={openAddProvider}
                handleClose={handleCloseAddProvider}
                data={<AddProvider handleClose={handleCloseAddProvider} />}
              />
            </CustomScrollbars>
          </Card>
        </Grid>
      </Grid>
    </SettingsContext.Provider>
  );
}

export default Settings;
