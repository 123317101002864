import React, { Fragment, useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import MoreIcon from "@mui/icons-material/MoreVert";
import Switch from "@mui/material/Switch";
import { CustomScrollbars } from "../../../../ui-component/ScrollBar";
import {
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  Typography,
  CardActions,
  Card,
  CardContent,
} from "@mui/material";
import { useNavigate } from "react-router";
import { customDataTableStyles } from "../../../../ccm-constant";
import { IconArrowsSort } from "@tabler/icons-react";
import CustomPagination from "../../../../ui-component/Pagination";
import avatar from "../../../../assets/adminAvatar.jpg";
import { SettingsApi } from "../../../../service/Settings/Index";
import { useSettings } from "..";
import { Box } from "@mui/material";
import ConfirmBox from "../../../../ui-component/ConfirmBox";
import CircularLoader from "../../../../ui-component/CircularLoader";
import { useNotification } from "../../../../hooks/use-notification";
import { AuthApis } from "../../../../service/auth";
import httpClient from "../../../../service/base";
// import { formatDate } from "../../../../helpers/formatDate";
import { formatDate } from "../../../../ccm-constant";

const MyDataTable = () => {
  const userRole = httpClient.userRole;
  const { isUserAdd, setIsUserAdd, searchText } = useSettings();
  const navigate = useNavigate();
  const formRef = React.createRef();
  const [currentPage, setCurrentPage] = useState(0);

  const columns = [
    {
      name: "Name",
      selector: (row) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "3px",
            cursor: "pointer",
            // wordBreak:"keep-all"
          }}
          onClick={() => handleCellClick(row)}
        >
          <img
            src={row?.profile_pic ? `${row?.profile_pic}?${new Date().getTime()}` : avatar}
            alt="Image"
            width={40}
            height={40}
            style={{
              marginRight: 10,
              borderRadius: "50%",
              padding: "1px",
              border: "1px solid #dfdfdf",
            }}
            onError={(e) => {
              e.target.src = avatar;
              e.target.onerror = null; // Prevent an infinite loop in case the error image also fails to load
            }}
          />
          {row?.first_name + " " + row?.last_name}
        </div>
      ),
      sortable: true,
      // width: "250px",
      // cell:
    },
    {
      name: "Email ID",
      selector: (row)=> row?.email,
      cell: (row) => 
          <div
            style={{
              color: "#002c6b",
            }}
            onClick={() => handleCellClick(row)}
          >
            {row?.email}
          </div>
      ,
      sortable: true,
      // width: userRole === "super_admin" ? "200px" : "280px",
      // cell: (row) => (
      //   <div
      //     style={{
      //       color: "#002c6b",
      //       wordBreak: "break-word",
      //       cursor: "pointer",
      //     }}
      //     onClick={() => handleCellClick(row)}
      //   >
      //     {row.email}
      //   </div>
      // ),
    },

    {
      name: "Phone Number",
      selector: (row)=> row?.phone_number,
      sortable: true,
      // width: "%",
    },
    // {
    //   name: "Address",
    //   selector: (row) =>
    //     `${row?.address?.address_line_1} ${row?.address?.address_line_2}`,
    //   // sortable: true,
    //   width:"20%",
    //   // width: userRole === "super_admin" ? "200px" : "300px",
    //   cell: (row) => (
    //     <div
    //       onClick={() => handleCellClick(row)}
    //       style={{  cursor: "pointer" }}
    //     >
    //       {`${row?.address?.address_line_1}, ${row?.address?.address_line_2} ${
    //         row?.address?.address_line_2 ? "," : ""
    //       } ${row?.address?.state}${row?.address?.state ? "," : ""}${row?.address?.country}${
    //         row?.address?.country ? "," : ""
    //       } ${row?.address?.zip_code}`}
    //     </div>
    //   ),
    // },
    // {
    //   name: "State ",
    //   selector: (row) => `${row?.address.state}`,
    //   // sortable: true,
    //   width: "10%",
    // },
    // {
    //   name: "Country ",
    //   selector: (row) => `${row?.address.country}`,
    //   // sortable: true,
    //   width: "10%",
    // },
    // {
    //   name: "Zip Code ",
    //   selector: (row) => `${row?.address.zip_code}`,
    //   // sortable: true,
    //   width: "8%",
    // },
    {
      name: "Last Login ",
      selector: (row) => formatDate(row?.last_login, true),
      // sortable: true,
      // width: "25%",
      cell: (row) => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={() => handleCellClick(row)}
        >
          {formatDate(row.last_login, true)}
        </div>
      ),
    },
  ];

  if (userRole === "super_admin") {
    // Add Status column for super admin
    columns.push({
      name: "Status",
      cell: (row) => <StatusSwitch row={row} />,
      width: "10%",
    });

    // Add Action column for super admin
    columns.push({
      name: "Action",
      width: "10%",
      cell: (row) => <MoreButton row={row} />,
    });
  }

  const [loading, setLoading] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("desc");
  const [IsDelete, setIsDelete] = useState(false);
  const { notifySuccess, notifyError } = useNotification();
  const [rowData, setRowData] = useState();
  useEffect(() => {
    setLoading(true);
    const delay = setTimeout(() => {
      getUserData();
    }, 500);
    return () => {
      clearTimeout(delay);
    };
  }, [currentPage, sortDirection, sortedColumn, searchText]);

  useEffect(() => {
    if (isUserAdd) {
      setLoading(true);
      getUserData();
      setIsUserAdd(false);
    }
  }, [isUserAdd]);

  const handleCellClick = (row) => {
    // Handle the click event for the cell
    navigate(`/settings/Admin/profile/:Id`, {
      state: {
        edit: false,
        data: JSON.stringify(row),
        formRef: formRef,
      },
    });
  };

  const handleSort = (column, sortDirection) => {
    console.log("column", column, sortDirection);
    if (column?.name === "Name") {
      setSortedColumn("first_name");
    } else if (column?.name === "Email ID") {
      setSortedColumn("email");
    } else {
      setSortedColumn(column.selector);
    }
    setSortDirection(sortDirection);
  };

  const MoreButton = ({ row }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const handleEdit = () => {
      navigate("/settings/Admin/profile/:id", {
        state: { edit: true, data: JSON.stringify(row), formRef: formRef },
      });
    };

    return (
      <div>
        <IconButton onClick={handleClick}>
          <MoreIcon />
        </IconButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleEdit}>Edit</MenuItem>
            <MenuItem
              onClick={() => {
                setIsDelete(true);
                setRowData(row);
              }}
            >
              Delete
            </MenuItem>
          </Menu>
        </Popover>
      </div>
    );
  };

  const StatusSwitch = ({ row }) => {
    const [checked, setChecked] = useState(row?.status);

    const handleChange = () => {
      setChecked(!checked);
      SettingsApi.updateAdminUser(row?.uuid, { status: !checked })
        .then((response) => {
          getUserData();
          notifySuccess("Status updated successfully!");
        })
        .catch((err) => {
          console.log("error", err);
        });
    };

    return (
      <div>
        <Switch
          checked={checked}
          onChange={handleChange}
          color="primary"
          inputProps={{ "aria-label": "status switch" }}
        />
      </div>
    );
  };

  const getUserData = () => {
    setLoading(true);
    SettingsApi.getUser(
      "admin",
      // currentPage === 0 || searchText ? currentPage : (currentPage - 1) * 10,
      currentPage === 0 ? currentPage : searchText ? 0 : (currentPage - 1) * 10,
      sortDirection,
      sortedColumn,
      searchText
    )
      .then((response) => {
        setUsersData(response);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleDeleteSubmit = () => {
    console.log("delete data", rowData?.uuid);
    SettingsApi.deleteUser(rowData?.uuid)
      .then((response) => {
        console.log("response", response);
        notifySuccess(response.message || "Record Deleted Successfully!");
        // setCurrentPage(0);
        setRowData();
        getUserData();
        setIsDelete(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        notifyError(error?.response?.data?.error);
        setIsDelete(false);
      });
  };

  return (
    <>
      {IsDelete && (
        <ConfirmBox
          handleClose={() => setIsDelete(false)}
          open={true}
          handleSubmit={() => handleDeleteSubmit()}
        />
      )}
      <Grid item xs={12}>
        <Card sx={{ height: "75vh", position: "relative" }}>
          <CardContent>
            <Grid
              container
              xs={12}
              padding={1}
              sx={{ flex: 1, alignItems: "center", marginBottom: "10px" }}
            >
              <CustomScrollbars height="60vh">
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {!loading ? (
                    <DataTable
                      columns={columns}
                      data={usersData?.data}
                      onRowClicked={(row) =>
                        navigate(`/settings/Admin/profile/:Id`, {
                          state: {
                            edit: false,
                            data: JSON.stringify(row),
                            formRef: formRef,
                          },
                        })
                      }
                      highlightOnHover
                      pointerOnHover
                      customStyles={customDataTableStyles}
                      sortIcon={<IconArrowsSort size={18} marginLeft={1} />}
                      sortServer
                      onSort={handleSort}
                    />
                  ) : (
                    <CircularProgress
                      color="primary"
                      sx={{ marginTop: "15%" }}
                    />
                  )}
                </Grid>
              </CustomScrollbars>
            </Grid>
          </CardContent>
          <CardActions sx={{ position: "absolute", bottom: 0, width: "100%" }}>
            {usersData?.count >= 1 && (
              <Grid
                item
                xs={12}
              // display="flex"
              // alignItems="center"
              // justifyContent="space-between"
              >
                <CustomPagination
                  count={usersData?.count}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  data={usersData}
                />
              </Grid>
            )}
          </CardActions>
        </Card>
      </Grid>
    </>
  );
};

const ProviderUser = React.memo(() => {
  return <MyDataTable />;
});

export default ProviderUser;
