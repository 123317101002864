import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import MoreIcon from "@mui/icons-material/MoreVert";
import { IconArrowsSort } from "@tabler/icons-react";
import {
  Grid,
  IconButton,
  Popover,
  Menu,
  MenuItem,
  CircularProgress,
  CardActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import cardStyle from "../../../../../../../../../../theme/card-layout";
import DrawerComponent from "../../../../../../../../../../ui-component/Drawer";
import { customDataTableStyles, formatDate } from "../../../../../../../../../../ccm-constant";
import { PatientApi } from "../../../../../../../../../../service/ProviderGroup/Patient";
import { useNotification } from "../../../../../../../../../../hooks/use-notification";
import CustomPagination from "../../../../../../../../../../ui-component/Pagination";
import { CustomScrollbars } from "../../../../../../../../../../ui-component/ScrollBar";
import { useProviderContext } from "../../../..";
import ConfirmBox from "../../../../../../../../../../ui-component/ConfirmBox";
import MedicationForm from "./Components/MedicationForm";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SetDefaultBox from "../../../../../../../../../../ui-component/SetDefaultBox";
// import ConditionsForm from "./Components/ConditionsForm";

const Medication = ({ setOpenDrawer, openDrawer }) => {
  const { searchText } = useProviderContext();
  const { id } = useParams();
  let userType = JSON.parse(localStorage.getItem("user"))?.tokens?.userRole;
  let ProviderId =
    userType === "provider_group"
      ? JSON.parse(localStorage.getItem("user"))?.user?.data?.uuid
      : JSON.parse(localStorage.getItem("user"))?.user?.data?.provider_group
        ?.uuid;
  const ProviderRole = JSON.parse(localStorage.getItem("user"))?.user?.data
    ?.role;
  const { notifySuccess, notifyError } = useNotification();
  const [sortedColumn, setSortedColumn] = useState("");
  const [ActivesortDirection, setActiveSortDirection] = useState("-id");
  const [PastsortDirection, setPastSortDirection] = useState("-id");
  const [userData, setUserData] = useState([]);
  const [userPastData, setPastUserData] = useState([]);


  console.log("userData", userData);
  const [loading, setLoading] = useState(false);
  const [Pastloading, setPastLoading] = useState(false);
  const [ActivecurrentPage, setActiveCurrentPage] = useState(0);
  const [PastcurrentPage, setPastCurrentPage] = useState(0);
  const [ActivepageCount, setActivePageCount] = useState(0);
  const [allActiveData, setAllActiveData] = useState();
  const [pagePastCount, setPastPageCount] = useState(0);
  const [allPastData, setAllPastData] = useState();
  const [refreshList, setRefreshList] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [IsDelete, setIsDelete] = useState(false);
  const [isTable1Open, setTable1Open] = useState(true);
  const [isTable2Open, setTable2Open] = useState(true);
  const [stop, setStop] = useState(false)

  const toggleTable1 = () => {
    setTable1Open(!isTable1Open);
    // setTable2Open(false);
  };

  const toggleTable2 = () => {
    setTable2Open(!isTable2Open);
    // setTable1Open(false);
  };

  const CurrentMedicationsColumns = [
    {
      name: "No",
      selector: (row, id) => id + 1 + (ActivecurrentPage ? (ActivecurrentPage - 1) * 10 : ""),
      // sortable: true,
      width: "10%",
    },
    {
      name: "Medicines",
      selector: (row) => row?.name,
      sortable: true,
      wrap: true,
      // minWidth: "13%",
    },
    {
      name: "Directions",
      selector: (row) =>
        <div
          // style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
          style={{
            whiteSpace: 'pre-line',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            // overflow: 'hidden',
            // WebkitLineClamp: 3,
          }}
        >
          {row.directions}
        </div>,
      sortable: true,
      wrap: true,
      // minWidth: "13%",
      minWidth: "15%",
    },
    {
      name: "Start Date",
      selector: (row) => formatDate(row?.start_date),
      sortable: true,
      // minWidth: "13%",
    },
    {
      name: "Duration",
      selector: (row) =>
        `${row?.for_lifetime ? "For Lifetime" : row?.for_days}  ${!row?.for_lifetime ? "days" : ""
        }`,
      sortable: true,
      // minWidth: "13%",
    },
    {
      name: "Note",
      selector: (row) => 
        <div
          // style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
          style={{
            whiteSpace: 'pre-line',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            // overflow: 'hidden',
            // WebkitLineClamp: 3,
          }}
        >
          {row.note}
        </div>,
      sortable: true,
      // minWidth: "18%",
    },
    {
      name: "Est.End Date",
      selector: (row) => row?.stop ? "" : formatDate(row?.stop_date),
      sortable: true,
      width: "160px",
    },
    {
      name: "Remaining Duration",
      selector: (row) =>
        `${row?.stop ? "Stopped" : row?.remaining_days ? row?.remaining_days : ""} ${row?.stop ? "" : !row?.remaining_days ? "" : "days"
        }`,
      // sortable: true,
      width: "220px",
    },
    {
      name: "",
      selector: "",
      width: "80px",
      cell: (row) => {
        if (
          userType === "provider" &&
          (ProviderRole === "Care Team" || ProviderRole === "Physician")
        ) {
          return <MoreButton row={row} />;
        } else {
          return null;
        }
      },
    },
  ];

  const PastMedicationsColumns = [
    {
      name: "No",
      selector: (row, id) => id + 1 + (PastcurrentPage ? (PastcurrentPage - 1) * 10 : ""),
      // sortable: true,
      width: "10%",
    },
    {
      name: "Medicines",
      selector: (row) => row?.name,
      sortable: true,
      wrap: true,
      // minWidth: "20%",
    },
    {
      name: "Directions",
      selector: (row) =>
        <div
          // style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
          style={{
            whiteSpace: 'pre-line',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            // overflow: 'hidden',
            // WebkitLineClamp: 3,
          }}
        >
          {row.directions}
        </div>,
      sortable: true,
      // minWidth: "30%",
    },
    {
      name: "Start Date",
      selector: (row) => formatDate(row?.start_date),
      sortable: true,
      // minWidth: "16%",
    },
    {
      name: "Duration",
      selector: (row) => `${row?.for_days ? row?.for_days : ""} ${!row?.for_days ? "" : "days"}`,
      sortable: true,
      // minWidth: "16%",
    },
    {
      name: "Note",
      selector: (row) => 
        <div
          // style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
          style={{
            whiteSpace: 'pre-line',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            // overflow: 'hidden',
            // WebkitLineClamp: 3,
          }}
        >
          {row.note}
        </div>
      ,
      sortable: true,
      // minWidth: "20%",
    },
    {
      name: "Completed Date",
      selector: (row) => formatDate(row?.stop_date),
      sortable: true,
      minWidth: "150px",
    },
  ];

  const getAllMedications = (type) => {
    type === "active" ?
      setLoading(false) :
      setPastLoading(false)
    console.log("I am calling", ActivecurrentPage);
    const query = type === "active" ? {
      search: searchText,
      limit: 10,
      offset:
        ActivecurrentPage === 0
          ? ActivecurrentPage
          : searchText
            ? 0
            : (ActivecurrentPage - 1) * 10,
      order_by: ActivesortDirection,
    } :
      {
        search: searchText,
        limit: 10,
        offset:
          PastcurrentPage === 0
            ? PastcurrentPage
            : searchText
              ? 0
              : (PastcurrentPage - 1) * 10,
        order_by: PastsortDirection,
      }

    if (searchText) {
      setTable1Open(true);
      setTable2Open(true);
    }

    PatientApi.getAllMedications(id, type, query)
      .then((response) => {
        if (type === "active") {
          setUserData(response.data);
          setActivePageCount(response.count);
          setLoading(true);
          // setPastLoading(false);
          setAllActiveData(response);
        }
        else {
          setPastUserData(response.data);
          setPastPageCount(response.count);
          setPastLoading(true);
          // setLoading(false);
          setAllPastData(response);
        }
        setRefreshList(false);
        console.log("response.data", response.data);
      })
      .catch((error) => {
        setLoading(false)
        setPastLoading(false)
        notifyError(error?.response?.data?.error)
        console.error("Error fetching Care Team data:", error);
        // type === "active" ? 

      });
  };

  const handleSort = (column, sortDirection) => {
    console.log("sortDirection", sortDirection);
    switch (column.name) {
      case "Medicines":
        setActiveSortDirection(sortDirection === "desc" ? "-name" : "name");
        break;
      case "Directions":
        setActiveSortDirection(
          sortDirection === "desc" ? "-directions" : "directions"
        );
        break;
      case "Note":
        setActiveSortDirection(sortDirection === "desc" ? "-note" : "note");
        break;
      case "Start Date":
        setActiveSortDirection(
          sortDirection === "desc" ? "-start_date" : "start_date"
        );
        break;
      case "Duration":
        setActiveSortDirection(sortDirection === "desc" ? "-for_days" : "for_days");
        break;
      case "Est.End Date":
        setActiveSortDirection(sortDirection === "desc" ? "-stop_date" : "stop_date");
        break;
      case "Remaining Duration":
        setActiveSortDirection(
          sortDirection === "desc" ? "-for_lifetime" : "for_lifetime"
        );
        break;
      default:
        setActiveSortDirection("-id");
    }
  };


  const handlePastSort = (column, sortDirection) => {
    console.log("sortDirection", sortDirection);
    switch (column.name) {
      case "Medicines":
        setPastSortDirection(sortDirection === "desc" ? "-name" : "name");
        break;
      case "Directions":
        setPastSortDirection(
          sortDirection === "desc" ? "-directions" : "directions"
        );
        break;
      case "Note":
        setPastSortDirection(sortDirection === "desc" ? "-note" : "note");
        break;
      case "Start Date":
        setPastSortDirection(
          sortDirection === "desc" ? "-start_date" : "start_date"
        );
        break;
      case "Duration":
        setPastSortDirection(sortDirection === "desc" ? "-for_days" : "for_days");
        break;
      case "Completed Date":
        setPastSortDirection(sortDirection === "desc" ? "-stop_date" : "stop_date");
        break;
      default:
        setPastSortDirection("-id");
    }
  };

  const MoreButton = ({ row }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
      setSelectedRow(null)
    };

    const open = Boolean(anchorEl);

    return (
      <div>
        <IconButton onClick={handleClick}>
          <MoreIcon />
        </IconButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {!row?.stop && <><MenuItem
              onClick={() => {
                setSelectedRow(row);
                setStop(true)
                // setIsDelete(true);
              }}
            >
              Stop
            </MenuItem></>}
            <MenuItem
              onClick={() => {
                setSelectedRow(row);
                setOpenDrawer(true);
              }}
            >
              Edit
            </MenuItem>

          </Menu>
        </Popover>
      </div>
    );
  };

  useEffect(() => {
    if (refreshList) {
      getAllMedications("active");
      setTimeout(() => {
        getAllMedications("past");
      }, 1000);
      setSelectedRow(null);
    }
  }, [refreshList]);

  useEffect(() => {
    const delay = setTimeout(
      () => {
        getAllMedications("active");
      },
      searchText ? 1000 : 100
    );

    return () => {
      clearTimeout(delay);
    };
  }, [searchText, ActivesortDirection, , ActivecurrentPage]);

  useEffect(() => {
    const delay = setTimeout(
      () => {
        getAllMedications("past");
      },
      searchText ? 1000 : 100
    );

    return () => {
      clearTimeout(delay);
    };
  }, [searchText, PastsortDirection, PastcurrentPage]);

  const handleDeleteSubmit = () => {
    PatientApi.deleteMedictions(selectedRow?.uuid)
      .then((response) => {
        setRefreshList(true);
        setSelectedRow(null);
        setIsDelete(false);
        notifySuccess("Conditions deleted successfully");
        console.log("response.data", response.data);
      })
      .catch((error) => {
        console.error("Error addAllergies data:", error);
        notifyError(error?.response?.data?.error);
      });
  };

  return (
    <Grid>
      {IsDelete && (
        <ConfirmBox
          handleClose={() => setIsDelete(false)}
          open={true}
          handleSubmit={() => handleDeleteSubmit()}
        />
      )}

      {stop && (
        <SetDefaultBox
          handleClose={() => { setStop(false); setSelectedRow("") }}
          open={stop}
          handleSubmit={() => {
            PatientApi.updateMedications(selectedRow?.uuid, {
              "stop": true
            })
              .then((response) => {
                setOpenDrawer(false);
                setRefreshList(true);
                setStop(false);
                notifySuccess("Medication stopped successfully");
                console.log("response.data", response.data);
              })
              .catch((error) => {
                console.error("Error addAllergies data:", error);
                notifyError(error?.response?.data?.error);
              })
          }}
          title="Stop Medication"
          FormResetConfirm={false}
        />
      )}



      <Grid item xs={12} sx={{ maxHeight: "96vh", position: "relative", width: "100%" }}>
        <Grid container xs={12} spacing={0} padding={0}>
          <CustomScrollbars height="88vh">

            <Grid item xs={12} sx={{ maxHeight: "82vh", position: "relative" }} marginBottom={0}>
              <CustomScrollbars height="71vh">
                <Accordion
                  expanded={isTable1Open}
                  onChange={toggleTable1}
                  style={{ padding: 5, borderBottom: 0, }}
                >
                  <AccordionSummary
                    style={{
                      ...cardStyle,
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid xs={12}>
                      <Typography variant="h6">Current Medications</Typography>
                    </Grid>

                    <Grid style={{ float: "right" }}>
                      {!isTable1Open ? (
                        <KeyboardArrowDownIcon
                          style={{
                            height: "25px",
                            fontWeight: 600,
                            paddingTop: 5,
                          }}
                        />
                      ) : (
                        <KeyboardArrowUpIcon
                          style={{ fontSize: "25px", fontWeight: 600 }}
                        />
                      )}
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {loading ? (
                        <DataTable
                          columns={CurrentMedicationsColumns}
                          data={userData}
                          sortIcon={<IconArrowsSort size={18} marginLeft={1} />}
                          onSort={handleSort}
                          highlightOnHover
                          pointerOnHover
                          customStyles={customDataTableStyles}
                        />
                      ) : (
                        <CircularProgress
                          color="primary"
                          sx={{ marginTop: "15%" }}
                        />
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </CustomScrollbars>
              <CardActions
                sx={{ position: "relative", bottom: 0, width: "99%" }}
              >
                {ActivepageCount !== 0 && loading && isTable1Open && (
                  <Grid item xs={12} marginTop={1}>
                    <CustomPagination
                      count={ActivepageCount}
                      currentPage={ActivecurrentPage}
                      setCurrentPage={setActiveCurrentPage}
                      data={allActiveData}
                    />
                  </Grid>
                )}
              </CardActions>
            </Grid>
            <Grid item xs={12} style={{ maxHeight: "80vh", width: "100%" }}>
              <CustomScrollbars height="70vh">
                <Accordion
                  expanded={isTable2Open}
                  onChange={toggleTable2}
                  style={{ padding: 5, borderBottom: 0 }}
                >
                  <AccordionSummary
                    style={{
                      ...cardStyle,
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid xs={12}>
                      <Typography variant="h6">Past Medications</Typography>
                    </Grid>

                    <Grid style={{ float: "right" }}>
                      {!isTable2Open ? (
                        <KeyboardArrowDownIcon
                          style={{
                            height: "25px",
                            fontWeight: 600,
                            paddingTop: 5,
                          }}
                        />
                      ) : (
                        <KeyboardArrowUpIcon
                          style={{ fontSize: "25px", fontWeight: 600 }}
                        />
                      )}
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {Pastloading ? (
                        <DataTable
                          columns={PastMedicationsColumns}
                          data={userPastData}
                          sortIcon={<IconArrowsSort size={18} marginLeft={1} />}
                          onSort={handlePastSort}
                          highlightOnHover
                          pointerOnHover
                          customStyles={customDataTableStyles}
                        />
                      ) : (
                        <CircularProgress
                          color="primary"
                          sx={{ marginTop: "15%" }}
                        />
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </CustomScrollbars>
              <CardActions
                sx={{ position: "relative", bottom: 0, width: "99%" }}
              >
                {pagePastCount !== 0 && Pastloading && isTable2Open && (
                  <Grid item xs={12} marginTop={1}>
                    <CustomPagination
                      count={pagePastCount}
                      currentPage={PastcurrentPage}
                      setCurrentPage={setPastCurrentPage}
                      data={allPastData}
                    />
                  </Grid>
                )}
              </CardActions>
            </Grid>


          </CustomScrollbars>
        </Grid>
        <DrawerComponent
          open={openDrawer}
          handleClose={() => {
            setOpenDrawer(false);
            setSelectedRow(null)
          }}
          data={
            <MedicationForm
              setOpenDrawer={setOpenDrawer}
              setRefreshList={setRefreshList}
              selectedData={selectedRow}
              setSelectedRow={setSelectedRow}
            />
          }
        />
      </Grid>
    </Grid>
  );
};

export default Medication;
