import React from 'react';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import {
  TextField
} from "@mui/material";

const CustomPhoneInput = ({ name, value, onChange, country, enableSearch, placeholder, variant }) => {
  const handlePhoneChange = (value, country, e, formattedValue) => {
   
    // const countryCode = countryData.dialCode;
    // const phoneNumber = phone;
    // const phoneNumberWithoutCountryCode = phone.replace(`${countryCode}`, '');

    // console.log("phonephone",phoneNumberWithoutCountryCode)
    console.log("phonephone country",formattedValue)
    // Implement any custom logic you want here
    onChange(formattedValue );
  };

  return (
    <PhoneInput
      name={name}
      as={TextField}
      variant={variant || "outlined"}
      country={country}
      enableSearch={enableSearch}
      value={value}
      placeholder={placeholder || "Enter Phone Number"}
      onChange={handlePhoneChange}
      inputStyle={{width:"inherit"}}
    />
  );
};

export default CustomPhoneInput;
