import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import MoreIcon from "@mui/icons-material/MoreVert";
import { IconArrowsSort } from "@tabler/icons-react";
import {
  Grid,
  IconButton,
  Popover,
  Switch,
  Typography,
  Menu,
  MenuItem,
  CircularProgress,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import DrawerComponent from "../../../../../../../../ui-component/Drawer";
import { customDataTableStyles, formatDateAndTime } from "../../../../../../../../ccm-constant";
import { PatientApi } from "../../../../../../../../service/ProviderGroup/Patient";
import { useNotification } from "../../../../../../../../hooks/use-notification";
import ConfirmBox from "../../../../../../../../ui-component/ConfirmBox";
import CustomPagination from "../../../../../../../../ui-component/Pagination";
import { CustomScrollbars } from "../../../../../../../../ui-component/ScrollBar";
import TimeLogForm from "./Features/Index";
import { useProviderContext } from "../..";
import { formatDate } from "../../../../../../../../ccm-constant";
import { useDispatch, useSelector } from "react-redux";
import { setRefresh } from "../../../../../../../../store/slices/TImeLogSlice";

const TimeLogData = ({ setOpenDrawer, openDrawer, setRefreshPatientData }) => {
  const {
    searchText,
  } = useProviderContext();
  const navigate = useNavigate();
  const { timeLog } = useSelector((state) => state);
  const { id } = useParams();
  const dispatch = useDispatch();
  let userType = JSON.parse(localStorage.getItem("user"))?.tokens?.userRole;
  let ProviderId = userType === "provider_group" ? JSON.parse(localStorage.getItem("user"))?.user?.data?.uuid :
    JSON.parse(localStorage.getItem("user"))?.user?.data?.provider_group?.uuid;
  const { notifySuccess, notifyError } = useNotification();
  const [sortedColumn, setSortedColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("-id");
  const [userData, setUserData] = useState([]);
  console.log("userData", userData);
  const [openCnf, setOpenCnf] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [allData, setAllData] = useState();
  const [refreshList, setRefreshList] = useState(false);
  const [deleteTimeLogId, setDeletTimeLogId] = useState(false);
  const ProviderRole = JSON.parse(localStorage.getItem("user"))?.user?.data?.role;

  const TimeLogColumns = [
    // {
    //   name: "No",
    //   selector: (row, id) => id + 1 + (currentPage ? (currentPage - 1) * 10 : ""),
    //   width: "7%",
    // },
    {
      name: "Log ID",
      selector: (row) => row?.timelog_id,
      // width: "12%",
      sortable: true,
    },
    {
      name: "Log Name",
      selector: (row) => row?.timelog_as,
      sortable: true,
      // width: "20%",
    },
    {
      name: "Log By",
      selector: (row) => row?.timelog_by,
      sortable: true,
      // width: "15%",
    },
    {
      name: "Logged Date",
      selector: (row) => row?.start_time_log ? formatDate(row?.start_time_log) : '',
      sortable: true,
      // width: "14%",
    },
    
    {
      name: "Start Time",
      // selector: (row) => formatDateAndTime(row?.start_time_log)?.split(" ")[1],
      cell: (row) => {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const formattedTime = row?.start_time_log
          ? new Date(row?.start_time_log).toLocaleTimeString('en-IN', { timeZone: userTimeZone })
          : '';
        return <span>{formattedTime}</span>;
      },
      // sortable: true,
      // width: "12%",
    },
    {
      name: "End Time",
      // selector: (row) => formatDateAndTime(row?.end_time_log)?.split(" ")[1],
      cell: (row) => {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const formattedTime = row?.end_time_log
          ? new Date(row?.end_time_log).toLocaleTimeString('en-IN', { timeZone: userTimeZone })
          : '';
        return <span>{formattedTime}</span>;
      },
      // sortable: true,
      // width: "12%",
    },
    // {
    //   name: "Date",
    //   selector: (row) => row?.start_time_log ? formatDate(row?.start_time_log) : '',
    //   sortable: true,
    //   width: "10%",
    // },
    {
      name: "Total Time",
      selector: (row) => row?.log_time_in_minutes,
      // sortable: true,
      // width: "12%",
    },
    {
      selector: "",
      name: "",
      // width: "5px",
      cell: (row) => {
        if (userType === "provider" && (ProviderRole === "Care Team" || ProviderRole === "Physician")) {
          return <MoreButton row={row} />;
        } else {
          return null;
        }
      },
    },
  ];

  const getTimeLogData = () => {
    setLoading(false);
    console.log("I am calling");
    const query = {
      search: searchText,
      limit: 10,
      offset:
        currentPage === 0
          ? currentPage
          : searchText
            ? 0
            : (currentPage - 1) * 10,
      order_by: sortDirection,
      // sort_by: sortedColumn,
    };
    PatientApi.getAllTimeLogs(id, query)
      .then((response) => {
        setUserData(response.data);
        setPageCount(response.count);
        setAllData(response);
        setLoading(true);
        setRefreshList(false);
      })
      .catch((error) => {
        console.error("Error fetching Time Log data:", error);
        setLoading(true);
      });
  };

  const handleSort = (column, sortDirection) => {
    // setSortDirection(sortDirection);
    switch (column.name) {
      case "Log ID":
        setSortDirection(sortDirection == "desc" ? '-id' : "id");
        // setSortedColumn("id");
        break;
      case "Log Name":
        setSortDirection(sortDirection == "desc" ? '-timelog_as' : "timelog_as");
        // setSortedColumn("note");
        break;
      case "Logged Date":
        setSortDirection(sortDirection == "desc" ? '-start_time_log' : "start_time_log");
        // setSortedColumn("created_at");
        break;
      case "Log By":
        setSortDirection(sortDirection == "desc" ? '-timelog_by' : "timelog_by");
        // setSortedColumn("timelog_by");
        break;
      case "Log Time In Min":
        setSortDirection(sortDirection == "desc" ? '-log_time_in_minutes' : "log_time_in_minutes");
        // setSortedColumn("log_time_in_minutes");
        break;
      case "Date":
        setSortDirection(sortDirection == "desc" ? '-created_at' : "created_at");
        // setSortedColumn("created_at");
        break;
      case "Total Time":
        setSortDirection(sortDirection == "desc" ? '-created_at' : "created_at");
        // setSortedColumn("created_at");
        break;
      default:
        setSortDirection("-id");
    }
  };

  // useEffect(() => {
  //   if (timeLog?.refresh) {
  //     getTimeLogData();
  //     // dispatch(setRefresh(false));
  //   }
  // }, [timeLog?.refresh]);

  useEffect(() => {
    if (refreshList) {
      setRefreshPatientData(true)
      getTimeLogData();
    }
  }, [refreshList]);

  useEffect(() => {
    const delay = setTimeout(() => {
      getTimeLogData();
    }, 1000);

    return () => {
      clearTimeout(delay);
    };
  }, [searchText, sortDirection, sortedColumn, currentPage]);

  const MoreButton = ({ row }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
      <div>
        <IconButton onClick={handleClick}>
          <MoreIcon />
        </IconButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                setOpenCnf(true);
                setDeletTimeLogId(row?.uuid);
              }}
            >
              Delete
            </MenuItem>
          </Menu>
        </Popover>
      </div>
    );
  };

  const handleDeleteSubmit = async () => {
    PatientApi.deleteTimeLog(deleteTimeLogId)
      .then((response) => {
        console.log("response", response);
        notifySuccess(response?.message);
        setOpenCnf(false);
        getTimeLogData();
      })
      .catch((error) => {
        console.error("Error fetching delete time log data:", error.response?.data?.error);
        notifyError(error?.response?.data?.error);
        setOpenCnf(false);
      });
  };

  return (
    <Grid>
      <Grid item xs={12} sx={{ height: "75vh", position: "relative" }}>
        {/* <Card sx={{ height: "75vh", position: "relative" }}> */}
        {/* <CardContent> */}
        <Grid
          container
          xs={12}
          padding={1}
          sx={{ flex: 1, alignItems: "center", marginBottom: "10px" }}
        >
          <CustomScrollbars height="58vh">
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {loading ? (
                <DataTable
                  columns={TimeLogColumns}
                  data={userData}
                  sortIcon={<IconArrowsSort size={18} marginLeft={1} />}
                  onSort={handleSort}
                  // onRowClicked={handleRowClick}
                  highlightOnHover
                  pointerOnHover
                  customStyles={customDataTableStyles}
                />
              ) : (
                <CircularProgress
                  color="primary"
                  sx={{ marginTop: "15%" }}
                />
              )}
            </Grid>
          </CustomScrollbars>
          <DrawerComponent
            open={openDrawer}
            handleClose={() => { setOpenDrawer(false) }}
            data={<TimeLogForm setOpenDrawer={setOpenDrawer} setRefreshList={setRefreshList} />}
          />
          <ConfirmBox
            handleClose={() => setOpenCnf(false)}
            open={openCnf}
            handleSubmit={() => handleDeleteSubmit()}
          />
        </Grid>
        {/* </CardContent> */}
        <CardActions sx={{ position: "absolute", bottom: 0, width: "100%" }}>
          {pageCount !== 0 && loading && (
            <Grid item xs={12} marginTop={2}>
              <CustomPagination
                count={pageCount}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                data={allData}
              />
            </Grid>
          )}
        </CardActions>
        {/* </Card> */}
      </Grid>
    </Grid>
  );
};

export default TimeLogData;
