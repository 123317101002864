import { Box, Grid, Switch, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SettingsApi } from "../../../../service/Settings/Index";
import { useNotification } from "../../../../hooks/use-notification";
import CircularLoader from "../../../../ui-component/CircularLoader";
import SetDefaultBox from "../../../../ui-component/SetDefaultBox";

function AdminSettings({saveClick, setSave, loading, setLoading, SaveButton, setSaveButton,confirm, setConfirm, defaultClicked,setDefaultClicked}) {
  const { notifySuccess, notifyError } = useNotification();
  const [loadingData, setLoadingData] = useState(false);
  // const [confirm, setConfirm] = useState(false);
  const [settings, setSettings] = useState({
    chat_notification: false,
    file_downloaded: false,
    file_uploaded: false,
    file_deleted: false,
  });

  useEffect(() => { 
    getChatNotification();
    setSaveButton(false)
  }, []);

  useEffect(() => {
  if(saveClick){
    if (!confirm && defaultClicked) {
      setConfirm(true);
    }
    else{
      updateNotification(settings);
    }
  }
  else if(SaveButton){
    setSettings(settings)
  }
  else{
    getChatNotification()
  }
  }, [saveClick, SaveButton])
  
  



  const getChatNotification = () => {
    setLoadingData(true);
    setLoading(true)
    SettingsApi.getNotification()
      .then((response) => {
        console.log(
          "getNotification",
          response?.data?.admin_notification?.chat_notification
        );
        setSettings({
          chat_notification:
            response?.data?.admin_notification?.chat_notification,
          file_downloaded: response?.data?.admin_notification?.file_downloaded,
          file_uploaded: response?.data?.admin_notification?.file_uploaded,
          file_deleted: response?.data?.admin_notification?.file_deleted,
        });
        setLoadingData(false);
        setLoading(false)
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
      setSave(false)
  };

  const handleSettingChange = (key) => {
    setSaveButton(true)
    const updatedSettings = { ...settings, [key]: !settings[key] };
    setSettings(updatedSettings);
  };

  const updateNotification = (updatedSettings) => {
    SettingsApi.updateNotification({
      admin_notification: updatedSettings,
      default: SaveButton ? false : true,
    })
      .then((response) => {
        // getChatNotification();
        notifySuccess(response?.message);
        setSaveButton(false)
        setSave(false)
        setConfirm(false)
        setDefaultClicked(false)
      })
      .catch((error) => {
        notifyError(error?.response?.data?.error)
        console.error("Error updating settings:", error);
        notifyError("Something went wrong!");
      });
      
  };

  return (
    <div style={{ position: "relative" }}>
            {confirm ? (
        <SetDefaultBox
          handleClose={() => {setConfirm(false); setDefaultClicked(false);}}
          open={confirm}
          handleSubmit={() => updateNotification(settings)}
        />
      ) : null}
      <Grid
        container
        spacing={1}
        padding={5}
        style={{
          minHeight: "72.5vh",
        }}
      >
        {loadingData ? (
          <CircularLoader />
        ) : (
          <>
            <Grid item lg={10} md={12} spacing={5}>
              <Grid container spacing={2} columnSpacing={8}>
                <Grid item md={6} sm={12} spacing={2}>
                  <Box>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <Typography variant="subtitle1" marginBottom={1}>
                          Chat Notification
                        </Typography>
                      </Grid>
                      <Grid item paddingTop={2}>
                        <Switch
                          checked={settings.chat_notification}
                          onChange={() =>
                            handleSettingChange("chat_notification")
                          }
                          color="primary"
                          inputProps={{ "aria-label": "status switch" }}
                        />
                      </Grid>
                    </Grid>
                    <Typography variant="body1" marginBottom={2}>
                      Notify me when anyone sends Messages
                    </Typography>
                  </Box>

                  <Box>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <Typography variant="subtitle1" marginBottom={1}>
                          File Downloaded Notification
                        </Typography>
                      </Grid>
                      <Grid item paddingTop={2}>
                        <Switch
                          checked={settings.file_downloaded}
                          onChange={() =>
                            handleSettingChange("file_downloaded")
                          }
                          color="primary"
                          inputProps={{ "aria-label": "status switch" }}
                        />
                      </Grid>
                    </Grid>
                    <Typography variant="body1" marginBottom={2}>
                      Notify me when a file is downloaded
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={6} sm={12} spacing={1}>
                  <Box>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <Typography variant="subtitle1" marginBottom={1}>
                          File Uploaded Notification
                        </Typography>
                      </Grid>
                      <Grid item paddingTop={2}>
                        <Switch
                          checked={settings.file_uploaded}
                          onChange={() => handleSettingChange("file_uploaded")}
                          color="primary"
                          inputProps={{ "aria-label": "status switch" }}
                        />
                      </Grid>
                    </Grid>
                    <Typography variant="body1" marginBottom={2}>
                      Notify me when a file is uploaded
                    </Typography>
                  </Box>

                  <Box>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <Typography variant="subtitle1" marginBottom={1}>
                          File Deleted Notification
                        </Typography>
                      </Grid>
                      <Grid item paddingTop={1}>
                        <Switch
                          checked={settings.file_deleted}
                          onChange={() => handleSettingChange("file_deleted")}
                          color="primary"
                          inputProps={{ "aria-label": "status switch" }}
                        />
                      </Grid>
                    </Grid>
                    <Typography variant="body1" marginBottom={2}>
                      Notify me when a file is deleted
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={2} spacing={1}></Grid>
          </>
        )}
      </Grid>
    </div>
  );
}

export default AdminSettings;
