import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from "@mui/material";
import RestoreIcon from "@mui/icons-material/Restore";
import React, { useState } from "react";
import cardStyle from "../../../../../../../../../theme/card-layout";
import SearchIcon from "@mui/icons-material/Search";
import { DatePicker } from "../../../../../../../../../ui-component/DatePicker/DatePicker";
import { List, ListItem, ListItemText } from "@mui/material";
import { CustomScrollbars } from "../../../../../../../../../ui-component/ScrollBar";
import CarePlanUpdateHistory from "./CarePlanUpdateHistory";
import DrawerComponent from "../../../../../../../../../ui-component/Drawer";
import CarePlanReviewForm from "./CarePlanReviewForm";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CarePlanApi } from "../../../../../../../../../service/CarePlan/careAPI";
import { useParams } from "react-router-dom";
import { useNotification } from "../../../../../../../../../hooks/use-notification";
import { isCarePlanAssign } from "../../../../../../../../../store/slices/queAns";
import { formatDate } from "../../../../../../../../../ccm-constant";
import { useProviderContext } from "../../..";

function UnorderedListExample({ listItems }) {
  return (
    <List>
      {listItems.map((item, index) => (
        <ListItem key={index} sx={{ padding: 0 }}>
          <Typography
            variant="h1"
            component="span"
            fontSize={30}
            marginRight={1}
          >
            &#8226;
          </Typography>
          <ListItemText>
            <Typography variant="body1" component="span">
              {item}
            </Typography>
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
}

function CarePlanReview() {
  const {
    searchText,
  } = useProviderContext()
  const { answer } = useSelector((state) => state);
  console.log("carePlanAssign", answer?.carePlanAssign);
  // const isCarePlanAssign = answer?.carePlanAssign
  const dispatch = useDispatch();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [carePlanId, setCarePlanId] = useState();
  const [history, setHistory] = useState();
  console.log("carePlanId", carePlanId);
  const { notifySuccess, notifyError } = useNotification();
  const [expandedPanel, setExpandedPanel] = useState(null);
  const ProviderRole = JSON.parse(localStorage.getItem("user"))?.user?.data?.role;
  const userRole = JSON.parse(localStorage.getItem("user") || "{}");
  const userType = userRole.tokens?.userRole;

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    const isNoteButton =
      event.target.closest(".note-history-button") ||
      event.target.closest(".add-note-button");

    if (!isNoteButton) {
      setExpandedPanel(isExpanded ? panel : null);
    }
  };

  const handleClickOpen = (carePlanId) => {
    console.log("carePlanId", carePlanId);
    setOpen(true);
    setLoading(true)
    CarePlanApi.getHistory(carePlanId)
      .then((response) => {
    setLoading(false)

        setHistory(response?.data);
      })
      .catch((error) => {
        notifyError(error?.response?.data?.error);
      });
  };

  const handleClose = () => {
    setOpen(false);
    setIsUpdate(false);
  };

  const getAllData = () => { 
    setLoading(true)
    console.log("care assign api call");
    CarePlanApi.getPatientCarePlan(id, {search:searchText})
      .then((response) => {
        setData(response?.data);
        setLoading(false)
        console.log("getPatientCarePlan", response?.data);
      })
      .catch((error) => {
        setLoading(false)
        notifyError(error?.response?.data?.error);
      });
  }

  useEffect(() => {
    getAllData();
  }, [answer?.carePlanAssign, searchText]);

  return (
    <>
      <Grid style={!data ? { ...cardStyle } : {}}>
        {data?.map((item, index) => (
          <Accordion
            key={index}
            expanded={expandedPanel === index}
            onChange={handleAccordionChange(index)}
            sx={{...cardStyle}}
          >
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {/* <Typography>Accordion 1</Typography> */}
              <Grid container xs={12}>
                <Grid item xs={12} lg={6} padding={0}>
                  <Typography variant="h6" marginTop={"6px"}>
                    {item?.careplan?.name}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={6}
                  padding={0}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  {/* <DatePicker onSetDate={onSetDate} setOnSetDate={setOnSetDate} /> */}
                  <Typography variant="body1">
                    {formatDate(item?.updated_at)}
                  </Typography>
                  <Button
                    className="note-history-button"
                    variant="text"
                    color="primary"
                    style={{
                      marginLeft: "8px",
                      textTransform: "none",
                      width: "160px",
                    }}
                    onClick={() => handleClickOpen(item?.uuid)}
                  >
                    <Typography
                      variant="body1"
                      sx={{ transform: "rotate(0deg)", paddingRight:"3px", marginTop:"5px" }}
                    >
                      <RestoreIcon />
                    </Typography>
                    <Typography variant="body1">Note History</Typography>
                  </Button>
                  {ProviderRole !== "Non Clinical" && userType !== "provider_group" && <Button
                    className="add-note-button"
                    variant="contained"
                    color="primary"
                    style={{
                      marginLeft: "8px",
                      textTransform: "none",
                      width: "163px",
                    }}
                    onClick={() => {
                      setIsUpdate(true);
                      setCarePlanId(item?.uuid);
                    }}
                  >
                    Add Note
                  </Button>}
                </Grid>
                <Divider orientation="horizontal" flexItem />
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              {/* <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography> */}
              <Grid
                item
                xs={12}
                paddingRight={2}
                paddingLeft={2}
                paddingTop={0}
              >
                <CustomScrollbars height={"43vh"}>
                  <Grid container paddingBottom={0}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1">Goal</Typography>
                      <UnorderedListExample
                        listItems={item?.questions?.goals?.flatMap((goal) => goal?.answer) || []}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1">
                        Problems/ Symptoms
                      </Typography>
                      <UnorderedListExample
                        listItems={item?.questions?.symptom?.flatMap((symptom) => symptom?.answer) || []}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1">Barriers</Typography>
                      <UnorderedListExample
                        listItems={item?.questions?.barrier?.flatMap((barrier) => barrier?.answer) || []}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1">
                        Numbers To Track
                      </Typography>
                      <UnorderedListExample
                        listItems={
                          item?.questions?.numbers_to_track?.flatMap((numbers_to_track) => numbers_to_track?.answer) || []
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1">
                        Expected Outcomes
                      </Typography>
                      <UnorderedListExample
                        listItems={
                          item?.questions?.expected_outcomes?.flatMap((expected_outcomes) => expected_outcomes?.answer) || []
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1">
                        Resources/ Support
                      </Typography>
                      <UnorderedListExample
                        listItems={item?.questions?.support?.flatMap((item) => item?.answer) || []}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1">Allergies</Typography>
                      <UnorderedListExample
                        listItems={item?.questions?.allergies?.flatMap((item) => item?.answer) || []}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1">Interventions</Typography>
                      <UnorderedListExample
                        listItems={
                          item?.questions?.interventions?.flatMap((item) => item?.answer) || []
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1">Medications</Typography>
                      <UnorderedListExample
                        listItems={
                          item?.questions?.medications?.flatMap((item) => item?.answer) || []
                        }
                      />
                    </Grid>
                  </Grid>
                </CustomScrollbars>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
        {!data?.length && !loading && <Typography variant="body2" fontSize={"16px"} margin={3} padding={3} textAlign={"center"}>There are no records to display</Typography> }
        
        {open ? (
          <CarePlanUpdateHistory onClose={handleClose} history={history} loading={loading} />
        ) : null}
        <DrawerComponent
          open={isUpdate}
          handleClose={handleClose}
          data={
            <CarePlanReviewForm
              handleClose={handleClose}
              carePlanId={carePlanId}
            />
          }
        />
      </Grid>
    </>
  );
}

export default CarePlanReview;
