
import config from "../../../config";
import httpClient from "../../base"

const BACKEND_BASE_URL = config.BACKEND_BASE_URL;

export const PatientApi = {
  AddPatient: (payload, ehr) =>
    httpClient.post(`${BACKEND_BASE_URL}patients/api/v1/demographic?ehr=${ehr ? ehr : ""}`, payload, {}, Headers = {
      "Content-Type": "multipart/form-data",
    }),

  getPatientDataById: (id) =>
    httpClient.get(`${BACKEND_BASE_URL}patients/api/v1/demographic/${id}`),

  GetConditionsAndICDCodes: () =>
    httpClient.get(`${BACKEND_BASE_URL}patients/api/v1/icd_code`),

  GetConditions: (id, payload) =>
    httpClient.get(`${BACKEND_BASE_URL}patients/api/v1/${id}/condition`, payload),

  getAllPatients: (id, query) =>
    httpClient.get(`${BACKEND_BASE_URL}patients/api/v1/provider_group/${id}/patients`, { ...query }),

  invitePatient: (payload) =>
    httpClient.post(`${BACKEND_BASE_URL}patients/api/v1/invite`, payload),

  uploadPatientsCSV: (provider_group_id, payload) =>
    httpClient.post(`${BACKEND_BASE_URL}patients/api/v1/provider_group/${provider_group_id}/demographic/bulk`, payload, {}, Headers = {
      "Content-Type": "multipart/form-data",
    }),

  getAllTimeLogs: (id, query) =>
    httpClient.get(`${BACKEND_BASE_URL}patients/api/v1/${id}/timelog`, { ...query }),

  getAllCareTeams: (id, query) =>
    httpClient.get(`${BACKEND_BASE_URL}patients/api/v1/${id}/care-team`, { ...query }),

  getLoggedByList: (id, query) =>
    httpClient.get(`${BACKEND_BASE_URL}patients/api/v1/${id}/care-team-physician`, { ...query }),

  getCareTeamProviders: (id, query) =>
    httpClient.get(`${BACKEND_BASE_URL}patients/api/v1/${id}/care-team/provider-list`, { ...query }),

  getVitalsType: () =>
    httpClient.get(
      `${BACKEND_BASE_URL}patients/api/v1/vitals-metrics`
    ),

  assignCareTeam: (id, payload) =>
    httpClient.post(`${BACKEND_BASE_URL}patients/api/v1/${id}/care-team`, payload),

  unassignCareTeam: (id, provider) =>
    httpClient.delete(`${BACKEND_BASE_URL}patients/api/v1/${id}/care-team/${provider}`),

  createTimeLog: (payload) =>
    httpClient.post(`${BACKEND_BASE_URL}patients/api/v1/timelog`, payload),

  saveConsentForm: (id, payload) =>
    httpClient.put(`${BACKEND_BASE_URL}patients/api/v1/consentform/${id}`, payload, {}, Headers = {
      "Content-Type": "multipart/form-data",
    }),

  // showConsentForm: (id, payload) =>
  //   httpClient.get(`${BACKEND_BASE_URL}patients/api/v1/consent-form/${id}`, payload),

  submitConsentForm: (id) =>
    httpClient.post(`${BACKEND_BASE_URL}patients/api/v1/consent-form/${id}`),

  getCarePlans: (id, query) =>
    httpClient.get(`${BACKEND_BASE_URL}care-plan/api/v1/${id}/goals/list`, { ...query }),

  updtaeCarePlansProgress: (id, pid, payload) =>
    httpClient.put(`${BACKEND_BASE_URL}care-plan/api/v1/${pid}/goal/${id}`, payload),

  getAllPhysicianReviews: (id) =>
    httpClient.get(`${BACKEND_BASE_URL}care-plan/api/v1/physician-review/${id}`),

  AddPhysicianReviews: (id, payload) =>
    httpClient.post(`${BACKEND_BASE_URL}care-plan/api/v1/physician-review/${id}/add`, payload),

  deleteTimeLog: (id) =>
    httpClient.delete(`${BACKEND_BASE_URL}patients/api/v1/timelog/${id}`),

  getAllMedications: (id, type, query) =>
    httpClient.get(`${BACKEND_BASE_URL}patients/api/v1/${id}/medication/${type}`, { ...query }),

  getAllAllergies: (id, query) =>
    httpClient.get(`${BACKEND_BASE_URL}patients/api/v1/${id}/allergies`, { ...query }),

  getAllConditions: (id, query) =>
    httpClient.get(`${BACKEND_BASE_URL}patients/api/v1/${id}/condition`, { ...query }),

  addAllergies: (id, payload) =>
    httpClient.post(`${BACKEND_BASE_URL}patients/api/v1/${id}/allergies`, payload),

  addConditions: (id, payload) =>
    httpClient.post(`${BACKEND_BASE_URL}patients/api/v1/${id}/condition`, payload),

  addMedications: (id, payload) =>
    httpClient.post(`${BACKEND_BASE_URL}patients/api/v1/${id}/medication`, payload),

  updateAllergies: (id, payload) =>
    httpClient.put(`${BACKEND_BASE_URL}patients/api/v1/allergies/${id}`, payload),

  updateConditions: (id, payload) =>
    httpClient.put(`${BACKEND_BASE_URL}patients/api/v1/condition/${id}`, payload),

  updateMedications: (id, payload) =>
    httpClient.put(`${BACKEND_BASE_URL}patients/api/v1/medication/${id}`, payload),

  deleteAllergies: (id) =>
    httpClient.delete(`${BACKEND_BASE_URL}patients/api/v1/allergies/${id}`),

  deleteConditions: (id) =>
    httpClient.delete(`${BACKEND_BASE_URL}patients/api/v1/condition/${id}`),

  deleteMedictions: (id) =>
    httpClient.delete(`${BACKEND_BASE_URL}patients/api/v1/medication/${id}`),

  getAllFamilyMembersData: (id, query) =>
    httpClient.get(`${BACKEND_BASE_URL}patients/api/v1/${id}/family_members`, { ...query }),

  deleteFamilyMember: (id, memberId) =>
    httpClient.delete(`${BACKEND_BASE_URL}patients/api/v1/${id}/family_members/${memberId}`),

  AddFamilyMember: (id, payload) =>
    httpClient.post(`${BACKEND_BASE_URL}patients/api/v1/${id}/family_members`, payload, {}, Headers = {
      "Content-Type": "multipart/form-data",
    }),

  getFamilyMemberDataById: (id, memberId) =>
    httpClient.get(`${BACKEND_BASE_URL}patients/api/v1/${id}/family_members/${memberId}`),

  updateFamilyMember: (id, memberId, payload) =>
    httpClient.put(`${BACKEND_BASE_URL}patients/api/v1/${id}/family_members/${memberId}`, payload, {}, Headers = {
      "Content-Type": "multipart/form-data",
    }),

  uploadPatientDocuments: (id, payload) =>
    httpClient.post(`${BACKEND_BASE_URL}patients/api/v1/${id}/document`, payload, {}, Headers = {
      "Content-Type": "multipart/form-data",
    }),

  getAllPatientDocuments: (id, query) =>
    httpClient.get(`${BACKEND_BASE_URL}patients/api/v1/${id}/document`, { ...query }),

  deletePatientDocument: (id) =>
    httpClient.delete(`${BACKEND_BASE_URL}patients/api/v1/document/${id}`),

  getAllVitals: (id, searchText) =>
    httpClient.get(`${BACKEND_BASE_URL}patients/api/v1/${id}/vitals?search=${searchText}`),

  addVitals: (id, payload) =>
    httpClient.post(`${BACKEND_BASE_URL}patients/api/v1/${id}/vitals`, payload),

  getVitalsReading: (id, type, payload) =>
    httpClient.get(`${BACKEND_BASE_URL}patients/api/v1/${id}/vital-type/${type}/vitals-graph`, { ...payload }),

  getVitalsHistory: (id, type) =>
    httpClient.get(`${BACKEND_BASE_URL}patients/api/v1/${id}/vitals-metrics/${type}/notes`),

  addVitalsNote: (payload) =>
    httpClient.post(`${BACKEND_BASE_URL}patients/api/v1/vitals-metrics/notes`, payload),


  updatePatientDocument: (id, payload) =>
    httpClient.put(`${BACKEND_BASE_URL}patients/api/v1/document/${id}`, payload),

  updatePatientProfile: (id, payload) =>
    httpClient.put(`${BACKEND_BASE_URL}patients/api/v1/demographic/${id}`, payload, {}, Headers = {
      "Content-Type": "multipart/form-data",
    }),

  addPatientByEhrId: (id, ProviderGroupId) =>
    httpClient.get(`${BACKEND_BASE_URL}settings/api/v1/provider_group/${ProviderGroupId}/ehrPID/${id}`),

  setVitalsRange: (id, payload) =>
    httpClient.put(`${BACKEND_BASE_URL}patients/api/v1/vitals/set-range/${id}`, payload),


  verifyMRNNumber:(ProviderId, mrn) =>
  httpClient.get(
      `${BACKEND_BASE_URL}patients/api/v1/verify_mrn/${ProviderId}?mrn=${mrn}`,
      // { ...mrn },
    ),
}