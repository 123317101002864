import { createContext, useEffect, useReducer, useCallback, useState } from 'react';
import httpClient from '../../service/base'
import { AuthApis } from '../../service/auth';
import { loadState } from '../../helpers/storage';
import authReducer from './auth-reducer';
import AuthActions from './auth-actions';
import { openSnackbar } from '../../store/slices/snackbarSlice';
import { openDrawer } from '../../store/slices/drawerSlices';
// import { useNavigate } from "react-router-dom";

const JWTContext = createContext(null);

const initState = loadState('user') ?? {
  tokens: null,
  user: null
};

export const  JWTProvider = ({ children }) => {
  // const navigate = useNavigate();
  const [state, dispatch] = useReducer(authReducer, initState);
  const [initialized, setInitialized] = useState(false);
  const { tokens } = state;

  useEffect(() => {
    httpClient.init(AuthApis, dispatch);
    httpClient.accessToken = tokens?.accessToken;
    httpClient.refreshToken = tokens?.refreshToken;
    httpClient.userRole = tokens?.userRole;
    setInitialized(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = useCallback(async (username, password) => {
    console.log("Inside login context", username, password)
    const tokens = await AuthApis.signin(username, password);
    httpClient.userRole = tokens?.data?.token?.role;
    httpClient.accessToken = tokens?.data?.token?.access;
    httpClient.refreshToken = tokens?.data?.token?.refresh;
    dispatch(openDrawer());
    dispatch(openSnackbar({
      open: true,
      message: "Login successful",
      variant: 'alert',
      alert: { color: 'success' }
    }));
  
    // navigate("/analytics");
    // setTimeout(() => {
      AuthApis.getMe().then((user) => {
        console.log("user in context", user)
        dispatch({
          type: AuthActions.setUser,
          payload: {
            user,
            tokens: { accessToken: tokens?.data?.token?.access, refreshToken: tokens?.data?.token?.refresh ,userRole : tokens?.data?.token?.role, }
          }
        });
        window.location.href ="/analytics"
      });
      
    // }, 1000);
    
    
  }, []);


  // const refreshToken = useCallback(async () => {
  //   httpClient.accessToken = httpClient.refreshToken;
  //   try {
  //     const tokens = await AuthApis.refresh();
  //     if (tokens) {
  //       httpClient.accessToken = tokens?.access_token;
  //       httpClient.refreshToken = tokens?.refresh_token;
  //       dispatch({
  //         type: AuthActions.setUser,
  //         payload: {
  //           tokens: { accessToken: tokens?.access_token, refreshToken: tokens?.refresh_token }
  //         }
  //       });
  //     }
  //   } catch {
  //     dispatch({ type: AuthActions.clearUser });
  //   }
  // }, []);

  // const setTokens = useCallback((tokens) => {
  //   httpClient.accessToken = tokens?.access_token;
  //   httpClient.refreshToken = tokens?.refresh_token;
  //   dispatch({
  //     type: AuthActions.setUser,
  //     payload: {
  //       tokens: { accessToken: tokens?.access_token, refreshToken: tokens?.refresh_token }
  //     }
  //   });
  // }, []);

 

  const logout = useCallback(() => {
    dispatch({ type: AuthActions.clearUser });
  }, []);

  


  return (
    <JWTContext.Provider
      value={{
        ...state,
        login,
      logout,
      // refreshToken, 
      // setTokens,   
      }}
    >
      {initialized && children}
    </JWTContext.Provider>
  );
};

export default JWTContext;
