import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Grid,
  Typography,
  Avatar,
  Box,
  TextField,
  IconButton,
  CircularProgress,
  Fab,
} from "@mui/material";
import avatar from "../../../../assets/adminAvatar.jpg";
import { Formik, Field, Form, ErrorMessage } from "formik";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { useSettings } from "..";
import cardStyle from "../../../../theme/card-layout";
import { AuthApis } from "../../../../service/auth";
import { useSelector } from "react-redux";
import CircularLoader from "../../../../ui-component/CircularLoader";
import { CustomScrollbars } from "../../../../ui-component/ScrollBar";
import * as Yup from "yup";
import { useNotification } from "../../../../hooks/use-notification";
import CustomPhoneInput from "../../../../ui-component/CustomPhoneInput";
import { SettingsApi } from "../../../../service/Settings/Index";

const containerStyle = {
  position: "relative",
};

const userProfileStyle = {
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
};

const cameraIconStyle = {
  position: "absolute",
  bottom: "-25px",
  // transform: "translateX(-50%)",
  backgroundColor: "white",
  borderRadius: "50%",
};

const fields = [
  { label: "First Name", key: "first_name" },
  { label: "Last Name", key: "last_name" },
  { label: "Email", key: "email", disabled: true },
  { label: "Phone Number", key: "phone_number" },
  { label: "Address Line 1", key: "address_line_1" },
  { label: "Address Line 2", key: "address_line_2" },
  { label: "City", key: "city" },
  { label: "State", key: "state" },
  { label: "Country", key: "country" },
  { label: "Zip Code", key: "zip_code" },
];

const Profile = ({ isUserupdate, setIsUserUpdate, formRef }) => {
  const snackbar = useSelector((state) => state.snackbar);
  const { edit, setEdit } = useSettings();
  const [selectedImage, setSelectedImage] = useState();
  const [ProfileDetails, setProfileDetails] = useState();
  const [loading, setLoading] = useState(false);
  const { notifySuccess, notifyError } = useNotification();
  const [ChangedFile, setChangedFile] = useState(null);
  const [imageLoading, setImageLoading] = useState(true);


  let userType = JSON.parse(localStorage.getItem("user"))?.tokens?.userRole;


  const isSupportedFileType = (fileType) => {
    const supportedFormats = ["jpeg", "jpg", "png", "gif", "bmp"];
    console.log("fileType", fileType);
    return supportedFormats.some((format) => fileType.endsWith(format));
  };

  const leftFields = edit ? fields.slice(0, 4) : fields.slice(2, 4);
  const rightFields = useMemo(() => fields.slice(4, 9), []);

  const getCurrentUser = () => {
    setLoading(true);
    // setSelectedImage(null)
    AuthApis.getMe()
      .then((response) => {
        console.log("response", response?.data);
        setSelectedImage(response?.data?.profile_pic || avatar);
        setProfileDetails(response?.data || response);
        setLoading(false);
        // setIsUserUpdate(true);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  useEffect(() => {
    if (isUserupdate) {
      getCurrentUser();
    }
  }, [isUserupdate]);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid Email ID address")
      // .max(50, "Email ID must not exceed 50 characters")
      .required("Email ID is required"),
    first_name: Yup.string()
      // .max(50, "First Name must not exceed 50 characters")
      .required("First Name is required"),
    last_name: Yup.string()
      // .max(50, "Last Name must not exceed 50 characters")
      .required("Last Name is required"),
    phone_number: Yup.string()
      .matches(/^[\d\s()+-]+$/, "Invalid Phone number")
      .min(10, "Phone number is too short")
      .max(20, "Phone number is too long")
      .required("Phone number is required"),
    address_line_1: Yup.string()
      // .max(50, "Address Line 1 must not exceed 50 characters")
      .required("Address Line 1 is required"),
    // address_line_2: Yup.string().max(
    //   50,
    //   "Address Line 2 must not exceed 50 characters"
    // ),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    country: Yup.string().required("Country  is required"),
    zip_code: Yup.string()
      .matches(
        /^[0-9]{5,6}$/,
        "Zip code must be 5 to 6 digits and contain only numbers"
      )
      // .max(50, "Zip code must not exceed 50 characters")
      .required("Zip code Address is required"),
  });

  const handleSaveClick = (values) => {
    setLoading(true);
    if (formRef.current) {
      let values = formRef.current.values;
      console.log("values @", values);
      let payload = {
        first_name: values.first_name,
        last_name: values.last_name,
        phone_number: values.phone_number,
        address: {
          city: values.city,
          address_line_1: values.address_line_1,
          address_line_2: values.address_line_2,
          country: values.country,
          state: values.state,
          zip_code: values.zip_code,
        },
        profile_pic: ChangedFile,
      };
      AuthApis.updateMe(payload)
        .then((response) => {
          // if (ChangedFile) {
          //   SettingsApi.AddProfileImage(values?.uuid, {
          //     profile_pic: ChangedFile,
          //   })
          //     .then((response) => {
          //       setTimeout(() => {
          //         formRef?.current?.resetForm();
          //         setEdit(!edit);
          //         setIsUserUpdate(true);
          //         notifySuccess("Super Admin Updated Successfully!");
          //         setLoading(false);
          //       }, 5000);
          //       // notifySuccess(response.message);
          //     })
          //     .catch((error) => {
          //       if (typeof error?.response?.data?.error === "string")
          //         notifyError(error?.response?.data?.error);
          //       setIsUserUpdate(true);
          //       setLoading(false);
          //     });
          // } else {
          formRef?.current?.resetForm();
          setEdit(!edit);
          setIsUserUpdate(true);
          notifySuccess(userType == "admin" ? "Admin Updated Successfully! " :  "Super Admin Updated Successfully! ");
          setLoading(false);
          //   }
        })
        .catch((error) => {
          notifyError(error?.response?.data?.error);
          console.error("Error", error);
          setLoading(false);
        });
    }
  };
  const isURL = (url) => {
    try {
      if (url.includes("https://")) {
        // new URL(url);
        return true;
      } else return false;
    } catch (e) {
      return false;
    }
  };
  return (
    <Grid
      item
      spacing={1}
      padding={2}
      sx={containerStyle}
      style={{ height: "72vh" }}
    >
      <div style={{ position: "relative", top: loading ? "40%" : "30px" }}>
        {loading ? (
          <CircularLoader />
        ) : (
          <CustomScrollbars height={"65vh"}>
            <Formik
              initialValues={{
                email: ProfileDetails?.email || ProfileDetails?.email,
                first_name: ProfileDetails?.first_name,
                last_name: ProfileDetails?.last_name,
                phone_number: ProfileDetails?.phone_number,
                address_line_1: ProfileDetails?.address?.address_line_1,
                address_line_2: ProfileDetails?.address?.address_line_2,
                country: ProfileDetails?.address?.country,
                state: ProfileDetails?.address?.state,
                city: ProfileDetails?.address?.city,
                zip_code: ProfileDetails?.address?.zip_code,
                profile_pic: ProfileDetails?.profile_pic,
                uuid: ProfileDetails?.uuid,
              }}
              validationSchema={validationSchema}
              // enableReinitialize
              innerRef={formRef}
              onSubmit={handleSaveClick}
            >
              {({ values, setFieldValue, handleSubmit }) => (
                <Form>
                  <Grid item xs={12} padding={1}>
                    <Grid container xs={12} spacing={1}>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                        sm={12}
                        marginBottom={2}
                        sx={{ display: "flex", alignItems: "left" }}
                      >
                        <Box
                          style={userProfileStyle}
                        >
                          <div style={{ position: "relative" }}>
                            {/* Backdrop overlay */}
                            {imageLoading && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  backgroundColor: "rgba(119, 118, 118, 0.7)", // Adjust the color and opacity as needed
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  opacity: 0.3,
                                  zIndex: 2,
                                }}
                              >
                                <CircularProgress
                                  size={50}
                                  sx={{
                                    color: "#ffffff",
                                    position: "absolute",
                                  }}
                                />
                              </div>
                            )}

                            {/* User profile photo */}
                            <Avatar
                              alt="User Profile Photo"
                              src={
                                isURL(selectedImage)
                                  ? `${selectedImage}?${new Date().getTime()}`
                                  : selectedImage
                              }
                              sx={{
                                width: 200,
                                height: 180,
                                borderRadius: "0%",
                                marginBottom: "0px",
                                objectFit: "contain",
                                padding: 1,
                                backgroundColor: "#ffffff",
                                boxShadow: "0px 0px 8px #00000029",
                                visibility: imageLoading ? 'hidden' : 'visible',
                              }}
                              onError={(e) => {
                                e.target.src = avatar;
                                e.target.onerror = null;
                              }}
                              onLoad={() => setImageLoading(false)}
                            />
                          </div>

                          {edit && (
                            <label
                              htmlFor="image-upload"
                              style={cameraIconStyle}
                            >
                              <input
                                id="image-upload"
                                type="file"
                                onChange={(e) => {
                                  e.preventDefault();
                                  let file = null;
                                  file = e.target?.files[0];
                                  const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
                                  if (file) {
                                    if (!isSupportedFileType(file.type)) {
                                      notifyError(
                                        "Please select a valid image format, such as .jpeg, .jpg, .png, .gif or .bmp."
                                      );
                                    } else if (file.size > maxSize) {
                                      notifyError(
                                        "Image size should not exceed 5 MB."
                                      );
                                    } else {
                                      setChangedFile(file);
                                      setFieldValue("profile_pic", file);
                                      const reader = new FileReader();
                                      reader.onload = () => {
                                        setSelectedImage(reader.result);
                                      };
                                      reader.readAsDataURL(file);
                                    }
                                  }
                                }}
                                style={{ display: "none" }}
                              />
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                              >
                                <PhotoCameraIcon />
                              </IconButton>
                            </label>
                          )}
                        </Box>
                      </Grid>

                      {edit ? (
                        <>
                          {fields.map((field) => (
                            <Grid
                              item
                              xs={12}
                              md={12}
                              lg={6}
                              sm={12}
                              spacing={3}
                            >
                              <React.Fragment key={field.key}>
                                <Grid container xs={12} spacing={2}>
                                  <Grid item xs={edit ? 12 : 4}>
                                    <Typography
                                      variant="subtitle1"
                                      component="div"
                                      sx={{
                                        marginBottom: "5px",
                                        display: "flex",
                                      }}
                                    >
                                      {field.label}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={edit ? 12 : 8}>
                                    {!edit && (
                                      <>
                                        <Typography
                                          variant="body1"
                                          component="div"
                                          sx={{
                                            marginBottom: "10px",
                                            display: "flex",
                                          }}
                                        >
                                          : &nbsp;&nbsp;&nbsp;
                                          {values[field.key]}
                                        </Typography>
                                      </>
                                    )}
                                  </Grid>
                                </Grid>
                                {edit && (
                                  <>
                                    {field.key == "phone_number" ? (
                                      <Field
                                        name={field.key}
                                        variant="outlined"
                                        fullWidth
                                      // sx={{
                                      //   marginBottom: "10px",
                                      //   width: "90%",
                                      // }}
                                      // validate={validatePhoneNumber}
                                      >
                                        {({ field }) => (
                                          <CustomPhoneInput
                                            {...field}
                                            name="phone_number"
                                            as={TextField}
                                            variant="outlined"
                                            country={"us"} // Use the selectedCountry from your form values
                                            enableSearch={true}
                                            value={values?.phone_number}
                                            color="primary"
                                            placeholder={
                                              "pease Enter Phone Number"
                                            }
                                            onChange={(phone) =>
                                              setFieldValue(
                                                "phone_number",
                                                phone
                                              )
                                            }
                                          />
                                        )}
                                      </Field>
                                    ) : (
                                      <Field
                                        as={TextField}
                                        fullWidth
                                        multiline={field.multiline || false}
                                        name={field.key}
                                        style={cardStyle}
                                        placeholder={`Please Enter ${field.label}`}
                                        disabled={field.disabled}
                                        sx={{
                                          marginBottom: "10px",
                                          width: "100%",
                                        }}
                                      />
                                    )}
                                  </>
                                )}
                                <ErrorMessage
                                  name={field.key}
                                  component="div"
                                  style={{
                                    color: "red",
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                  }}
                                />
                              </React.Fragment>
                            </Grid>
                          ))}
                        </>
                      ) : (
                        <>
                          <Grid item xs={12} md={12} lg={6} sm={12}>
                            <Grid container xs={12} spacing={2}>
                              <Grid item xs={edit ? 12 : 4}>
                                <Typography
                                  variant="subtitle1"
                                  component="div"
                                  sx={{
                                    marginBottom: "5px",
                                    display: "flex",
                                  }}
                                >
                                  Full Name
                                </Typography>
                              </Grid>
                              <Grid item xs={8}>
                                <>
                                  <Typography
                                    variant="body1"
                                    component="div"
                                    sx={{
                                      marginBottom: "10px",
                                      display: "flex",
                                    }}
                                  >
                                    : &nbsp;&nbsp;&nbsp;
                                    {`${values.first_name} ${values.last_name}`}
                                  </Typography>
                                </>
                              </Grid>
                            </Grid>
                            {leftFields.map((field) => (
                              <React.Fragment key={field.key}>
                                <Grid container xs={12} spacing={2}>
                                  <Grid item xs={edit ? 12 : 4}>
                                    <Typography
                                      variant="subtitle1"
                                      component="div"
                                      sx={{
                                        marginBottom: "5px",
                                        display: "flex",
                                      }}
                                    >
                                      {field.label}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={edit ? 12 : 8}>
                                    {!edit && (
                                      <>
                                        <Typography
                                          variant="body1"
                                          component="div"
                                          sx={{
                                            marginBottom: "10px",
                                            display: "flex",
                                            wordBreak:
                                              field.label === "Email"
                                                ? "break-word"
                                                : "normal",
                                          }}
                                        >
                                          : &nbsp;&nbsp;&nbsp;
                                          {values[field.key]}
                                        </Typography>
                                      </>
                                    )}
                                  </Grid>
                                </Grid>
                                {edit && (
                                  <Field
                                    as={TextField}
                                    fullWidth
                                    multiline={field.multiline || false}
                                    name={field.key}
                                    style={cardStyle}
                                    disabled={field.disabled}
                                    placeholder={`Please Enter ${field.label}`}
                                    sx={{
                                      marginBottom: "10px",
                                      width: "90%",
                                    }}
                                  />
                                )}
                                <ErrorMessage
                                  name={field.key}
                                  component="div"
                                  style={{
                                    color: "red",
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                  }}
                                />
                              </React.Fragment>
                            ))}
                          </Grid>
                          <Grid item xs={12} md={12} lg={6} sm={12}>
                            <Grid container xs={12} spacing={2}>
                              <Grid item xs={edit ? 12 : 4}>
                                <Typography
                                  variant="subtitle1"
                                  component="div"
                                  sx={{
                                    marginBottom: "5px",
                                    display: "flex",
                                  }}
                                >
                                  Address
                                </Typography>
                              </Grid>
                              <Grid item xs={8}>
                                <>
                                  <Typography
                                    variant="body1"
                                    component="div"
                                    sx={{
                                      marginBottom: "10px",
                                      display: "flex",
                                    }}
                                  >
                                    : &nbsp;&nbsp;&nbsp;
                                    {[
                                      values.address_line_1,
                                      values.address_line_2,
                                      values.city,
                                      values.state,
                                      values.country,
                                      values.zip_code,
                                    ]
                                      .filter((value) => value) // Remove empty values
                                      .join(", ")}
                                  </Typography>
                                </>
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </CustomScrollbars>
        )}
      </div>
    </Grid>
  );
};

export default Profile;
