import { Card, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import VitalsCard from "./VitalsCard";
import { PatientApi } from "../../../../../../../../../service/ProviderGroup/Patient";
import { useParams } from "react-router";
import cardStyle from "../../../../../../../../../theme/card-layout";
import { CustomScrollbars } from "../../../../../../../../../ui-component/ScrollBar";
import { VitalsCardStyle } from "../../../../../../../../../ccm-constant";
import DateRangePicker from "../../../../../../../../../ui-component/DatePicker/DatePicker";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import EditNotificationsOutlinedIcon from '@mui/icons-material/EditNotificationsOutlined';
import { MdDns } from "react-icons/md";
import StorageOutlinedIcon from "@mui/icons-material/StorageOutlined";
import { LineChart } from "./Chart";
import CarePlanUpdateHistory from "../../CareManagement/Features/CarePlanUpdateHistory";
import NoteHistory from "./NoteHistory";
import CircleIcon from "@mui/icons-material/Circle";
import Range from "./Range";
import { useNotification } from "../../../../../../../../../hooks/use-notification";
import { elements } from "chart.js";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";

function VitalsDetails({ activeIndex, setActiveIndex, refresh, setRefresh, searchText }) {
  const { id } = useParams();
  const { notifyError, notifySuccess } = useNotification();
  const [vitals, setVitals] = useState([]);
  const [vitalsReading, setVitalsReading] = useState([]);
  const [color, setColor] = useState();
  const [startDate, setStartDate] = useState(null);
  const [stopDate, setStopDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [graphloading, setGraphLoading] = useState(false);
  const [vitalsLoading, setVitalsLoading] = useState(false)
  const [historyNotes, setHistoryNotes] = useState([]);
  const [open, setOpen] = useState(false);
  const [Ranges, setRangeOpen] = useState(false);
  const [refreshHistory, setRefreshHistory] = useState(false);

  useEffect(() => {
    if (activeIndex === null) {
      setActiveIndex(0);
    }
    setStopDate(null);
    setStartDate(null);
  }, []);

  useEffect(() => {
    const delay = setTimeout(() => {
      getAllVitals();
    }, 1000); // Adjust the delay as needed (3 seconds in this case)

    return () => {
      clearTimeout(delay);
    };
  }, [searchText])

  useEffect(() => {
    let { color, background, border, borderRadius, opacity } =
      VitalsCardStyle(activeIndex %10);
    setColor(color);
  }, [activeIndex]);

  useEffect(() => {
    if (refresh  || refreshHistory) {
      getAllVitals();
      getVitalsReadingData();
    }
  }, [refresh, refreshHistory]);

  useEffect(() => {
    if (vitals[activeIndex]?.type !== undefined && (startDate === null && stopDate === null))
      getVitalsReadingData();
  }, [activeIndex, vitals]);

  useEffect(() => {
    console.log("startDate", startDate)
    if (startDate !== "NaN-NaN-NaN" && stopDate !== "NaN-NaN-NaN") {
      getVitalsReadingData(startDate, stopDate);
    }
  }, [startDate, stopDate, activeIndex]);

  useEffect(() => {
    setVitalsReading(vitalsReading)
    console.log("final vitals", vitals, vitalsReading)
  }, [vitalsReading, activeIndex])


  useEffect(() => {
    if (open) {
      getVitalsHistory();
    }
  }, [open, refreshHistory]);

  const getAllVitals = () => {
    setVitals([]);
    setVitalsLoading(true);
    const data = PatientApi.getAllVitals(id, searchText);
    data.then((response) => {
      let finalCards = [];
      let sys = false;
      let dis = false;
      let sysvalue = 0;
      let disvalue = 0;
      let diflectionSystolic = 0;
      let diflectionDiastolic = 0
      let SysDate = "";
      let DiasDate = "";
      response?.data?.forEach((element) => {
        if (["systolic", "diastolic"].includes(element?.type)) {
          if (element?.type === "systolic") {
            sys = true;
            sysvalue = element?.value;
            diflectionSystolic = element?.deflection;
            SysDate = element?.created_at;
          }
          if (element?.type === "diastolic") {
            dis = true;
            disvalue = element?.value;
            diflectionDiastolic = element?.deflection;
            DiasDate = element?.created_at;
          }
        } else {
          finalCards.push(element);
        }
      });
      // if (finalCards?.length || searchText) {
      if (sys || dis) {
        setVitals([
          {
            label: "Blood Pressure",
            unit: "mmHg",
            type: "blood_pressure",
            value: sysvalue + "/" + disvalue,
            // created_at: "2023-12-15T11:12:30.425182Z",
            // deflection: 12.5,
            note: "",
            defSystolic: diflectionSystolic,
            defDiastolic: diflectionDiastolic,
            dateSystolic: SysDate,
            dateDiastolic: DiasDate
          },
          ...finalCards,
        ]);
      } else {
        setVitals(finalCards);
      }
      // }
      setVitalsLoading(false);
      setRefresh(false);
      console.log("getAllVitals response", response);
    });
    data.catch((error) => {
      setVitalsLoading(false);
      console.log("getAllVitals error", error?.response?.data?.error);
      notifyError(error?.response?.data?.error);
    });
  };

  const getVitalsReadingData = (start = null, stop = null) => {
    setGraphLoading(true);
    console.log("payload3", start, stop);
    const payload = {
      start_date: start,
      end_date: stop,
      month: null,
      year: null,
    };
    if (vitals[activeIndex]?.type)
      PatientApi.getVitalsReading(id, vitals[activeIndex]?.type, payload)
        .then((response) => {
          setVitalsReading(response?.data);
          setRefresh(false);
          setGraphLoading(false);
          // notifySuccess(response?.message)
          console.log("getAllVitals response", response);
        })
        .catch((error) => {
          setVitalsReading([]);
          setGraphLoading(false);
          notifyError(error?.response?.data?.error);
          console.log("getAllVitals error", error?.response?.data?.error);
        });
  };

  const getVitalsHistory = () => {
    setLoading(true);
    PatientApi.getVitalsHistory(id, vitals[activeIndex]?.type)
      .then((response) => {
        setHistoryNotes(response?.data);
        setRefresh(false);
        setLoading(false);
        setRefreshHistory(false);
        console.log("getAllVitals response", response);
      })
      .catch((error) => {
        notifyError(error?.response?.data?.error);
        setLoading(false);
        setRefreshHistory(false);
        console.log("getAllVitals error", error?.response?.data?.error);
      });
  };

  const handleDateRangeChange = (dateRange) => {
    console.log("dateRange", dateRange);
    let start = new Date(dateRange[0]);
    let end = dateRange[1] ? new Date(dateRange[1]) : "";
    console.log("start, end", start, end);
    if (end) {
      setStartDate(
        `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`
      );
      setStopDate(
        `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`
      );
    }

    if (dateRange?.length === 0) {
      setStartDate(null)
      setStopDate(null)
    }
  };

  return (
    <>
      {vitalsLoading ?
        <Grid display={"flex"} justifyContent={"center"}>
          <CircularProgress
            color="primary"
            sx={{ marginTop: "15%" }}
          />
        </Grid>
        : <>
          <div
            style={{
              overflowY: "auto",
              whiteSpace: "nowrap",
              height: "190px",
              marginBottom: 5,
              paddingBottom: 0,
            }}
          >
            <Grid container spacing={2} style={{ display: "inline-block" }}>
              {vitals?.map((element, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  key={index}
                  style={{ display: "inline-block", minWidth: "350px" }}
                  onClick={() => {
                    setActiveIndex(index);
                  }}
                >
                  <VitalsCard
                    data={element}
                    active={index === activeIndex ? true : false}
                    setActiveIndex={setActiveIndex}
                    ind={index}
                  />

                </Grid>
              ))}

              {
                vitals?.length === 0 ? <Typography variant="body2" fontSize={16} display={"flex"} justifyContent={"center"} marginTop={5}>There are no records to display</Typography> : null
              }
            </Grid>
          </div>

          {vitals?.length >= 1 && (
            <>
              <Card sx={{ height: "auto", ...cardStyle }} marginTop={5}>
                <Grid container>
                  <Grid item xs={12} marginTop={1}>
                    <Grid container padding={1}>
                      <Grid item xs={12} md={2}>
                        <Typography variant="h6">Statistics</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={10}
                        style={{
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        <Typography
                          variant="body1"
                          style={{
                            fontSize: "18px",
                            fontWeight: 500,
                            alignItems: "center",
                            alignContent: "center",
                            marginRight: "20px",
                          }}
                          paddingTop={1}
                        >

                          {vitals[activeIndex]?.label === "Blood Pressure" ? <>
                            <CircleIcon
                              style={{ color: color, marginTop: 2, height: "15px" }}
                            />
                            Systolic

                            <CircleIcon
                              style={{ color: "#d07016", marginTop: 2, height: "15px" }}
                            />
                            Diastolic
                          </> : <>
                            <CircleIcon
                              style={{ color: color, marginTop: 2, height: "15px" }}
                            />
                            {vitals[activeIndex]?.label}
                          </>}
                        </Typography>
                        < Typography width={"250px"}>
                          <DateRangePicker
                            onDateRangeChange={handleDateRangeChange}
                            stopDate={true}
                          />
                        </Typography>
                        <EditNotificationsOutlinedIcon
                          style={{ height: "40px", margin: "0px 20px" }}
                          onClick={() => setRangeOpen(true)}
                        />
                        <MdDns
                          style={{ height: "40px", width: "23px" }}
                          onClick={() => setOpen(true)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {open ? (
                      <NoteHistory
                        onClose={() => setOpen(false)}
                        history={historyNotes}
                        loading={loading}
                        vitalsReading={vitalsReading}
                        setRefreshHistory={setRefreshHistory}
                        vitalsType={vitals[activeIndex]?.type}
                      />
                    ) : null}
                    {Ranges ? (
                      <Range
                        onClose={() => setRangeOpen(false)}
                        history={historyNotes}
                        loading={loading}
                        setRefreshHistory={setRefreshHistory}
                        vitalsType={vitals[activeIndex]?.label}
                        type={vitals[activeIndex]?.type}
                        vitalsReading={vitalsReading}
                        vitalID={vitals[activeIndex]?.uuid}
                        setRefresh={setRefresh}
                      />
                    ) : null}
                    <LineChart
                      vitalsReading={vitalsReading}
                      color={color}
                      unit={vitals[activeIndex]?.unit}
                      vitalsType={vitals[activeIndex]?.type}
                      type={vitals[activeIndex]?.label}
                      loading={graphloading}
                    />
                    {vitalsReading?.values?.length> 0 && <>
                      <Grid
                        container
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        marginBottom={2}
                      >
                        <Typography variant="body1">
                          <CircleIcon
                            style={{
                              color: "#02B966",
                              height: "15px",
                            }}
                          />
                          Normal Reading
                        </Typography>

                        <Typography variant="body1">
                          <CircleOutlinedIcon
                            style={{
                              color: "#FFBE3D",
                              height: "15px",
                            }}
                          />
                          Abnormal Reading
                        </Typography>

                        <Typography variant="body1">
                          <CircleOutlinedIcon
                            style={{
                              color: "#FF0000CC",
                              height: "15px",
                            }}
                          />
                          Critical Reading
                        </Typography>
                      </Grid>
                    </>}
                  </Grid>
                </Grid>
              </Card>
            </>
          )}
        </>
      }
    </>
  );
}

export default VitalsDetails;
