import { Card, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import BillingCount from "../../../../ui-component/BillingCount";
import cardStyle from "../../../../theme/card-layout";
import { Billing } from "../../../../service/Billing";
import { None } from "amazon-chime-sdk-js";

const Adherence = ({AdherenceCount}) => {
  let userType = JSON.parse(localStorage.getItem("user"))?.tokens?.userRole;
  let ProviderGroupId =
    userType === "provider_group"
      ? JSON.parse(localStorage.getItem("user"))?.user?.data?.uuid
      : JSON.parse(localStorage.getItem("user"))?.user?.data?.provider_group
          ?.uuid;
  const [adherenceCount, setAdherenceCount] = useState([]);

  useEffect(() => {
    getAdherenceCount();
  }, []);

  const getAdherenceCount = () => {
    Billing.getBillingData(ProviderGroupId)
      .then((response) => {
        setAdherenceCount(response.data);
        // setPageCount(response.count);
        // setAllData(response);
        // setLoading(true);
        // setRefreshList(false);
      })
      .catch((error) => {
        console.error("Error fetching Time Log data:", error);
        // setLoading(true);
      });
  };

  return (
    <Card
      sx={{
        ...cardStyle,
        minHeight: "13vh",
      }}
      padding={2}
    >
      <Grid item xs={12} display={"flex"} justifyContent={"center"}>
        <Typography variant="h6" paddingTop={1}>
          Adherence
        </Typography>
      </Grid>
      <Grid container paddingBottom={2} paddingTop={{xl:2, xs:0}}>
        <Grid
          container
          xs={12}
          lg={7}
          xl={7}
          sx={{
            display: "flex",
            justifyContent: {xl:"flex-end", lg:"center", xs:"center"},
            borderRight: {
              lg: "3px dashed #c4bebe", // Add right border on md screens
            },
            borderBottom:{
              xs:"3px dashed #c4bebe",
            
              lg:"0px"
            },
            paddingBottom:{
              xs:2,
            },
            paddingRight: {
              lg: 2,
            },
          }}
        >
          <Grid item key={"Non Complex"}  lg={12} xs={12} xl={3} sx={{display:"flex",justifyContent: {xl:"flex-start", lg:"center", xs:"center"}}}>
            <Typography variant="body1" paddingTop={3} margin={2}  alignItems={"center"}>
              Non Complex
            </Typography>
          </Grid>
          {["99490", "99439","99491", "99437"].map((element, index) => (
            <Grid
              item
              key={index}
              paddingX={2}
              display={"flex"}
              justifyContent={"center"}
            >
              <BillingCount element={element} count ={AdherenceCount ? AdherenceCount[element] : 0}/>
            </Grid>
          ))}
        </Grid>
        <Grid container xs={12} lg={5} xl={5} sx={{
            display: "flex",
            justifyContent: {lg: "center",xs:"center", xl:"flex-start"},
            // paddingRight: {
            //   lg: 2,
            // },
            marginTop:0,
            paddingRight: {
              lg: 2,
            }
          }}>
          <Grid item sx={{display:"flex",justifyContent: {xl:"flex-start", lg:"center", xs:"center"}}} md={12} lg={12} xl={3} xs={12} marginLeft={{xl:3}}>
            <Typography
              variant="body1"
              paddingTop={3}
              margin={2}
              // sx={{
              //   paddingLeft: {
              //     xl: 2,
              //   },
                // width:{sm:"130px",  lg:"auto"}
              // }}
              // minWidth="130px" 
              alignItems={"center"}
            >
              Complex
            </Typography>
          </Grid>
          {[ "99487", "99489"]?.map((element, index) => (
            <Grid
              item
              paddingX={2}
              key={index}
              display={"flex"}
              justifyContent={"center"}
            >
              <BillingCount element={element} count ={AdherenceCount ? AdherenceCount[element] : 0}/>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Card>
  );
};

export default Adherence;
