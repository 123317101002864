import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { Card, Grid, TextField, Typography, Box, CircularProgress, Button } from '@mui/material'
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import cardStyle from '../../../../../../../../../theme/card-layout';
import { Textarea } from '../../../../../../../../../ccm-constant';
import { PatientApi } from '../../../../../../../../../service/ProviderGroup/Patient';
import { CustomScrollbars } from '../../../../../../../../../ui-component/ScrollBar';
import { useNotification } from '../../../../../../../../../hooks/use-notification';

function PhysicianReview() {
  const { id } = useParams();
  const { notifySuccess, notifyError } = useNotification();
  const [reviewsData, setReviewsData] = useState();
  console.log("Response d", reviewsData);
  const [disableButton, setDisableButton] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [loading, setLoading] = useState(false);
  const userType = JSON.parse(localStorage.getItem("user"))?.tokens?.userRole;
  const ProviderRole = JSON.parse(localStorage.getItem("user"))?.user?.data?.role;

  const PhysicianReviewCards = [
    { title: "Provider's Initial Review Notes", name: "initial_note" },
    { title: "Treatments or Tests", name: "treatment_or_test" },
    { title: "Referrals", name: "referrals" },
    { title: "Community Resources", name: "community_resources" },
    { title: "Medication Changes", name: "medication_changes" },
    { title: "Health Education Needed", name: "health_education_needed" },
    { title: "Diet Changes", name: "diet_changes" },
  ];

  const fetchAllPhysicianReviews = () => {
    setFetchingData(true);
    PatientApi.getAllPhysicianReviews(id)
      .then((response) => {
        setReviewsData(response?.data[0]);
        setDisableButton(response?.sign_off)
        setFetchingData(false);
      })
      .catch((error) => {
        console.error("Error fetching Physician Reviews data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchAllPhysicianReviews();
  }, [])

  const validationSchema = Yup.object().shape({
    referrals: Yup.string().required("Referral is required"),
    diet_changes: Yup.string().required("Diet change is required"),
    initial_note: Yup.string().required("Initial Note is required"),
    treatment_or_test: Yup.string().required("Test is required"),
    medication_changes: Yup.string().required("Medication change is required"),
    community_resources: Yup.string().required("Community resource is required"),
    health_education_needed: Yup.string().required("Health Education is required"),
  });


  return (
    <>
      <Formik
        initialValues={{
          referrals: reviewsData?.referrals,
          diet_changes: reviewsData?.diet_changes,
          initial_note: reviewsData?.initial_note,
          treatment_or_test: reviewsData?.treatment_or_test,
          medication_changes: reviewsData?.medication_changes,
          community_resources: reviewsData?.community_resources,
          health_education_needed: reviewsData?.health_education_needed
        }}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={(data) => {
          console.log("Review Data", data);
          const payload = {
            initial_note: data?.initial_note,
            treatment_or_test: data?.treatment_or_test,
            referrals: data?.referrals,
            community_resources: data?.community_resources,
            medication_changes: data?.medication_changes,
            health_education_needed: data?.health_education_needed,
            diet_changes: data?.diet_changes
          }
          setLoading(true);
          PatientApi.AddPhysicianReviews(id, payload)
            .then((response) => {
              setLoading(false);
              notifySuccess(response?.message);
              fetchAllPhysicianReviews();
            })
            .catch((error) => {
              console.error("Error fetching Add Physician Reviews data:", error);
              setLoading(false);
              notifyError(error?.response?.data?.error);
            });
        }}
      >
        {({ handleSubmit, values, setFieldValue }) => (
          <Form>
            {!fetchingData ?
              <Grid container marginTop={2}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    background: "#FF00000D",
                    height: "60px",
                    padding: "10px"
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{ marginBottom: 1 }}
                  >
                    <Typography variant='subtitle1'>
                      Provider Sign Off Required
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "end" }}
                  >
                    {(userType === "provider") && (ProviderRole == "Care Team" || ProviderRole == "Physician") ?
                      (<Button
                        variant="contained"
                        disabled={disableButton}
                        style={{
                          height: "40px",
                          fontWeight: 700,
                          marginLeft: "20px",
                          width: "220px",
                          color: "#ffffff",
                          textTransform: "none",
                        }}
                        onClick={handleSubmit}
                      >
                        Sign Off On Care Plan &nbsp;
                        {loading && (
                          <CircularProgress
                            size={18}
                            style={{ color: "white" }}
                          />
                        )}
                      </Button>) : <></>}
                  </Grid>
                </Grid>
                <CustomScrollbars height="45vh">
                  <Grid item xs={12} marginTop={2} padding={1}>
                    {PhysicianReviewCards.map((review, index) => (
                      <Grid marginBottom={3} key={index}>
                        <Card sx={{ ...cardStyle }} style={{ paddingBottom: "0px" }}>
                          <Grid xs={12} padding={2}>
                            <Grid item xs={6} marginBottom={1}>
                              <Typography variant="subtitle1" alignContent={"center"}>
                                {review?.title}
                              </Typography>
                            </Grid>
                            {/* <div style={{ height: 2, backgroundColor: '#E9E9E9', marginBottom: "10px" }}></div> */}
                            <Grid item xs={12}>
                              <Box width={"100%"}>
                                <Textarea
                                  minRows={5}
                                  width={"100%"}
                                  style={{
                                    width: "98%",
                                    resize: "unset",
                                    // border: "none",
                                    // outline: "none",
                                  }}
                                  aria-label={review?.title}
                                  placeholder="Enter Note"
                                  name={review?.name}
                                  disabled={(userType === "provider") && (ProviderRole == "Care Team" || ProviderRole == "Physician") ? disableButton : true}
                                  value={values[review?.name]}
                                  onChange={(e) => { setFieldValue(review?.name, e.target.value) }}
                                />
                                <ErrorMessage
                                  name={review?.name}
                                  component="div"
                                  style={{
                                    color: "red",
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                  }}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </CustomScrollbars>
              </Grid>
              :
              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress color="primary" sx={{ marginTop: "15%" }} />
              </Grid>
            }
          </Form>
        )}
      </Formik>
    </>
  )
}

export default PhysicianReview