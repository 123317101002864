import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import MoreIcon from "@mui/icons-material/MoreVert";
import { IconArrowsSort } from "@tabler/icons-react";
import {
  Grid,
  IconButton,
  Popover,
  Switch,
  Typography,
  Menu,
  MenuItem,
  CircularProgress,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import { singleDateConverter } from "../../../../../../../../helpers/regularDateConvert";
import { convertToBrowserLocalTime } from "../../../../../../../../helpers/regularDateConvert";
import DrawerComponent from "../../../../../../../../ui-component/Drawer";
import { customDataTableStyles } from "../../../../../../../../ccm-constant";
import { useNotification } from "../../../../../../../../hooks/use-notification";
import CustomPagination from "../../../../../../../../ui-component/Pagination";
import { CustomScrollbars } from "../../../../../../../../ui-component/ScrollBar";
import SetDefaultBox from "../../../../../../../../ui-component/SetDefaultBox";
import useProviderContext from "../../";
import { AssesmentApi } from "../../../../../../../../service/Assesment/assesmentAPI";
import AssignAssessment from "./feature/assignAssessment";
import AssessmentQueAnsCard from "./Components/AssessmentQA";
import { formatDate } from "../../../../../../../../ccm-constant";

const Assesment = ({ setOpenDrawer, openDrawer, searchText }) => {
  // const { searchText } = useProviderContext();
  console.log("searchText111", searchText);
  const navigate = useNavigate();
  const { id } = useParams();
  let userType = JSON.parse(localStorage.getItem("user"))?.tokens?.userRole;
  let ProviderId =
    userType === "provider_group"
      ? JSON.parse(localStorage.getItem("user"))?.user?.data?.uuid
      : JSON.parse(localStorage.getItem("user"))?.user?.data?.provider_group
        ?.uuid;
  const ProviderRole = JSON.parse(localStorage.getItem("user"))?.user?.data
    ?.role;
  const { notifySuccess, notifyError } = useNotification();
  const [sortedColumn, setSortedColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("-id");
  const [userData, setUserData] = useState([]);
  console.log("userDataCare", userData);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  console.log("pageCount", pageCount);
  const [allData, setAllData] = useState();
  const [openCnf, setOpenCnf] = useState(false);
  const [unassignCof, setUnassignCof] = useState(false);
  const [unassignId, setUnassignId] = useState();
  const [refreshList, setRefreshList] = useState(false);
  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState();
  const [drawerSize, setDrawerSize] = useState(false);
  console.log("unassignId", unassignId);

  const CareTeamColumns = [
    {
      name: "No",
      selector: (row, id) => id + 1 + (currentPage ? (currentPage - 1) * 10 : ""),
      width: "8%",
    },
    {
      name: "Assessment Name",
      selector: (row) => <span>{row?.assessment?.title}</span>,
      sortable: true,
      width: "20%",
    },
    {
      name: "Assignee Providers",
      selector: (row) => row.assignee_provider,
      // sortable: true,
      width: "20%",
    },
    {
      name: "Date",
      selector: (row) => <span>{formatDate(row?.created_at)}</span>,
      sortable: true,
      // width: "20%",
    },
    {
      name: "Time",
      selector: (row) => (
        <span>{convertToBrowserLocalTime(row?.created_at)}</span>
      ),
      sortable: true,
      // width: "14%",
    },
    {
      name: "Results",
      selector: (row) => <span>{row?.result}</span>,
      sortable: true,
      // width: "14%",
    },
    {
      selector: "",
      name: "",
      width: "10%",
      // cell: (row) => <MoreButton row={row} />,
      cell: (row) => {
        if (
          (userType === "provider" || userType === "provider_group") ||
          (ProviderRole === "Care Team" || ProviderRole === "Physician")
        ) {
          return <MoreButton row={row} />;
        } else {
          return null;
        }
      },
    },
  ];

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getAssessmentList = () => {
    setLoading(true);
    console.log("I am calling");
    const query = {
      search: searchText,
      limit: 10,
      offset:
        currentPage === 0
          ? currentPage
          : searchText
            ? 0
            : (currentPage - 1) * 10,
      order_by: sortDirection,
      // sort_by: sortedColumn,
    };
    AssesmentApi.getAssessmentById(id, query)
      .then((response) => {
        setUserData(response.data);
        setPageCount(response.count);
        setAllData(response);
        setLoading(false);
        setRefreshList(false);
      })
      .catch((error) => {
        console.error("Error fetching Care Team data:", error);
        setLoading(false);
      });
  };

  const handleSort = (column, sortDirection) => {
    console.log("sortDirection", sortDirection);
    switch (column.name) {
      case "Assessment Name":
        setSortDirection(sortDirection == "desc" ? "-assessment__title" : "assessment__title");
        break;
      case "Date":
        setSortDirection(
          sortDirection == "desc" ? "-created_at" : "created_at"
        );
        break;
      case "Time":
        setSortDirection(
          sortDirection == "desc" ? "-created_at" : "created_at"
        );
        break;
      case "Result":
        setSortDirection(
          sortDirection == "desc" ? "-result" : "result"
        );
        break;
      default:
        setSortDirection("-id");
    }
  };

  const handleUnassign = async () => {
    AssesmentApi.unassignAssessment(unassignId, id)
      .then((response) => {
        notifySuccess(response?.message);
        setOpenCnf(false);
        getAssessmentList();
      })
      .catch((error) => {
        console.error("Error fetching unassign Care Team:", error);
      });
  };

  const MoreButton = ({ row }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
      <div>
        {ProviderRole !== "Non Clinical" && <IconButton onClick={handleClick}>
          <MoreIcon />
        </IconButton>}
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                setOpenCnf(true);
                setUnassignCof(true);
                setUnassignId(row?.assessment?.uuid);
                console.log("row?.uuid", row?.uuid);
              }}
            >
              Unassign
            </MenuItem>
            <MenuItem
              onClick={() => {
                // setOpenCnf(true);
                // setUnassignCof(true);
                setRowData(row);
                handleOpen();
                // if (row.is_reviewed == true) {
                //   setDrawerSize(true);
                // }
              }}
            >
              Review
            </MenuItem>
          </Menu>
        </Popover>
      </div>
    );
  };

  useEffect(() => {
    if (refreshList) {
      getAssessmentList();
    }
  }, [refreshList]);

  useEffect(() => {
    setLoading(true);
    const delay = setTimeout(() => {
      getAssessmentList();
    }, 1000);

    return () => {
      clearTimeout(delay);
    };
  }, [searchText, sortDirection, sortedColumn, currentPage]);

  return (
    <Grid>
      <Grid item xs={12} sx={{ height: "75vh", position: "relative" }}>
        {/* <Card sx={{ height: "75vh", position: "relative" }}> */}
        {/* <CardContent> */}
        <Grid
          container
          xs={12}
          padding={1}
          sx={{ flex: 1, alignItems: "center", marginBottom: "10px" }}
        >
          <CustomScrollbars height="58vh">
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {!loading ? (
                <DataTable
                  columns={CareTeamColumns}
                  data={userData}
                  sortIcon={<IconArrowsSort size={18} marginLeft={1} />}
                  onSort={handleSort}
                  // onRowClicked={handleRowClick}
                  highlightOnHover
                  pointerOnHover
                  customStyles={customDataTableStyles}
                />
              ) : (
                <CircularProgress color="primary" sx={{ marginTop: "15%" }} />
              )}
            </Grid>
          </CustomScrollbars>
          <DrawerComponent
            open={openDrawer}
            handleClose={() => {
              setOpenDrawer(false);
            }}
            data={
              <AssignAssessment
                setOpenDrawer={setOpenDrawer}
                setRefreshList={setRefreshList}
              />
            }
          />
          <DrawerComponent
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            drawerSize={drawerSize}
            data={
              <AssessmentQueAnsCard handleClose={handleClose}
                rowData={rowData}
                patientId={id}
                setRefreshList={setRefreshList}
                setDrawerSize={setDrawerSize}
              />
            }
          />
          <SetDefaultBox
            handleClose={() => {
              setOpenCnf(false);
            }}
            open={openCnf}
            // unassignCof={unassignCof}
            setUnassignCof={setUnassignCof}
            handleSubmit={() => handleUnassign()}
            title={"unssigned assessment"}
          />
        </Grid>
        {/* </CardContent> */}
        <CardActions sx={{ position: "absolute", bottom: 0, width: "100%" }}>
          {pageCount !== 0 && !loading && (
            <Grid item xs={12} marginTop={2}>
              <CustomPagination
                count={pageCount}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                data={allData}
              />
            </Grid>
          )}
        </CardActions>
        {/* </Card> */}
      </Grid>
    </Grid>
  );
};

export default Assesment;
