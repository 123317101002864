import React, { useState, useEffect } from 'react';
import { Grid, CardActions, Select, TextField, CircularProgress, Button } from '@mui/material';
import { CustomScrollbars } from '../../../../ui-component/ScrollBar';
import DataTable from 'react-data-table-component';
import { IconArrowsSort } from "@tabler/icons-react";
import { customDataTableStyles } from '../../../../ccm-constant';
import CustomPagination from '../../../../ui-component/Pagination';
import { SettingsApi } from '../../../../service/Settings/Index';
import { formatDate } from '../../../../ccm-constant';
import Input from '../../Patients/components/Patient/Input';
import * as Yup from "yup";
import { useParams } from 'react-router-dom';
import { useNotification } from '../../../../hooks/use-notification';
import { Formik, Field, Form, ErrorMessage, useFormikContext } from "formik";

const EhrAudit = () => {
  const [auditData, setAuditData] = useState();
  const [loading, setLoading] = useState(false);
  const [fetchingAudits, setFetchingAudits] = useState(false);
  const [sortedColumn, setSortedColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("-created_at");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [clearFilter, setClearFilter] = useState(false);
  const [filterData, setFilterData] = useState();
  console.log("filterData", filterData);

  let userType = JSON.parse(localStorage.getItem("user"))?.tokens?.userRole;
  let ProviderId = userType === "provider_group" ? JSON.parse(localStorage.getItem("user"))?.user?.data?.uuid :
    JSON.parse(localStorage.getItem("user"))?.user?.data?.provider_group
      ?.uuid;
  const ProviderRole = JSON.parse(localStorage.getItem("user"))?.user?.data?.role;

  const [fields, setFields] = useState([
    {
      // label: "EHR Provider",
      fieldName: "ehr_id",
      fieldType: Select,
      placeholder: "Select EHR",
      options: []
    },
    {
      // label: "Practice ID",
      fieldName: "entity",
      fieldType: Select,
      placeholder: "Entity",
      options: [
        {
          name: "Patient",
          label: "Patient"
        },
        {
          name: "Provider",
          label: "Provider"
        }
      ]
    },
    {
      // label: "Department",
      fieldName: "event_type",
      fieldType: Select,
      placeholder: "Entity Type",
      options: [
        {
          name: "Pull Patient",
          label: "Pull Patient"
        },
        {
          name: "Pull Provider",
          label: "Pull Provider"
        }
      ]
    },
  ])

  const getEHRList = () => {
    let EHRData = [];
    let mapData = [];
    SettingsApi.getEHRList(ProviderId)
      .then((response) => {
        console.log("EHRList", response);
        response?.data?.map((ehr) => {
          EHRData.push({
            name: ehr?.name,
            label: ehr?.name,
          });
        })
        if (EHRData?.length)
          fields?.map((ele) => {
            if (ele?.fieldName === "ehr_id") {
              mapData?.push({
                // label: "EHR Provider",
                fieldName: "ehr_id",
                fieldType: Select,
                placeholder: "Select EHR",
                options: EHRData
              });
            } else {
              mapData?.push(ele);
            }
          });
        if (mapData?.length >= 1) setFields(mapData);

      })
      .catch((error) => {
        console.error("Error fetching EHR list data:", error);
        setLoading(false);
      });
  };

  const getAuditDataList = () => {
    setLoading(true);
    const query = {
      limit: 10,
      offset:
        currentPage === 0
          ? currentPage
          : (currentPage - 1) * 10,
      order_by: sortDirection,
      is_error: false,
      ehr_id__name: filterData?.ehr_id,
      entity: filterData?.entity,
      event_type: filterData?.event_type,
      // error: false
      // alert_severity: buttonValue,
      // sort_by: sortedColumn,
    };
    SettingsApi.getEhrAuditList(ProviderId, query)
      .then((response) => {
        console.log("auditLIst", response)
        setAuditData(response.data);
        setPageCount(response.count);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching Audit data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getAuditDataList();
    getEHRList();
  }, [])

  useEffect(() => {
    getAuditDataList();
  }, [currentPage, sortDirection])

  const AuditColoumns = [
    // {
    //   name: "No",
    //   selector: (row, id) => id + 1 + (currentPage ? (currentPage - 1) * 10 : ""),
    //   width: "7%",
    // },
    {
      name: "Event ID",
      selector: (row) => row?.uuid,
      width: "15%",
      sortable: true,
      wrap: true
    },
    {
      name: "Created On",
      selector: (row) => formatDate(row?.updated_at, true),
      sortable: true,
      width: "15%",
      wrap: true
    },
    {
      name: "Entity",
      selector: (row) => row?.entity,
      sortable: true,
      width: "10%",
      wrap: true
    },
    {
      name: "EHR Facility",
      selector: (row) => row?.ehr_facility,
      sortable: true,
      width: "16%",
      wrap: true
    },
    {
      name: "Portal ID",
      selector: (row) => row?.portal_id,
      sortable: true,
      width: "14%",
      wrap: true
    },
    {
      name: "EHR ID",
      selector: (row) => row?.ehr_id,
      sortable: true,
      width: "15%",
      wrap: true
    },
    {
      name: "Event Type",
      selector: (row) => row?.event_type,
      sortable: true,
      width: "14%",
      wrap: true
    },
  ];

  const handleSort = (column, sortDirection) => {
    switch (column.name) {
      case "Event ID":
        setSortDirection(sortDirection == "desc" ? '-id' : "id");
        break;
      case "Created On":
        setSortDirection(sortDirection == "desc" ? '-updated_at' : "updated_at");
        break;
      case "Entity":
        setSortDirection(sortDirection == "desc" ? '-entity' : "entity");
        break;
      case "EHR Facility":
        setSortDirection(sortDirection == "desc" ? '-ehr_facility' : "ehr_facility");
        break;
      case "Portal ID":
        setSortDirection(sortDirection == "desc" ? '-portal_id' : "portal_id");
        break;
      case "EHR ID":
        setSortDirection(sortDirection == "desc" ? '-operation' : "operation");
        break;
      case "Event Type":
        setSortDirection(sortDirection == "desc" ? '-event_type' : "event_type");
        break;
      default:
        setSortDirection("-id");
    }
  };


  const validationSchema = Yup.object().shape({
    ehr_id: Yup.string().required("EHR is required"),
    entity: Yup.string().required("Entity is required"),
    event_type: Yup.string().required("Entity type is required"),
  });


  return (
    <>
      <Grid padding={3}>
        <Formik
          initialValues={{
            ehr_id: "",
            entity: "",
            event_type: "",
          }}
          onSubmit={(data) => {
            console.log("567yuj", data)
            setFilterData(data);
            if ((data?.entity !== "" || data?.event_type !== "" || data?.ehr_id !== "") || clearFilter) {
              setFetchingAudits(true);
              const query = {
                limit: 10,
                ehr_id__name: data?.ehr_id,
                entity: data?.entity,
                event_type: data?.event_type,
                offset:
                  currentPage === 0
                    ? currentPage
                    : (currentPage - 1) * 10,
                order_by: sortDirection,
                is_error: false
                // alert_severity: buttonValue,
                // sort_by: sortedColumn,
              };
              SettingsApi.getEhrAuditList(ProviderId, query)
                .then((response) => {
                  console.log("auditLIst", response)
                  setAuditData(response.data);
                  setPageCount(response.count);
                  setFetchingAudits(false);
                  setClearFilter(false);
                })
                .catch((error) => {
                  console.error("Error fetching Audit data:", error);
                  setLoading(false);
                });
            }
          }}
          // validationSchema={validationSchema}
          // enableReinitialize
        >
          {({ values, setFieldValue, handleSubmit, resetForm }) => (
            <Form>
              <>
              <Grid container display={"flex"} justifyContent={"space-between"}>
                <Grid item xs={12} sm={12} xl={9} lg={9} md={12} display="flex" justifyContent="flex-start">
                  {fields?.map((field) => (
                    <Grid xs={3} mr={3}>
                      <Input data={field} />
                      <ErrorMessage
                        name={field.fieldName}
                        component="div"
                        style={{
                          color: "red",
                          fontSize: "15px",
                          marginBottom: 1,
                          marginTop: "5px",
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
                <Grid item xs={12} sm={12} xl={3} lg={3} md={12} mt={1} display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    style={{
                      textTransform: "none",
                      width: 120,
                      marginRight: 15
                    }}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Filter &nbsp;{fetchingAudits && (
                      <CircularProgress size={18} style={{ color: "white" }} />
                    )}
                  </Button>
                  <Button
                    variant="outlined"
                    style={{
                      textTransform: "none",
                      width: 120,
                    }}
                    onClick={() => {
                      setClearFilter(true);
                      resetForm();
                      handleSubmit();
                    }}
                  >
                    Clear
                  </Button>
                </Grid>
                </Grid>
                </>
            </Form>
          )}
        </Formik>
      </Grid>
      <Grid container padding={2}>
        <Grid item xs={12} sx={{ height: "52vh", position: "relative" }}>
          <CustomScrollbars height="50vh">
            <Grid
              container
              xs={12}
              padding={1}
              sx={{ flex: 1, alignItems: "center", marginBottom: "10px" }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {!loading ? (
                  <DataTable
                    columns={AuditColoumns}
                    data={auditData}
                    sortIcon={<IconArrowsSort size={18} marginLeft={1} />}
                    onSort={handleSort}
                    // onRowClicked={handleRowClick}
                    highlightOnHover
                    pointerOnHover
                    customStyles={customDataTableStyles}
                  />
                )
                  : (
                    <CircularProgress
                      color="primary"
                      sx={{ marginTop: "15%" }}
                    />
                  )}
              </Grid>
              {/* <DrawerComponent
            open={openDrawer}
            handleClose={() => { setOpenDrawer(false) }}
            data={<TimeLogForm setOpenDrawer={setOpenDrawer} setRefreshList={setRefreshList} />}
          /> */}
              {/* <ConfirmBox
            handleClose={() => setOpenCnf(false)}
            open={openCnf}
            handleSubmit={() => handleDeleteSubmit()}
          /> */}
            </Grid>
          </CustomScrollbars>
        </Grid>
      </Grid>
      {/* <CardActions sx={{ position: "absolute", bottom: 0, width: "100%" }}> */}
      {pageCount !== 0 && !loading && (
        <Grid item xs={12} marginTop={3} marginLeft={2}>
          <CustomPagination
            count={pageCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            data={auditData}
          />
        </Grid>
      )}
      {/* </CardActions> */}
    </>
  )
}

export default EhrAudit