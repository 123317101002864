import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import WarningIcon from "@mui/icons-material/Warning";
import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";

export default function ConfirmBox({ open, handleSubmit, handleClose, cancelCall }) {
  return (
    <Dialog open={open} onClose={handleClose} sx={{padding:"5px"}}>
      <DialogTitle>
        <Box textAlign={"center"}>
          <WarningIcon color="warning" fontSize="60px !important" sx={{height:"80px", width:"80px"}} />
        </Box>
        <Typography variant="h6">
         {cancelCall?  "Are you sure you want cancel this call": "Are you sure you want to delete record?"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText variant="subtitle1" textAlign="center">
          You will not be able to recover this record!
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{padding:"15px"}}>
        <Link style={{ margin: "10px" }} onClick={handleClose}>
          No, cancel it!
        </Link>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Yes, I am Sure!
        </Button>
      </DialogActions>
    </Dialog>
  );
}
